import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

// Development time
import PropTypes from "prop-types";

// Own components
import EventsPickerNew from "./EventsPickerNew";
import QuizzPicker from "./QuizzPicker";
import SurveyPicker from "./SurveyPicker";
import ElementButton from "../inputs/ElementButton";

// Styling
import "../inputs/element-input.css";

import { isEmptyOrSpaces } from "../../utils/utils";

const ObjectivesPickerNew = (props) => {
  const [events, setEvents] = useState(
    props.objectives ? props.objectives.events : []
  );
  const [quizzId, setQuizzId] = useState(
    props.objectives.quizzId ? props.objectives.quizzId : ""
  );
  const [surveyId, setSurveyId] = useState(
    props.objectives.surveyId ? props.objectives.surveyId : ""
  );

  const [eventsPickerEnabled, setEventsPickerEnabled] = useState(true);
  const [quizzPickerEnabled, setQuizzPickerEnabled] = useState(true);
  const [surveyPickerEnabled, setSurveyPickerEnabled] = useState(true);

  const setPickersVisibility = () => {
    if (events.length > 0) {
      setEventsPickerEnabled(true);
      setQuizzPickerEnabled(false);
      setSurveyPickerEnabled(false);
      return;
    }
    if (!isEmptyOrSpaces(quizzId)) {
      setEventsPickerEnabled(false);
      setQuizzPickerEnabled(true);
      setSurveyPickerEnabled(false);
      return;
    }
    if (!isEmptyOrSpaces(surveyId)) {
      setEventsPickerEnabled(false);
      setQuizzPickerEnabled(false);
      setSurveyPickerEnabled(true);
      return;
    }
    setEventsPickerEnabled(true);
    setQuizzPickerEnabled(true);
    setSurveyPickerEnabled(true);
  };

  const [hidePicker, setHidePicker] = useState(false);

  useEffect(() => {
    setPickersVisibility();
  }, []);

  useEffect(() => {
    const objectives = { events, quizzId, surveyId };
    setPickersVisibility();
    props.onChange(objectives);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, quizzId, surveyId]);

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-objectives-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Objectives" : "- Objectives"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <React.Fragment>
          {eventsPickerEnabled && (
            <Grid item xs={12}>
              <EventsPickerNew
                onChange={(events) => setEvents(events)}
                selectedEvents={props.objectives.events}
              />
            </Grid>
          )}
          {quizzPickerEnabled && (
            <Grid item xs={12}>
              <QuizzPicker
                onChange={(quizzId) => setQuizzId(quizzId)}
                quizzId={quizzId}
              />
            </Grid>
          )}
          {surveyPickerEnabled && (
            <Grid item xs={12}>
              <SurveyPicker
                onChange={(surveyId) => setSurveyId(surveyId)}
                surveyId={surveyId}
              />
            </Grid>
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};

ObjectivesPickerNew.propTypes = {
  onChange: PropTypes.func,
  objectives: PropTypes.object.isRequired,
};

export default ObjectivesPickerNew;
