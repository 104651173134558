import React, { useState } from "react";

import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

const ElementCategory = ({ category, onChange }) => {
  const [value, setValue] = useState(category);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-category"
        label="Category:"
        tooltip="[Fixed] You can specify a single category for the element that can be used to filter content for the player"
        onChange={(value) => {
          setValue(value);
          onChange(value);
        }}
        type="text"
      />
    </React.Fragment>
  );
};

ElementCategory.propTypes = {
  category: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ElementCategory;
