import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

//import "../components.css";
import ElementInput from "./ElementInput";
import { removeAllWhitespaces } from "../../utils/utils";

const ElementTags = ({ tags, onChange }) => {
  const [value, setValue] = useState(tags);

  useEffect(() => {
    onChange(removeAllWhitespaces(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-tags"
        label="Tags:"
        tooltip="[Flexible] You can specify multiple tags - comma separated - for the element that can be used to filter content for the player"
        onChange={(value) => setValue(value === "" ? [] : value)}
        type="text"
      />
    </React.Fragment>
  );
};

ElementTags.propTypes = {
  tags: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ElementTags;
