import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import { fetchLevelsForAccount } from "../actions/adminActions";

import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";

const Levels = (props) => {
  return (
    <ElementsPage
      title="Levels"
      getElements={props.getLevelsForAccount}
      elementType={ElementType.LEVEL}
    />
  );
};

Levels.propTypes = {
  getLevelsForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});
const mapDispatchToProps = (dispatch) => ({
  getLevelsForAccount: (query) => dispatch(fetchLevelsForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Levels);
