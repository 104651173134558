import { combineReducers } from "redux";
import { produce } from "immer";
import { connectRouter } from "connected-react-router";

//import other reducers
import adminReducer from "./adminReducer";

//import actions
import {
  LOADING,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  SET_USER_ROLE,
  SET_USER_DATA,
} from "../actions/rootActions";

let loadCounter = 0;
const initialState = {
  loading: false,
  user: {},
  userrole: 0,
  userdata: { role: 0, accounts: [] },
};

// root reducer if needed
const rootReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    //console.log(state,action);
    switch (action.type) {
      case "@@router/LOCATION_CHANGE":
        /*draft.action.name = "routeChanged";
                draft.action.data=action;*/
        break;
      case LOADING:
        loadCounter = action.on ? loadCounter + 1 : loadCounter - 1;
        draft.loading = loadCounter > 0;
        break;
      case USER_LOGGED_IN:
        draft.user = action.user;
        break;
      case USER_LOGGED_OUT:
        draft.user = initialState.user;
        break;
      case SET_USER_ROLE:
        draft.userrole = action.userrole;
        break;
      case SET_USER_DATA:
        draft.userdata = action.userdata;
        break;
      default:
        break;
    }
  });

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    root: rootReducer,
    input: adminReducer,
  });
