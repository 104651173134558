import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import { fetchMysteryboxes } from "../actions/adminActions";

import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";

const MysteryBoxes = (props) => {
  return (
    <ElementsPage
      title="Mystery Boxes"
      getElements={props.getMysteryboxesForAccount}
      elementType={ElementType.MYSTERYBOX}
    />
  );
};

MysteryBoxes.propTypes = {
  getMysteryboxesForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});
const mapDispatchToProps = (dispatch) => ({
  getMysteryboxesForAccount: (query) => dispatch(fetchMysteryboxes(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MysteryBoxes);
