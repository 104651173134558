import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItemExt } from "./PickerItem";

const MissionPickerSingle = (props) => {
  const items = props.missions;
  const [mission, setMission] = useState(null);

  useEffect(() => {
    if (props.missionId) setMission(populateMission(props.missionId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // add item to selected list
  const onSelected = (id, item) => {
    //console.log("item ", item);
    props.onChange(item.id); // item.id
    setMission(item);
  };

  const onDelete = () => {
    props.onChange("");
    setMission(null);
  };

  const populateMission = (id) => {
    const _mission = props.missions.find((mission) => mission.id === id);
    if (_mission) {
      return {
        id: _mission.id,
        name: _mission.name,
        imgUrl: _mission.imgUrl,
      };
    }
    return null;
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Mission:"
        id="id-single-mission-picker"
        required={false}
        onSelected={onSelected}
        items={items}
        tooltip={props.tooltip}
        type="missions"
      />
      {mission && (
        <div className="rows">
          <PickerItemExt
            key={mission.id}
            title="Mission:"
            id={mission.id}
            name={mission.name}
            imgUrl={mission.imgUrl}
            onDelete={onDelete}
          />
          <div className="pickerspace" />
        </div>
      )}
    </div>
  );
};

MissionPickerSingle.propTypes = {
  account: PropTypes.object.isRequired,
  missions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  missionId: PropTypes.string,
  tooltip: PropTypes.string,
};

const mapStateToProps = (state) => ({
  missions: state.input.missions ? state.input.missions : [],
  account: state.input.account,
});

export default connect(mapStateToProps, null)(MissionPickerSingle);
