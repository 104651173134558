const ElementType = {
  ACCOUNT: 1,
  EVENT: 2,
  ACHIEVEMENT: 3,
  MISSION: 4,
  LEVEL: 5,
  PLAYER: 6,
  TEAM: 7,
  LEADERBOARD: 8,
  PRIZE: 9,
  MYSTERYBOX: 10,
  RAFFLE: 11,
  QUIZ: 12,
  SURVEY: 13,
  RULE: 14,
  STREAK: 15,
};
Object.freeze(ElementType);

export { ElementType };
