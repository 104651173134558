import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import ElementSwitch from "./ElementSwitch";
import ElementInput from "./ElementInput";

import "./element-input.css";

const ElementLimitStock = ({ limitStock, stockCount, onChange }) => {
  // Unlimited count is the negation of limit count
  const [_unlimitedStock, setUnlimitedStock] = useState(
    limitStock == null ? false : !limitStock
  );
  const [_stockLimit, setStockLimit] = useState(
    stockCount == null ? 1 : stockCount
  );

  useEffect(() => {
    onChange(!_unlimitedStock, _stockLimit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_unlimitedStock, _stockLimit]);

  return (
    <div className="countlimit">
      <Grid item xs={4}>
        <ElementSwitch
          checked={_unlimitedStock}
          id="id-unlimitedstock"
          label="Unlimited Stock"
          tooltip=""
          onChange={(value) => {
            setUnlimitedStock(value);
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <ElementInput
          value={_stockLimit}
          id="id-stocklimit"
          label="Limit Stock:"
          tooltip="You can specify how many units of stock are available to be redeemed within the refresh period"
          disabled={_unlimitedStock}
          onChange={(value) => setStockLimit(value)}
          type="number"
        />
      </Grid>
    </div>
  );
};

ElementLimitStock.propTypes = {
  limitStock: PropTypes.bool,
  stockCount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default ElementLimitStock;
