import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

const ElementPriority = ({ priority, onChange }) => {
  const [value, setValue] = useState(priority);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-priority"
        type="number"
        label="Priority:"
        tooltip="You can specify a priority for the element that can be used to filter content in the front-end"
        onChange={(value) => setValue(value)}
      />
    </React.Fragment>
  );
};

ElementPriority.propTypes = {
  priority: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default ElementPriority;
