import axios from "axios";
import https from "https";

import { sec } from "../auth/utils";

import {
  accountsPath,
  eventsPath,
  achievementsPath,
  missionsPath,
  teamsPath,
  playersPath,
  levelsPath,
  prizesPath,
  leaderboardsPath,
  analyticsPath,
  mysteryboxesPath,
  rafflesPath,
  quizzesPath,
  surveysPath,
  rulesPath,
  streaksPath,
} from "./paths";

const httpsAgent = new https.Agent({
  maxVersion: "TLSv1.2",
  minVersion: "TLSv1.2",
});

const backendAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADMIN_API_ENDPOINT,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
  httpsAgent: httpsAgent,
});

// Inject JWT into each request
backendAPI.interceptors.request.use(
  async (config) => {
    const token = await sec.getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    })();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const backendFormAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADMIN_API_ENDPOINT,
  timeout: 30000,
  httpsAgent: httpsAgent,
});

// Inject JWT into each request
backendFormAPI.interceptors.request.use(
  async (config) => {
    const token = await sec.getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    })();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//return data from each response and reject in case of error
backendAPI.interceptors.response.use(
  (response) => {
    //console.log(`response.data: ${JSON.stringify(response.data)}`);
    return response.data;
  },
  function (error) {
    //console.log(error);
    //console.log(error.response.data);
    return Promise.reject(error.response.data);
  }
);

backendFormAPI.interceptors.response.use(
  (response) => {
    //console.log(`response.data: ${JSON.stringify(response.data)}`);
    return response.data;
  },
  function (error) {
    return Promise.reject(error.response.data);
  }
);

// We will add support JWT access tokens later

//----- Accounts related Admin APIs ------

/**
 * Retrieves all accounts in the system
 */
async function getAccounts() {
  return backendAPI.get(accountsPath);
}

// accounts/accounts/?accounts=samsung-demo,simple-account
/**
 * Retrieves specific accounts
 * @param {*} accounts - list of accounts IDs, comma-separated
 */
async function queryAccounts(accounts) {
  return backendAPI.get(`${accountsPath}/accounts/?accounts=${accounts}`);
}

/**
 * Creates a new account with given parameters
 * @param {*} account - account object to be created
 */
async function createAccount(account) {
  return backendAPI.post(accountsPath, account);
}

/**
 * Updates account
 * @param {*} event
 */
async function updateAccount(account) {
  return backendAPI.put(accountsPath, account);
}

/**
 * Uploads an image for given account
 * @param {*} account - account object
 * @param {*} image - image file
 */
async function uploadImageForAccount(account, image) {
  const data = new FormData();
  data.append("image", image);
  return backendFormAPI.post(`${accountsPath}/image/${account.account}`, data);
}

/**
 * Copies account elements from account to another
 * @param {*} fromAccount
 * @param {*} toAccount
 */
async function copyAccountElements(fromAccount, toAccount) {
  return backendAPI.post(`${accountsPath}/copy`, {
    fromAccount,
    toAccount,
  });
}

//----- Events related Admin APIs ------

/**
 * Retrieves all events for account based on query
 * @param query
 */
async function getEventsForAccount(query) {
  return backendAPI.get(`${eventsPath}/${query}`);
}

/**
 * Creates a new event with given parameters
 * @param {*} event - event object
 */
async function createEvent(event) {
  return backendAPI.post(eventsPath, event);
}

/**
 * Updates event
 * @param {*} event
 */
async function updateEvent(event) {
  return backendAPI.put(eventsPath, event);
}

/**
 * Delete event
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deleteEvent(account, id) {
  return backendAPI.delete(`${eventsPath}/${account}/${id}`);
}

/**
 * Complete event for given event ID
 * @param {*} eventId
 * @param {*} body
 */
async function completeEvent(eventId, body) {
  return backendAPI.post(`${eventsPath}/${eventId}/complete`, body);
}

//----- Achievement related Admin APIs ------

/**
 * Retrieves all achievement for account
 * @param {*} query
 */
async function getAchievementsForAccount(query) {
  return backendAPI.get(`${achievementsPath}/${query}`);
}

/**
 * Create a new achievement
 * @param {*} achievement - achievement object to be created
 */
async function createAchievement(achievement) {
  return backendAPI.post(achievementsPath, achievement);
}

/**
 * Updates an achievement
 * @param {*} achievement
 */
async function updateAchievement(achievement) {
  return backendAPI.put(achievementsPath, achievement);
}

/**
 * Delete achievement
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deleteAchievement(account, id) {
  return backendAPI.delete(`${achievementsPath}/${account}/${id}`);
}

//----- Mission related Admin APIs ------

/**
 * Retrieves missions for an account based in given query
 * @param query - query to retrieve missions
 */
async function getMissionsForAccount(query) {
  return backendAPI.get(`${missionsPath}/${query}`);
}

/**
 * Create a new mission
 * @param {*} mission - mission object to be created
 */
async function createMission(mission) {
  return backendAPI.post(missionsPath, mission);
}

/**
 * Updates a mission
 * @param {*} mission
 */
async function updateMission(mission) {
  return backendAPI.put(missionsPath, mission);
}

/**
 * Delete mission
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deleteMission(account, id) {
  return backendAPI.delete(`${missionsPath}/${account}/${id}`);
}

//----- Teams related Admin APIs ------

/**
 * Retrieves all teams for account
 * @param {*} query
 */
async function getTeamsForAccount(query) {
  return backendAPI.get(`${teamsPath}/${query}`);
}

/**
 * Create a new team
 * @param {*} team - team object to be created
 */
async function createTeam(team) {
  return backendAPI.post(teamsPath, team);
}

/**
 * Updates a team
 * @param {*} team
 */
async function updateTeam(team) {
  return backendAPI.put(teamsPath, team);
}

/**
 * Delete team
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deleteTeam(account, id) {
  return backendAPI.delete(`${teamsPath}/${id}?account=${account}`);
}

//----- Players related Admin APIs ------

/**
 * Retrieves players for account based on given query
 * @param query - query params for player retrieval
 */
async function getPlayersForAccount(query) {
  //console.log(query);
  return backendAPI.get(`${playersPath}/${query}`);
}

/**
 * Create a new player
 * @param {*} player - player object to be created
 */
async function createPlayer(player) {
  return backendAPI.post(playersPath, player);
}

/**
 * Updates a player
 * @param {*} player
 * @remarks - We use patch to update player data, since put will use upsert
 */
async function updatePlayer(player) {
  return backendAPI.patch(`${playersPath}/${player.player}`, player);
}

/**
 * Delete player
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deletePlayer(account, id) {
  return backendAPI.delete(`${playersPath}/${id}?account=${account}`);
}

//----- Levels related Admin APIs ------

/**
 * Retrieves levels for account
 * @param {*} query
 */
async function getLevelsForAccount(query) {
  return backendAPI.get(`${levelsPath}/${query}`);
}

/**
 * Create a new level
 * @param {*} level - level object to be created
 */
async function createLevel(level) {
  return backendAPI.post(levelsPath, level);
}

/**
 * Updates a level
 * @param {*} level
 */
async function updateLevel(level) {
  return backendAPI.put(levelsPath, level);
}

/**
 * Delete level
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deleteLevel(account, id) {
  return backendAPI.delete(`${levelsPath}/${account}/${id}`);
}

//----- Prizes related Admin APIs ------

/**
 * Retrieves prizes for account
 * @param {*} query
 */
async function getPrizesForAccount(query) {
  return backendAPI.get(`${prizesPath}/${query}`);
}

/**
 * Create a new prize
 * @param {*} prize - prize object to be created
 */
async function createPrize(prize) {
  return backendAPI.post(prizesPath, prize);
}

/**
 * Updates a prize
 * @param {*} prize
 */
async function updatePrize(prize) {
  return backendAPI.put(prizesPath, prize);
}

/**
 * Delete prize
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deletePrize(account, id) {
  return backendAPI.delete(`${prizesPath}/${account}/${id}`);
}

//----- Leaderboard related Admin APIs ------

/**
 * Retrieves leaderboards for account
 * @param {*} query
 */
async function getLeaderboardsForAccount(query) {
  return backendAPI.get(`${leaderboardsPath}/${query}`);
}

/**
 * Create a new leaderboard
 * @param {*} leaderboard - leaderboard object to be created
 */
async function createLeaderboard(leaderboard) {
  return backendAPI.post(leaderboardsPath, leaderboard);
}

/**
 * Updates a leaderboard
 * @param {*} leaderboard
 */
async function updateLeaderboard(leaderboard) {
  return backendAPI.put(leaderboardsPath, leaderboard);
}

/**
 * Delete leaderboard
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deleteLeaderboard(account, id) {
  return backendAPI.delete(`${leaderboardsPath}/${account}/${id}`);
}

//----- Mystery box related Admin APIs ------

/**
 * Query mystery boxes
 * @param {*} query
 */
async function queryMysteryboxes(query) {
  return backendAPI.get(`${mysteryboxesPath}/${query}`);
}

/**
 * Create a new mystery box
 * @param {*} mysterybox - mystery box object to be created
 */
async function createMysterybox(mysterybox) {
  return backendAPI.post(mysteryboxesPath, mysterybox);
}

/**
 * Updates a mystery box
 * @param {*} mysterybox
 */
async function updateMysterybox(mysterybox) {
  return backendAPI.put(mysteryboxesPath, mysterybox);
}

/**
 * Delete mystery box
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deleteMysterybox(account, id) {
  return backendAPI.delete(`${mysteryboxesPath}/${account}/${id}`);
}

//----- Raffle related Admin APIs ------

/**
 * Query raffles
 * @param {*} query
 */
async function queryRaffles(query) {
  return backendAPI.get(`${rafflesPath}/${query}`);
}

/**
 * Create a new raffle
 * @param {*} raffle - raffle object to be created
 */
async function createRaffle(raffle) {
  return backendAPI.post(rafflesPath, raffle);
}

/**
 * Updates a raffle
 * @param {*} raffle - complete raffle object
 */
async function updateRaffle(raffle) {
  return backendAPI.put(rafflesPath, raffle);
}

/**
 * Delete raffle
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deleteRaffle(account, id) {
  return backendAPI.delete(`${rafflesPath}/${account}/${id}`);
}

//----- Quizzes related Admin APIs ------

/**
 * Retrieves quizzes for an account based in given query
 * @param query - query to retrieve quizzes
 */
async function queryQuizzes(query) {
  return backendAPI.get(`${quizzesPath}/${query}`);
}

/**
 * Create a new quiz
 * @param {*} quiz - quiz object to be created
 */
async function createQuiz(quiz) {
  return backendAPI.post(quizzesPath, quiz);
}

/**
 * Updates a quiz
 * @param {*} quiz
 */
async function updateQuiz(quiz) {
  //console.log(JSON.stringify(quiz));
  return backendAPI.put(quizzesPath, quiz);
}

/**
 * Delete quiz
 * @param {*} account - account of the quiz to be deleted
 * @param {*} id - ID of the quiz to be deleted
 */
async function deleteQuiz(account, id) {
  return backendAPI.delete(`${quizzesPath}/${account}/${id}`);
}

//----- Surveys related Admin APIs ------

/**
 * Retrieves surveys for an account based in given query
 * @param query - query to retrieve surveys
 */
async function querySurveys(query) {
  return backendAPI.get(`${surveysPath}/${query}`);
}

/**
 * Create a new survey
 * @param {*} survey - survey object to be created
 */
async function createSurvey(survey) {
  return backendAPI.post(surveysPath, survey);
}

/**
 * Updates a survey
 * @param {*} survey
 */
async function updateSurvey(survey) {
  return backendAPI.put(surveysPath, survey);
}

/**
 * Delete survey
 * @param {*} account - account of the survey to be deleted
 * @param {*} id - ID of the survey to be deleted
 */
async function deleteSurvey(account, id) {
  return backendAPI.delete(`${surveysPath}/${account}/${id}`);
}

//----- Analytics related Admin APIs ------

/**
 * Retrieves analytics for account
 * @param {*} account - account Id
 */
async function getAnalyticsForAccount(account) {
  return backendAPI.get(`${analyticsPath}/?account=${account}`);
}

/**
 * Retrieves analytics for the system
 */
async function getAggregatedAnalytics() {
  return backendAPI.get(`${analyticsPath}/aggregated`);
}

//----- Rules related Admin APIs ------

/**
 * Retrieves rules for an account based in given query
 * @param query - query to retrieve rules
 */
async function queryRules(query) {
  return backendAPI.get(`${rulesPath}/${query}`);
}

async function getRulesMetadata(account) {
  // {{url}}/api/v0/rules/?account={{testaccount}}&metadata=true
  return backendAPI.get(`${rulesPath}/?account=${account}&metadata=true`);
}

/**
 * Create a new rule
 * @param {*} rule - survey object to be created
 */
async function createRule(rule) {
  return backendAPI.post(rulesPath, rule);
}

/**
 * Updates a rule
 * @param {*} rule
 */
async function updateRule(rule) {
  return backendAPI.put(rulesPath, rule);
}

/**
 * Delete rule
 * @param {*} account - account of the rule to be deleted
 * @param {*} id - ID of the rule to be deleted
 */
async function deleteRule(account, id) {
  return backendAPI.delete(`${rulesPath}/${account}/${id}`);
}

//----- Streak related Admin APIs ------

/**
 * Retrieves streaks for an account based in given query
 * @param query - query to retrieve streaks
 */
async function getStreaksForAccount(query) {
  return backendAPI.get(`${streaksPath}/${query}`);
}

/**
 * Create a new streak
 * @param {*} streak - streak object to be created
 */
async function createStreak(streak) {
  return backendAPI.post(streaksPath, streak);
}

/**
 * Updates a streak
 * @param {*} streak
 */
async function updateStreak(streak) {
  return backendAPI.put(streaksPath, streak);
}

/**
 * Delete streak
 * @param {*} account - account of the entity to be deleted
 * @param {*} id - ID of the entity to be deleted
 */
async function deleteStreak(account, id) {
  return backendAPI.delete(`${streaksPath}/${account}/${id}`);
}

export {
  getAccounts,
  queryAccounts,
  createAccount,
  updateAccount,
  copyAccountElements,
  uploadImageForAccount,
  getEventsForAccount,
  createEvent,
  updateEvent,
  deleteEvent,
  completeEvent,
  getAchievementsForAccount,
  createAchievement,
  updateAchievement,
  deleteAchievement,
  getMissionsForAccount,
  createMission,
  updateMission,
  deleteMission,
  getTeamsForAccount,
  createTeam,
  updateTeam,
  deleteTeam,
  getPlayersForAccount,
  createPlayer,
  updatePlayer,
  deletePlayer,
  getLevelsForAccount,
  createLevel,
  updateLevel,
  deleteLevel,
  getPrizesForAccount,
  createPrize,
  updatePrize,
  deletePrize,
  getLeaderboardsForAccount,
  createLeaderboard,
  updateLeaderboard,
  deleteLeaderboard,
  getAnalyticsForAccount,
  getAggregatedAnalytics,
  queryMysteryboxes,
  createMysterybox,
  updateMysterybox,
  deleteMysterybox,
  queryRaffles,
  createRaffle,
  updateRaffle,
  deleteRaffle,
  queryQuizzes,
  createQuiz,
  updateQuiz,
  deleteQuiz,
  querySurveys,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  queryRules,
  getRulesMetadata,
  createRule,
  updateRule,
  deleteRule,
  getStreaksForAccount,
  createStreak,
  updateStreak,
  deleteStreak,
};
