import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

// Development time
import PropTypes from "prop-types";

// Own components
import AchievementsPickerNew from "./AchievementsPickerNew";
import ElementButton from "../inputs/ElementButton";
import ElementPoints from "../inputs/ElementPoints";
import ElementCredits from "../inputs/ElementCredits";

// Styling
import "../inputs/element-input.css";

const ObjectivesPickerLevel = (props) => {
  const [points, setPoints] = useState(props.objectives.points);
  const [credits, setCredits] = useState(props.objectives.credits);
  const [achievements, setAchievements] = useState(
    props.objectives.achievements
  );
  const [hidePicker, setHidePicker] = useState(false);

  useEffect(() => {
    const objectives = { points, credits, achievements };
    props.onChange(objectives);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, credits, achievements]);

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-objectives-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Objectives" : "- Objectives"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <>
          <Grid item xs={12}>
            <ElementPoints
              points={points}
              onChange={(points) => setPoints(points)}
            />
          </Grid>
          <Grid item xs={12}>
            <ElementCredits
              credits={credits}
              onChange={(credits) => setCredits(credits)}
            />
          </Grid>
          <Grid item xs={12}>
            {
              <AchievementsPickerNew
                tooltip="You can specify the achievement[s], if any, that the player must possess for the element to be available to them"
                onChange={(data) => setAchievements(data)}
                selectedAchievements={props.objectives.achievements}
                editMode={false}
              />
            }
          </Grid>
        </>
      )}
    </Grid>
  );
};

ObjectivesPickerLevel.propTypes = {
  onChange: PropTypes.func,
  objectives: PropTypes.object.isRequired,
};

export default ObjectivesPickerLevel;
