import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";

import ElementRadio from "./ElementRadio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ElementTooltip from "./ElementTooltip";

import "./element-input.css";

const UNITS_POINTS = "points";
const UNITS_CREDITS = "credits";

const useStyles = makeStyles({
  label: {
    fontSize: 13,
    fontFamily: "Montserrat",
    fontWeight: 100,
  },
  root: {
    display: "flex",
    justifyContent: "stretch",
  },
});

const ElementLeaderboardUnits = (props) => {
  const classes = useStyles();

  const [units, setUnits] = useState(props.units);
  const [radioActive, setRadioActive] = useState(false);

  const radioBox = `radiobox ${radioActive ? "active" : ""}`;
  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={2}>
        <label className="textlabel">Partition Units:</label>
      </Grid>
      <Grid item xs={10} className="radiorow">
        <Box
          className={radioBox}
          onFocus={() => setRadioActive(true)}
          onBlur={() => setRadioActive(false)}
        >
          <FormControl component="fieldset">
            <RadioGroup
              className="radiocontainer"
              row
              defaultValue={UNITS_POINTS}
              value={units}
              name="refresh-radios"
              onChange={(event) => {
                setUnits(event.target.value);
                props.onChange(event.target.value);
              }}
            >
              <FormControlLabel
                value={UNITS_POINTS}
                control={<ElementRadio id="id-unit-points" />}
                label={
                  <Typography className={classes.label}>Points</Typography>
                }
                labelPlacement="start"
              />
              <Box width={200} />
              <FormControlLabel
                value={UNITS_CREDITS}
                control={<ElementRadio id="id-unit-credits" />}
                label={
                  <Typography className={classes.label}>Credits</Typography>
                }
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <ElementTooltip tooltip="You can specify the units upon which the leaderboard will be based"></ElementTooltip>
      </Grid>
    </Grid>
  );
};

ElementLeaderboardUnits.propTypes = {
  units: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ElementLeaderboardUnits;
