import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "../inputs/element-input.css";

const imgEdit = process.env.PUBLIC_URL + "/assets/images/edit.png";
const imgDone = process.env.PUBLIC_URL + "/assets/images/checked.png";
const imgDelete = process.env.PUBLIC_URL + "/assets/images/trash-bin.png";

import { defaultImage } from "../../utils/imageutils";

const PickerItemExt = ({
  id,
  name,
  imgUrl,
  title,
  onDelete,
  status,
  description,
}) => {
  const [iconImg, setIconImg] = useState(imgDelete);
  useEffect(() => {
    if (!onDelete) {
      setIconImg(status === "completed" ? imgDone : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="itembox">
      <div>
        <img
          className="itemboximage"
          alt=""
          src={imgUrl ? imgUrl : defaultImage()}
        />
      </div>
      <div className="itemboxdata">
        <label className="itemboxlabel padding">{title}</label>
        <label className="itemboxlabel bold">{name}</label>
        {description && <label className="itemboxdesc">{description}</label>}
      </div>
      <div className="itemboxicons">
        <img
          className="pickerimage"
          src={iconImg}
          alt=""
          onClick={() => (onDelete ? onDelete(id) : {})}
        />
      </div>
    </div>
  );
};

const PickerItem = ({
  id,
  name,
  count,
  imgUrl,
  title,
  onChange,
  onDelete,
  label,
}) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(count);
  const [disabled, setDisabled] = useState(true);
  const field = `field ${active ? "active" : ""}`;

  const changeValue = (event) => {
    const value = event.target.value;
    setValue(value);
    onChange(id, value);
    //setProperties({ ...properties, value, error: "" });
  };

  const itemBox = `itembox ${active ? "active" : ""}`;

  return (
    <div
      className={itemBox}
      onFocus={() => {
        setActive(true);
      }}
      onBlur={() => setActive(false)}
    >
      <div>
        <img className="itemboximage" src={imgUrl} alt="" />
      </div>
      <div className="itemboxdata">
        <label className="itemboxlabel padding">{title}</label>
        <label className="itemboxlabel bold">{name}</label>
        <label className="itemboxlabel padding">{label}</label>
        <input
          className={field + " marginbottom"}
          id={"item" + id}
          type="number"
          value={value}
          disabled={disabled}
          onChange={(event) => changeValue(event)}
        />
      </div>
      <div className="itemboxicons">
        <img
          className="pickerimage"
          src={imgDelete}
          alt=""
          onClick={() => onDelete(id)}
        />
        <img
          className="pickerimage"
          src={disabled ? imgEdit : imgDone}
          alt=""
          onClick={() => {
            setDisabled(!disabled);
          }}
        />
      </div>
    </div>
  );
};

PickerItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  imgUrl: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

PickerItemExt.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  imgUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  onDelete: PropTypes.func,
};

export { PickerItem, PickerItemExt };
