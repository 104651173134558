import { formatRFC3339 } from "date-fns";

const requirementDefault = {
  //points: 0,
  //credits: 0,
  level: {},
  tags: [],
  //categories: [],
  missions: [],
  achievements: [],
};

const rewardDefault = {
  points: 0,
  credits: 0,
  achievements: [],
};

const objectivesDefault = {
  events: [],
  missions: [],
};

// Default elements
const defaultMission = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  priority: 0,
  isAvailable: true,
  limitCount: true,
  countLimit: 1,
  active: {
    from: formatRFC3339(new Date()),
    to: formatRFC3339(new Date()),
  },
  period: "all-time",
  timeToComplete: 0,
  timeToRestart: 0,
  objectives: objectivesDefault,
  requirement: requirementDefault,
  reward: rewardDefault,
  languages: [],
};

const defaultStreak = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  priority: 0,
  isAvailable: true,
  limitCount: true,
  countLimit: 1,
  active: {
    from: formatRFC3339(new Date()),
    to: formatRFC3339(new Date()),
  },
  period: "monthly",
  objectives: [],
  reward: rewardDefault,
  languages: [],
};

const defaultEvent = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  isAvailable: true,
  countLimit: 1,
  limitCount: false,
  reward: rewardDefault,
};

const defaultAccount = {
  account: "",
  name: "",
  description: "",
  apikey: "",
  imageFolder: "",
  imgUrl: "",
  isDevAccount: true,
  isFreeAccount: false,
};

const defaultTeam = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  points: 0,
  credits: 0,
  level: {},
  isAvailable: true,
};

const defaultAchievement = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  isAvailable: true,
  steps: 0,
  requirement: requirementDefault,
  reward: rewardDefault,
  languages: [],
};

const defaultLevel = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  isAvailable: true,
  ordinal: 1,
  objectives: {
    points: 0,
    credits: 0,
    achievements: [],
  },
  reward: rewardDefault,
  languages: [],
};

const defaultLeaderboard = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  isAvailable: true,
  element: "players",
  units: "points",
  //rankBy: rankbyTotal,
  active: {
    from: formatRFC3339(new Date()),
    to: formatRFC3339(new Date()),
  },
  period: "all-time",
  requirement: requirementDefault,
  languages: [],
  drivenBy: {
    missions: [],
  },
};

const defaultPrize = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  isAvailable: true,
  credits: 0,
  active: {
    from: formatRFC3339(new Date()),
    to: formatRFC3339(new Date()),
  },
  period: "all-time",
  requirement: requirementDefault,
  limitCount: true,
  countLimit: 1,
  limitStock: true,
  stockCount: 1,
  languages: [],
};

const defaultRaffle = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  isAvailable: true,
  credits: 0,
  drawTime: formatRFC3339(new Date()),
  active: {
    from: formatRFC3339(new Date()),
    to: formatRFC3339(new Date()),
  },
  prizes: [],
  period: "all-time",
  requirement: requirementDefault,
  limitCount: true,
  countLimit: 1,
  languages: [],
};

const defaultQuiz = {
  id: "",
  name: "",
  description: "",
  category: "",
  imgUrl: "",
  isAvailable: true,
  type: "basic",
  requiredPassRate: 100,
  passMessage: "",
  failMessage: "",
  isShuffleRequired: true,
  timing: {
    maxSecondsToComplete: 120,
  },
  questions: [],
  reward: rewardDefault,
  requirement: requirementDefault,
};

const defaultSurvey = {
  id: "",
  name: "",
  description: "",
  category: "",
  imgUrl: "",
  isAvailable: true,
  type: "basic",
  isShuffleRequired: true,
  questions: [],
  reward: rewardDefault,
  requirement: requirementDefault,
};

const defaultMysterybox = {
  id: "",
  name: "",
  description: "",
  category: "",
  tags: "",
  imgUrl: "",
  isAvailable: true,
  credits: 0,
  active: {
    from: formatRFC3339(new Date()),
    to: formatRFC3339(new Date()),
  },
  prizes: [],
  period: "all-time",
  requirement: requirementDefault,
  limitCount: true,
  countLimit: 1,
};

const defaultPlayer = {
  player: "",
  name: "",
  category: "",
  tags: "",
  imgUrl: "",
  points: 0,
  credits: 0,
  team: "",
  level: {},
};

const defaultRule = {
  id: "",
  name: "",
  description: "",
  imgUrl: "",
  isAvailable: true,
  conditionId: "startDateOrCreatedOnRange",
  actionId: "actionMultiplyGainedPoints",
  conditionParams: {
    minDays: { value: 7 },
    maxDays: { value: 10 },
    days: { value: 7 },
    startDate: { value: "2023-09-02" },
  },
  actionParams: {
    multiplier: { value: 2 },
  },
  priority: 100,
  breakOnEval: true,
};

export {
  requirementDefault,
  rewardDefault,
  objectivesDefault,
  defaultMission,
  defaultEvent,
  defaultAccount,
  defaultTeam,
  defaultAchievement,
  defaultLevel,
  defaultLeaderboard,
  defaultPrize,
  defaultRaffle,
  defaultMysterybox,
  defaultPlayer,
  defaultQuiz,
  defaultSurvey,
  defaultRule,
  defaultStreak,
};
