export const accountsPath = "/accounts";
export const eventsPath = "/events";
export const achievementsPath = "/achievements";
export const missionsPath = "/missions";
export const teamsPath = "/teams";
export const playersPath = "/players";
export const levelsPath = "/levels";
export const prizesPath = "/prizes";
export const leaderboardsPath = "/leaderboards";
export const analyticsPath = "/analytics";
export const mysteryboxesPath = "/mysteryboxes";
export const rafflesPath = "/raffles";
export const quizzesPath = "/quizzes";
export const surveysPath = "/surveys";
export const rulesPath = "/rules";
export const streaksPath = "/streaks";
