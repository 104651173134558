import produce from "immer";

//Import actions
import {
  GET_ACCOUNTS_SUCCESS,
  UPLOAD_IMAGE_SUCCESS,
  SELECT_ACCOUNT,
  GET_EVENTS_SUCCESS,
  COMPLETE_EVENT_SUCCESS,
  GET_ACHIEVEMENTS_SUCCESS,
  GET_MISSIONS_SUCCESS,
  GET_TEAMS_SUCCESS,
  GET_PLAYERS_SUCCESS,
  GET_LEVELS_SUCCESS,
  GET_PRIZES_SUCCESS,
  GET_LEADERBOARDS_SUCCESS,
  GET_ANALYTICS_SUCCESS,
  GET_MYSTERYBOXES_SUCCESS,
  GET_RAFFLES_SUCCESS,
  GET_QUIZZES_SUCCESS,
  GET_SURVEYS_SUCCESS,
  GET_RULES_SUCCESS,
  GET_RULES_METADATA_SUCCESS,
  GET_STREAKS_SUCCESS,
} from "../actions/adminActions";

const initialState = {
  accounts: [], // list of accounts
  events: [], // list of events
  achievements: [], // list of achievements,
  missions: [], // list of missions
  teams: [], // list of teams
  players: [], // list of players
  levels: [], // list of levels
  prizes: [], // list of prizes
  leaderboards: [],
  mysteryboxes: [],
  raffles: [],
  quizzes: [],
  surveys: [],
  rules: [],
  streaks: [],
  rulesmetadata: {},
  analytics: {},
  account: { _id: "", account: "", name: "", description: "", apikey: "" }, // selected account
  imagePath: "",
  eventData: {},
};

const adminReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ACCOUNTS_SUCCESS:
        draft.accounts = action.accounts;
        break;
      case UPLOAD_IMAGE_SUCCESS:
        draft.imagePath = action.imagePath;
        break;
      case GET_EVENTS_SUCCESS:
        draft.events = action.events;
        break;
      case COMPLETE_EVENT_SUCCESS:
        draft.eventData = action.eventData;
        break;
      case GET_ACHIEVEMENTS_SUCCESS:
        draft.achievements = action.achievements;
        break;
      case GET_MISSIONS_SUCCESS:
        draft.missions = action.missions;
        break;
      case GET_TEAMS_SUCCESS:
        draft.teams = action.teams;
        break;
      case GET_PLAYERS_SUCCESS:
        draft.players = action.players;
        break;
      case GET_LEVELS_SUCCESS:
        draft.levels = action.levels;
        break;
      case GET_PRIZES_SUCCESS:
        draft.prizes = action.prizes;
        break;
      case GET_LEADERBOARDS_SUCCESS:
        draft.leaderboards = action.leaderboards;
        break;
      case GET_MYSTERYBOXES_SUCCESS:
        draft.mysteryboxes = action.mysteryboxes;
        break;
      case GET_RAFFLES_SUCCESS:
        draft.raffles = action.raffles;
        break;
      case GET_QUIZZES_SUCCESS:
        draft.quizzes = action.quizzes;
        break;
      case GET_SURVEYS_SUCCESS:
        draft.surveys = action.surveys;
        break;
      case GET_RULES_SUCCESS:
        draft.rules = action.rules;
        break;
      case GET_RULES_METADATA_SUCCESS:
        draft.rulesmetadata = action.rulesmetadata;
        break;
      case GET_ANALYTICS_SUCCESS:
        draft.analytics = action.analytics;
        break;
      case GET_STREAKS_SUCCESS:
        draft.streaks = action.streaks;
        break;
      case SELECT_ACCOUNT:
        draft.account = action.account;
        //console.log(`selected account: ${JSON.stringify(action.account)}`);
        break;
      default:
        break;
    }
  });

export default adminReducer;
