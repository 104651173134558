import { uploadImageForAccount } from "../../api/backendAPI";

function updateQuizImages(quiz, id, path) {
  if (quiz == null || quiz.questions == null) return;
  for (var i = 0; i < quiz.questions.length; i++) {
    if (quiz.questions[i].id === id) {
      quiz.questions[i].imgUrl = path;
      //console.log(`Question: Updating path ${path} for id: ${id}`);
      return;
    }
    if (quiz.questions.choices == null) continue;
    for (var j = 0; j < quiz.questions[i].choices.length; j++) {
      if (quiz.questions[i].choices[j].id === id) {
        quiz.questions[i].choices[j].imgUrl = path;
        //console.log(`Choice: Updating path ${path} for id: ${id}`);
        return;
      }
    }
  }
}

function updateQuizImagePaths(questions, id, path) {
  if (questions == null) return questions;
  for (var i = 0; i < questions.length; i++) {
    if (questions[i].id === id) {
      questions[i].imgUrl = path;
      //console.log(`Question: Updating path ${path} for id: ${id}`);
      return questions;
    }
    if (questions[i].choices == null) continue;
    for (var j = 0; j < questions[i].choices.length; j++) {
      if (questions[i].choices[j].id === id) {
        questions[i].choices[j].imgUrl = path;
        //console.log(`Choice: Updating path ${path} for id: ${id}`);
        return questions;
      }
    }
  }
  return questions;
}

const saveImageCache = async (quiz, imageCache, account) => {
  for (const imageData of imageCache) {
    //console.log(JSON.stringify(imageData));
    const res = await uploadImageForAccount(account, imageData.image);
    const { path } = res;
    updateQuizImages(quiz, imageData.id, path);
  }
};

export { saveImageCache, updateQuizImagePaths };
