if (process.env.NODE_ENV !== "production") {
  require("dotenv").config();
}

/**
 * Returns an url for a file. If none given, url to default is provided
 * @param {*} file
 * @returns url to image
 */
const imageUrl = (file) => {
  return file && file instanceof File
    ? URL.createObjectURL(file)
    : process.env.REACT_APP_DEFAULT_IMAGE;
};

const defaultImage = () => {
  return process.env.REACT_APP_DEFAULT_IMAGE;
};

export { imageUrl, defaultImage };
