import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

const ElementName = ({ name, onChange }) => {
  const [value, setValue] = useState(name);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-name"
        label="Name:"
        required
        tooltip="[Required] This is the name of the element that, depending on needs, will be displayed in the front-end"
        onChange={(value) => setValue(value)}
        type="text"
      />
    </React.Fragment>
  );
};

ElementName.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ElementName;
