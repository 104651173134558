import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "../components.css";
import ElementSwitch from "./ElementSwitch";

const ElementIsAvailable = ({ isAvailable, onChange }) => {
  const [value, setValue] = useState(isAvailable);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <ElementSwitch
        checked={value}
        id="id-isavailable"
        label="Available"
        tooltip="Is the element available for actions"
        onChange={(value) => setValue(value)}
      />
    </React.Fragment>
  );
};

ElementIsAvailable.propTypes = {
  isAvailable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ElementIsAvailable;
