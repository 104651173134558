import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import { fetchQuizzesForAccount } from "../actions/adminActions";

import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";

const Quizzes = (props) => {
  return (
    <ElementsPage
      title="Quizzes"
      getElements={props.getQuizzesForAccount}
      elementType={ElementType.QUIZ}
    />
  );
};

Quizzes.propTypes = {
  getQuizzesForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});
const mapDispatchToProps = (dispatch) => ({
  getQuizzesForAccount: (query) => dispatch(fetchQuizzesForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Quizzes);
