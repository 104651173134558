import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import { fetchLeaderboardsForAccount } from "../actions/adminActions";

import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";

const Leaderboards = (props) => {
  return (
    <ElementsPage
      title="Leaderboards"
      getElements={props.getLeaderboardsForAccount}
      elementType={ElementType.LEADERBOARD}
    />
  );
};

Leaderboards.propTypes = {
  getLeaderboardsForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});
const mapDispatchToProps = (dispatch) => ({
  getLeaderboardsForAccount: (query) =>
    dispatch(fetchLeaderboardsForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboards);
