import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import history from "./utils/history";

// Import Reducer
import rootReducer from "./reducers/rootReducer";

const store = createStore(
  rootReducer(history),
  compose(applyMiddleware(routerMiddleware(history), thunk))
);
export default store;
