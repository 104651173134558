import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";
import { fetchStreaksForAccount } from "../actions/adminActions";

const Streaks = (props) => {
  return (
    <ElementsPage
      title="Streaks"
      getElements={props.getStreaksForAccount}
      elementType={ElementType.STREAK}
    />
  );
};

Streaks.propTypes = {
  getStreaksForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});

const mapDispatchToProps = (dispatch) => ({
  getStreaksForAccount: (query) => dispatch(fetchStreaksForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Streaks);
