import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

// Development time
import PropTypes from "prop-types";

// Own components
import LevelPickerNew from "./LevelPickerNew";
import MissionsPickerNew from "./MissionsPickerNew";
import ElementButton from "../inputs/ElementButton";
import AchievementsPickerNew from "./AchievementsPickerNew";
import ElementInput from "../inputs/ElementInput";

// Styling
import "../inputs/element-input.css";

const RequirementsPickerNew = (props) => {
  const [tags, setTags] = useState(
    props.requirement ? props.requirement.tags : ""
  );
  const [category, setCategory] = useState(
    props.requirement ? props.requirement.category : ""
  );
  const [level, setLevel] = useState(
    props.requirement ? props.requirement.level : null
  );
  const [missions, setMissions] = useState(
    props.requirement ? props.requirement.missions : []
  );
  const [achievements, setAchievements] = useState(
    props.requirement ? props.requirement.achievements : []
  );

  const [hidePicker, setHidePicker] = useState(false);

  useEffect(() => {
    const requirement = { category, tags, missions, achievements, level };
    props.onChange(requirement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, category, missions, achievements, level]);

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-timer-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Requirements" : "- Requirements"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <React.Fragment>
          <Grid item xs={12}>
            <ElementInput
              label="Category:"
              id="id-cat-req"
              type="text"
              tooltip="You can specify the category, if any, that the player must possess for the element to be available to them"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ElementInput
              label="Tags:"
              id="id-tags-req"
              type="text"
              tooltip="You can specify the tag[s], if any, that the player must possess for the element to be available to them"
              value={tags}
              onChange={(value) => setTags(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <LevelPickerNew
              level={level}
              tooltip="You can specify the level[s], if any, that the player must have reached for the element to be available to them"
              onChange={(level) => setLevel(level)}
            />
          </Grid>
          <Grid item xs={12}>
            <MissionsPickerNew
              tooltip="You can specify the mission[s], if any, that the player must have completed for the element to be available to them"
              onChange={(data) => setMissions(data)}
              selectedMissions={
                props.requirement ? props.requirement.missions : []
              }
              missionId={props.missionId}
              editMode={false}
            />
          </Grid>
          <Grid item xs={12}>
            <AchievementsPickerNew
              tooltip="You can specify the achievement[s], if any, that the player must possess for the element to be available to them"
              onChange={(data) => setAchievements(data)}
              selectedAchievements={
                props.requirement ? props.requirement.achievements : []
              }
              achievementId={props.achievementId}
              editMode={false}
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

RequirementsPickerNew.propTypes = {
  onChange: PropTypes.func,
  requirement: PropTypes.object,
  missionId: PropTypes.string,
  achievementId: PropTypes.string,
};

export default RequirementsPickerNew;
