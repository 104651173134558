import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";

import ElementRadio from "./ElementRadio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ElementTooltip from "./ElementTooltip";

import "./element-input.css";

const ELEMENT_PLAYER = "player";
const ELEMENT_TEAM = "team";
const ELEMENT_TEAM_MEMBERS = "teammembers";

const useStyles = makeStyles({
  label: {
    fontSize: 13,
    fontFamily: "Montserrat",
    fontWeight: 100,
  },
  root: {
    display: "flex",
    justifyContent: "stretch",
  },
});

const ElementLeaderboardElement = (props) => {
  const classes = useStyles();

  const [element, setElement] = useState(props.element);
  const [radioActive, setRadioActive] = useState(false);

  const radioBox = `radiobox ${radioActive ? "active" : ""}`;
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={2}>
        <label className="textlabel">Partition Element:</label>
      </Grid>
      <Grid item xs={10} className="radiorow">
        <Box
          className={radioBox}
          onFocus={() => setRadioActive(true)}
          onBlur={() => setRadioActive(false)}
        >
          <FormControl component="fieldset">
            <RadioGroup
              className="radiocontainer"
              row
              defaultValue={ELEMENT_PLAYER}
              value={element}
              name="refresh-radios"
              onChange={(event) => {
                setElement(event.target.value);
                props.onChange(event.target.value);
              }}
            >
              <FormControlLabel
                value={ELEMENT_PLAYER}
                control={<ElementRadio id="id-player" />}
                label={
                  <Typography className={classes.label}>
                    Player vs. Player
                  </Typography>
                }
                labelPlacement="start"
              />
              <Box width={5} />
              <FormControlLabel
                value={ELEMENT_TEAM}
                control={<ElementRadio id="id-team" />}
                label={
                  <Typography className={classes.label}>
                    Team vs. Team
                  </Typography>
                }
                labelPlacement="start"
              />
              <Box width={5} />
              <FormControlLabel
                value={ELEMENT_TEAM_MEMBERS}
                control={<ElementRadio id="id-team-members" />}
                label={
                  <Typography className={classes.label}>
                    Team Member vs. Team Member
                  </Typography>
                }
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <ElementTooltip tooltip="You can specify the element upon which the leaderboard will be based"></ElementTooltip>
      </Grid>
    </Grid>
  );
};

ElementLeaderboardElement.propTypes = {
  element: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ElementLeaderboardElement;
