import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";
import { fetchMissionsForAccount } from "../actions/adminActions";

const Missions = (props) => {
  return (
    <ElementsPage
      title="Missions"
      getElements={props.getMissionsForAccount}
      elementType={ElementType.MISSION}
    />
  );
};

Missions.propTypes = {
  getMissionsForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});

const mapDispatchToProps = (dispatch) => ({
  getMissionsForAccount: (query) => dispatch(fetchMissionsForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Missions);
