import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// Material-UI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";

// Own components
import { addEvent, modifyEvent, removeEvent } from "../actions/adminActions";

// New UI components
import ElementId from "../components/inputs/ElementId";
import ElementName from "../components/inputs/ElementName";
import ElementDescription from "../components/inputs/ElementDescription";
import ElementImage from "../components/inputs/ElementImage";
import ElementTags from "../components/inputs/ElementTags";
import ElementCategory from "../components/inputs/ElementCategory";
import ElementIsAvailable from "../components/inputs/ElementIsAvailable";
import ElementButton from "../components/inputs/ElementButton";
import ElementLimitCount from "../components/inputs/ElementLimitCount";
import LanguageTable from "../components/inputs/LanguageTable";

import RewardsPickerNew from "../components/pickers/RewardsPickerNew";
import { uploadImageForAccount } from "../api/backendAPI";
import { isEmpty } from "../utils/utils";
import { ErrorDialog } from "../components/Dialogs";
import { hasAdminRights } from "../auth/roles";

const cloneDeep = require("lodash.clonedeep");

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    //   background: "#f6f7fa",
    background: "white",
    paddingTop: 15,
    paddingBottom: 10,
    paddingLeft: 62,
  },
}));

const ElementEvent = (props) => {
  const classes = useStyles();

  const [formData, setFormData] = useState(
    props.data.languages != null ? props.data : { ...props.data, languages: [] }
  );
  const [imageSource, setImageSource] = useState({});

  // Form validity
  const [validForm, setValidForm] = useState({
    id: props.data.id !== "",
    name: props.data.name !== "",
  });

  const [validId, setValidId] = useState(true);
  const isFormValid = validForm.name && validForm.id;
  const [showError, setShowError] = useState(false);

  useEffect(() => {}, [formData]);
  // Material-UI

  const saveData = (path) => {
    let data = cloneDeep(formData);
    data.account = props.account.account;
    data.imgUrl = path;
    if (props.updateMode) {
      props.updateEntity(data).then(() => {
        props.onUpdated();
      });
    } else {
      props.createEntity(data).then(() => props.onUpdated());
    }
  };

  const saveEntity = () => {
    if (isEmpty(imageSource) && !props.updateMode) {
      saveData(process.env.REACT_APP_DEFAULT_IMAGE);
    } else {
      if (!isEmpty(imageSource)) {
        uploadImageForAccount(props.account, imageSource)
          .then((res) => {
            const { path } = res;
            saveData(path);
          })
          .catch((err) => {
            toast.error(
              `Failed to upload image. ${err.error ? err.error : ""}`
            );
          });
      } else {
        saveData(formData.imgUrl);
      }
    }
  };

  const onImageChanged = (data) => {
    setImageSource(data.file);
  };

  const onRewardsChanged = (data) => {
    const s = { ...formData };
    s.reward = cloneDeep(data);
    setFormData(s);
  };

  const isValidId = (id) => {
    if (!props.updateMode) {
      // ID must be unique
      const obj = props.entities.find((item) => item.id === id);
      setValidForm({
        ...validForm,
        id: obj === undefined,
      });
      setValidId(obj === undefined);
    }
  };

  const isValidName = (name) => {
    setValidForm({
      ...validForm,
      name: name !== "",
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (isFormValid) {
      saveEntity();
    } else {
      setShowError(!validId);
    }
  };

  return (
    <div className={classes.root}>
      <form className="needs-validation" noValidate onSubmit={submitHandler}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid
            container
            xs={8}
            spacing={2}
            alignItems="center"
            justify="center"
            //style={{ width: "70%" }}
          >
            <Grid item xs={12}>
              <ElementIsAvailable
                isAvailable={formData.isAvailable}
                onChange={(isAvailable) =>
                  setFormData({ ...formData, isAvailable })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <ElementId
                disabled={props.updateMode}
                id={formData.id}
                onChange={(id) => {
                  setFormData({ ...formData, id });
                  isValidId(id);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementName
                name={formData.name}
                onChange={(name) => {
                  setFormData({ ...formData, name });
                  isValidName(name);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementDescription
                description={formData.description}
                onChange={(description) => {
                  setFormData({ ...formData, description });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementImage
                path={formData.imgUrl}
                onDataChanged={(data) => {
                  onImageChanged(data);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementCategory
                category={formData.category}
                onChange={(category) => {
                  setFormData({ ...formData, category });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementTags
                tags={formData.tags}
                onChange={(tags) => {
                  setFormData({ ...formData, tags });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementLimitCount
                limitCount={
                  formData.limitCount != null ? formData.limitCount : false
                }
                countLimit={formData.countLimit}
                onChange={(limitCount, countLimit) => {
                  setFormData({
                    ...formData,
                    limitCount,
                    countLimit,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <RewardsPickerNew
                onChange={(data) => onRewardsChanged(data)}
                reward={formData.reward}
              />
            </Grid>
            <Grid item xs={12}>
              <LanguageTable
                languages={formData.languages}
                onChange={(languages) => {
                  setFormData({ ...formData, languages: languages });
                }}
              />
            </Grid>
          </Grid>
          {hasAdminRights() && (
            <Grid item xs={4}>
              <div className="buttonrow">
                <div style={{ paddingRight: 20 }}>
                  <ElementButton
                    id="id-save-btn"
                    variant="contained"
                    size="small"
                    color="amber"
                    type="submit"
                  >
                    Save
                  </ElementButton>
                </div>
                {props.updateMode && (
                  <ElementButton
                    id="id-delete-btn"
                    variant="contained"
                    size="small"
                    color="red"
                    onClick={() => {
                      props
                        .deleteEntity(props.account.account, formData.id)
                        .then(() => props.onUpdated());
                    }}
                  >
                    Delete
                  </ElementButton>
                )}
                {!props.updateMode && (
                  <ElementButton
                    id="id-cancel-btn"
                    variant="contained"
                    size="small"
                    color="blue"
                    onClick={() => {
                      if (props.onCancel) props.onCancel();
                    }}
                  >
                    Cancel
                  </ElementButton>
                )}
              </div>
            </Grid>
          )}
          {showError && (
            <ErrorDialog
              title={`Event with ${formData.id} exists already. Please use different ID.`}
              onDone={() => setShowError(false)}
            />
          )}
        </Grid>
      </form>
    </div>
  );
};

ElementEvent.propTypes = {
  data: PropTypes.object.isRequired,
  updateMode: PropTypes.bool.isRequired,
  account: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onUpdated: PropTypes.func,
  createEntity: PropTypes.func.isRequired,
  updateEntity: PropTypes.func.isRequired,
  deleteEntity: PropTypes.func.isRequired,
  entities: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
  entities: state.input.events,
});

const mapDispatchToProps = (dispatch) => ({
  createEntity: (event) => dispatch(addEvent(event)),
  updateEntity: (data) => dispatch(modifyEvent(data)),
  deleteEntity: (account, id) => dispatch(removeEvent(account, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ElementEvent);
