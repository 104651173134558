import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import { fetchRaffles } from "../actions/adminActions";

import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";

const Raffles = (props) => {
  return (
    <ElementsPage
      title="Raffles"
      getElements={props.getRafflesForAccount}
      elementType={ElementType.RAFFLE}
    />
  );
};

Raffles.propTypes = {
  getRafflesForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});
const mapDispatchToProps = (dispatch) => ({
  getRafflesForAccount: (query) => dispatch(fetchRaffles(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Raffles);
