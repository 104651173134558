import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItemExt } from "./PickerItem";

const LevelPickerNew = (props) => {
  const items = props.levels;
  const [level, setLevel] = useState(null);

  useEffect(() => {
    if (props.level) setLevel(populateLevel(props.level.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // add item to selected list
  const onSelected = (id, item) => {
    //console.log("item ", item);
    props.onChange(item); // item.id
    setLevel(item);
  };

  const onDelete = () => {
    props.onChange({ id: "", ordinal: 0 });
    setLevel(null);
  };

  const populateLevel = (id) => {
    const _level = props.levels.find((level) => level.id === id);
    if (_level) {
      return {
        id: _level.id,
        name: _level.name,
        imgUrl: _level.imgUrl,
      };
    }
    return null;
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Level:"
        id="id-levelpicker"
        required={false}
        onSelected={onSelected}
        items={items}
        tooltip={props.tooltip}
        type="levels"
      />
      {level && (
        <div className="rows">
          <PickerItemExt
            key={level.id}
            title="Level:"
            id={level.id}
            name={level.name}
            imgUrl={level.imgUrl}
            onDelete={onDelete}
          />
          <div className="pickerspace" />
        </div>
      )}
    </div>
  );
};

LevelPickerNew.propTypes = {
  account: PropTypes.object.isRequired,
  levels: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  level: PropTypes.object,
  tooltip: PropTypes.string,
};

const mapStateToProps = (state) => ({
  levels: state.input.levels ? state.input.levels : [],
  account: state.input.account,
});

export default connect(mapStateToProps, null)(LevelPickerNew);
