import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import "./element-input.css";

// Development time
import PropTypes from "prop-types";

// Own components
import ElementInput from "./ElementInput";
import ElementSwitch from "./ElementSwitch";
import ElementButton from "./ElementButton";
import ElementTooltip from "./ElementTooltip";
import ElementRadio from "./ElementRadio";
import ElementImage from ".//ElementImage";

import { uniqueId } from "../../utils/utils";
//import { imageUrl } from "../../utils/imageutils";

// Styling
import "./element-input.css";
import { isEmpty } from "lodash";

const useStyles = makeStyles({
  label: {
    fontSize: 13,
    fontFamily: "Montserrat",
    fontWeight: 100,
  },
  root: {
    display: "flex",
    justifyContent: "stretch",
  },
});

const createDefaultChoice = (isSurveyQuestion) => {
  return isSurveyQuestion
    ? {
        text: "",
        key: uniqueId(),
        id: uniqueId(),
      }
    : {
        text: "",
        correctAnswer: false,
        key: uniqueId(),
        id: uniqueId(),
      };
};

const TYPE_SINGLE = "single";
const TYPE_MULTI = "multi";

//const QUIZ_BASIC = "basic";
//const QUIZ_IMMEDIATE = "immediate";

const Choice = (props) => {
  const [text, setText] = useState(props.choice.text);
  const [correctAnswer, setCorrectAnswer] = useState(
    props.choice.correctAnswer
  );

  const isSurveyQuestion = props.questionType === "survey";

  useEffect(() => {
    //console.log(`text: ${text}, correctAnswer: ${correctAnswer}`);
    if (isSurveyQuestion) {
      props.onChange({ text, id: props.choice.id }, props.count);
    } else {
      props.onChange({ text, correctAnswer, id: props.choice.id }, props.count);
    }
    props.onChange({ text, correctAnswer, id: props.choice.id }, props.count);
  }, [text, correctAnswer]);

  //           tooltip = "[Required] Answer option for the question"*

  const onImageChanged = (data) => {
    const choiceImage = { image: data.file, id: props.choice.id };
    //setImgUrl(imagePath);
    if (isEmpty(data)) return;
    //console.log(`Choice: onImageChanged => ${choiceImage}`);
    //console.log(data.file);
    props.onImageUpdated(choiceImage);
  };
  return (
    <React.Fragment>
      <Grid item xs={9}>
        <ElementInput
          value={text}
          id={"id-choice" + props.count}
          label={isSurveyQuestion ? "Response" : "Choice:"}
          required
          onChange={(text) => setText(text)}
          type="text"
        />
      </Grid>

      <Grid item xs={3}>
        {!isSurveyQuestion && (
          <ElementSwitch
            checked={correctAnswer}
            id={"id-correctAnswer" + props.count}
            label="Correct answer"
            onChange={(correctAnswer) => setCorrectAnswer(correctAnswer)}
          />
        )}
        <ElementButton
          width="100px"
          height="30px"
          id="id-btn-delete-chouce"
          variant="contained"
          component="span"
          color="red"
          onClick={() => {
            props.onDelete(props.count);
          }}
        >
          Delete
        </ElementButton>
      </Grid>

      <Grid item xs={12}>
        <ElementImage
          size="small"
          path={props.choice.imgUrl}
          id={"choice-image-id" + props.count}
          onDataChanged={(data) => {
            if (!isEmpty(data)) onImageChanged(data);
          }}
        />{" "}
      </Grid>
    </React.Fragment>
  );
};

Choice.propTypes = {
  choice: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onImageUpdated: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  questionType: PropTypes.string,
};

const ElementQuizQuestion = (props) => {
  const classes = useStyles();

  //const [imgUrl, setImgUrl] = useState(props.question.imgUrl);
  const [type, setType] = useState(props.question.type);

  const [weight, setWeight] = useState(props.question.weight);
  const [strictMode, setStrictMode] = useState(props.question.strictMode);
  const [text, setText] = useState(props.question.text);
  const [choices, setChoices] = useState(
    props.question.choices ? props.question.choices : []
  );
  const [radioActive, setRadioActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(0);

  const buttonText = " Question " + (props.count + 1);
  const radioBox = `radiobox ${radioActive ? "active" : ""}`;
  const isSurveyQuestion = props.questionType === "survey";

  useEffect(() => {
    let data;
    if (isSurveyQuestion) {
      data = {
        ...props.question,
        type,
        text,
        choices,
      };
    } else {
      data = {
        ...props.question,
        type,
        weight,
        strictMode,
        text,
        choices,
      };
    }
    //console.log("useEffect => " + JSON.stringify(data));
    props.onChange(data, props.count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, weight, strictMode, text, choices]);

  useEffect(() => {
    if (deleteStatus === 1) {
      setExpanded(false);
      setDeleteStatus(2);
    }
    if (deleteStatus === 2) {
      setExpanded(true);
      setDeleteStatus(0);
    }
  }, [deleteStatus]);

  const onImageChanged = (data) => {
    const questionImage = { image: data.file, id: props.question.id };
    //console.log(`question: onImageChanged => ${JSON.stringify(questionImage)}`);
    props.onImageUpdated(questionImage);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <React.Fragment>
        <Grid item xs={12}>
          <ElementButton
            id="id-btn-question"
            variant="contained"
            component="span"
            size="large"
            color="blue"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "-" + buttonText : "+" + buttonText}
          </ElementButton>
        </Grid>
      </React.Fragment>
      {expanded && (
        <React.Fragment>
          {!isSurveyQuestion && (
            <Grid item xs={12}>
              <ElementSwitch
                checked={strictMode}
                id="id-strictmode"
                label="Strict mode"
                tooltip="In strict mode all answer options must be correct to pass the question"
                onChange={(strictMode) => setStrictMode(strictMode)}
              />
            </Grid>
          )}
          <React.Fragment>
            <Grid item xs={4}>
              <label className="textlabel">Question Type:</label>
            </Grid>
            <Grid item xs={8} className="radiorow">
              <Box
                className={radioBox}
                onFocus={() => setRadioActive(true)}
                onBlur={() => setRadioActive(false)}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    className="radiocontainer"
                    row
                    defaultValue={TYPE_SINGLE}
                    value={type}
                    name="quizz-question-radios"
                    onChange={(event) => setType(event.target.value)}
                  >
                    <FormControlLabel
                      value={TYPE_SINGLE}
                      control={<ElementRadio id="id-type-multi" />}
                      label={
                        <Typography className={classes.label}>
                          Single
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value={TYPE_MULTI}
                      control={<ElementRadio id="id-type-multi" />}
                      label={
                        <Typography className={classes.label}>Multi</Typography>
                      }
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <ElementTooltip tooltip="Specifies if question can have only one correct answer of multiple correct answers"></ElementTooltip>
            </Grid>
          </React.Fragment>
          <Grid item xs={12}>
            <ElementInput
              value={text}
              id="id-question"
              label="Question:"
              required
              tooltip="[Required] Question for the quiz"
              onChange={(text) => setText(text)}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <ElementImage
              size="normal"
              path={props.question.imgUrl}
              id={"question-image-id" + (props.count + 1)}
              onDataChanged={(data) => {
                onImageChanged(data);
              }}
            />
          </Grid>
          <React.Fragment>
            {choices &&
              choices.map((choice, index) => (
                <Choice
                  questionType={props.questionType}
                  key={index}
                  count={index}
                  choice={choice}
                  onImageUpdated={(data) => {
                    if (!isEmpty(data)) props.onImageUpdated(data);
                  }}
                  onChange={(choice, count) =>
                    setChoices(
                      choices.map((obj, index) => {
                        if (index === count) {
                          return { ...choice, imgUrl: obj.imgUrl };
                        }
                        return obj;
                      })
                    )
                  }
                  onDelete={(count) => {
                    const arr = [];
                    for (let i = 0; i < choices.length; i++) {
                      if (i !== count) {
                        arr.push({ ...choices[i] });
                      }
                    }
                    setChoices([...arr]);
                    setDeleteStatus(1);
                    /*setChoices(
                      choices.filter((choice, index) => index != count)
                    );*/
                  }}
                />
              ))}
          </React.Fragment>
          <Grid item xs={12}>
            <ElementButton
              width="100px"
              height="30px"
              id="id-btn-add-choice"
              variant="contained"
              component="span"
              size="small"
              color="green"
              onClick={() =>
                setChoices([...choices, createDefaultChoice(isSurveyQuestion)])
              }
            >
              Add
            </ElementButton>
          </Grid>
          {!isSurveyQuestion && (
            <Grid item xs={12}>
              <ElementInput
                value={weight}
                id="id-weight"
                label="Weight:"
                tooltip="Weight of the question. Default is 1"
                onChange={(weight) => setWeight(weight)}
                type="number"
              />
            </Grid>
          )}
          <Grid
            container
            xs={12}
            justify="flex-end"
            style={{ paddingRight: 42, paddingBottom: 10 }}
          >
            <ElementButton
              id="id-btn-delete-question"
              variant="contained"
              component="span"
              size="large"
              color="red"
              onClick={() => {
                props.onDelete(props.count);
                setExpanded(false);
              }}
            >
              Delete Question
            </ElementButton>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

ElementQuizQuestion.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onImageUpdated: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  questionType: PropTypes.string,
};

export default ElementQuizQuestion;
