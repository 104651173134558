import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItem } from "./PickerItem";
import {
  populateElementSelection,
  createSelectableElementList,
  addToSelection,
  updateSelectionList,
} from "./pickertools";

import { ElementType } from "../../definitions/definitions";

const editMode = true;

const EventsPickerNew = (props) => {
  // {currentCount: 0, id: "testing-event", count: 2}
  const [selection, setSelection] = useState([]);
  const [selectableEvents, setSelectableEvents] = useState(props.events);

  const { events, selectedEvents, eventId, onChange } = props;
  // todo: build list of selected events containing name + imgUrl
  useEffect(() => {
    // Create list of selected items based on achievements
    setSelectableEvents(
      createSelectableElementList(events, selectedEvents, eventId)
    );
    setSelection(
      populateElementSelection(
        events,
        selectedEvents,
        ElementType.EVENT,
        editMode
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Create list of selected items based on achievements
    const list = createSelectableElementList(events, selection, eventId);
    setSelectableEvents(list);
    let elementIds = selection.map(function (element) {
      return {
        id: element.id,
        count: element.count,
      };
    });
    onChange(elementIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const updateSelection = (id, value) => {
    const newSelection = updateSelectionList(selection, id, value);
    setSelection(newSelection);
  };

  // add item to selected list
  const onSelected = (id) => {
    setSelection(addToSelection(selection, props.events, id));
  };

  const onDelete = (id) => {
    const list = selection.filter((item) => item.id !== id);
    setSelection(list);
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Event:"
        id="id-eventpicker"
        required={false}
        onSelected={(id) => onSelected(id)}
        items={selectableEvents}
        tooltip="You must specify the event[s] that must be triggered by the player’s actions for the element to be completed for the player"
        type="events"
      />
      {selection &&
        selection.map((item) => {
          return (
            <div className="rows" key={item.id}>
              <PickerItem
                title="Event Name:"
                label="Steps Required:"
                id={item.id}
                name={item.name}
                imgUrl={item.imgUrl}
                count={item.count}
                onChange={(id, value) => updateSelection(id, value)}
                onDelete={onDelete}
              />
              <div className="pickerspace"></div>
            </div>
          );
        })}
    </div>
  );
};

EventsPickerNew.propTypes = {
  events: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  eventId: PropTypes.string,
  getEventsForAccount: PropTypes.func.isRequired,
  selectedEvents: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  events: state.input.events ? state.input.events : [],
  account: state.input.account,
});

export default connect(mapStateToProps, null)(EventsPickerNew);
