//import React from "react";
import { toast } from "react-toastify";

// Import Actions
import { loading } from "./rootActions";
import { parseError } from "../utils/utils";

// Import API
import {
  getAccounts,
  queryAccounts,
  createAccount,
  updateAccount,
  uploadImageForAccount,
  copyAccountElements,
  getEventsForAccount,
  createEvent,
  updateEvent,
  deleteEvent,
  completeEvent,
  getAchievementsForAccount,
  createAchievement,
  updateAchievement,
  deleteAchievement,
  getMissionsForAccount,
  createMission,
  updateMission,
  deleteMission,
  getTeamsForAccount,
  createTeam,
  updateTeam,
  deleteTeam,
  getPlayersForAccount,
  createPlayer,
  updatePlayer,
  deletePlayer,
  getLevelsForAccount,
  createLevel,
  updateLevel,
  deleteLevel,
  getPrizesForAccount,
  createPrize,
  updatePrize,
  deletePrize,
  getLeaderboardsForAccount,
  updateLeaderboard,
  createLeaderboard,
  deleteLeaderboard,
  getAnalyticsForAccount,
  getAggregatedAnalytics,
  queryMysteryboxes,
  createMysterybox,
  updateMysterybox,
  deleteMysterybox,
  queryRaffles,
  createRaffle,
  updateRaffle,
  deleteRaffle,
  queryQuizzes,
  createQuiz,
  updateQuiz,
  deleteQuiz,
  querySurveys,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  queryRules,
  createRule,
  updateRule,
  deleteRule,
  getRulesMetadata,
  getStreaksForAccount,
  createStreak,
  updateStreak,
  deleteStreak,
} from "../api/backendAPI";

// Account actions
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";

// Event actions
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
// todo: remove some exported constants which are not needed
export const CREATE_EVENTS_SUCCESS = "CREATE_EVENTS_SUCCESS";
export const COMPLETE_EVENT_SUCCESS = "COMPLETE_EVENT_SUCCESS";

// Achievement actions
export const GET_ACHIEVEMENTS_SUCCESS = "GET_ACHIEVEMENTS_SUCCESS";
export const CREATE_ACHIEVEMENTS_SUCCESS = "CREATE_ACHIEVEMENTS_SUCCESS";

// Mission actions
export const GET_MISSIONS_SUCCESS = "GET_MISSIONS_SUCCESS";
export const CREATE_MISSION_SUCCESS = "CREATE_MISSION_SUCCESS";

// Team actions
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";

// Player actions
export const GET_PLAYERS_SUCCESS = "GET_PLAYERS_SUCCESS";
export const CREATE_PLAYER_SUCCESS = "CREATE_PLAYER_SUCCESS";

// Level actions
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS";
export const CREATE_LEVEL_SUCCESS = "CREATE_LEVEL_SUCCESS";

// Prizes actions
export const GET_PRIZES_SUCCESS = "GET_PRIZES_SUCCESS";
export const CREATE_PRIZE_SUCCESS = "CREATE_PRIZE_SUCCESS";

// Leaderboard actions
export const GET_LEADERBOARDS_SUCCESS = "GET_LEADERBOARDS_SUCCESS";
export const CREATE_LEADERBOARD_SUCCESS = "CREATE_LEADERBOARD_SUCCESS";

// Analytics actions
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";

// Mystery box actions
export const GET_MYSTERYBOXES_SUCCESS = "GET_MYSTERYBOXES_SUCCESS";

// Raffles actions
export const GET_RAFFLES_SUCCESS = "GET_RAFFLES_SUCCESS";

// Quizzes actions
export const GET_QUIZZES_SUCCESS = "GET_QUIZZES_SUCCESS";

// Surveys actions
export const GET_SURVEYS_SUCCESS = "GET_SURVEYS_SUCCESS";

// Rules actions
export const GET_RULES_SUCCESS = "GET_RULES_SUCCESS";
export const GET_RULES_METADATA_SUCCESS = "GET_RULES_METADATA_SUCCESS";

// Streak actions
export const GET_STREAKS_SUCCESS = "GET_STREAKS_SUCCESS";
export const CREATE_STREAKS_SUCCESS = "CREATE_STREAKS_SUCCESS";

// Selection actions
export const SELECT_ACCOUNT = "SELECT_ACCOUNT";

// toast ids - purpose: ensure only one toast per ID is visible at the same time
const accountsErrorId = "account-error";
const eventsErrorId = "event-error";
const achievementsErrorId = "achievement-error";
const missionsErrorId = "mission-error";
const teamsErrorId = "team-error";
const levelsErrorId = "level-error";
const playersErrorId = "players-error";
const prizesErrorId = "prize-error";
const leaderboardErrorId = "leaderboard-error";
const analyticsErrorId = "analytics-error";
const deleteErrorId = "delete-error";
const deleteSuccessId = "delete-success";
const addErrorId = "add-error";
const addSuccessId = "add-success";
const updateSuccessId = "update-success";
const updateErrorId = "update-error";
const mysteryboxErrorId = "mysterybox-error";
const rafflesErrorId = "raffles-error";
const quizzesErrorId = "quizzes-error";
const surveysErrorId = "surveys-error";
const rulesErrorId = "surveys-error";
const streaksErrorId = "streaks-error";

// Core delete function
function removeEntity(account, id, deleteFunction) {
  return (dispatch) => {
    dispatch(loading(true));
    return deleteFunction(account, id)
      .then(() => {
        dispatch(loading(false));
        toast.success(`Deleted ${id}`, {
          toastId: deleteSuccessId,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast.error(`Failed to delete ${id} - ${parseError(err)}`, {
          toastId: deleteErrorId,
          autoClose: 3000,
        });
      });
  };
}

// Core add entity
function addEntity(entity, id, addFunction) {
  return (dispatch) => {
    dispatch(loading(true));
    return addFunction(entity)
      .then(() => {
        dispatch(loading(false));
        toast.success(`${id} created`, {
          toastId: addSuccessId,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast.error(`Failed to create ${id}: ${parseError(err)}`, {
          toastId: addErrorId,
          autoClose: 3000,
        });
      });
  };
}

// Core update an entity
function updateEntity(entity, id, updateFunction) {
  return (dispatch) => {
    dispatch(loading(true));
    return updateFunction(entity)
      .then(() => {
        dispatch(loading(false));
        toast.success(`${id} updated`, {
          toastId: updateSuccessId,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast.error(`Failed to update ${id}: ${parseError(err)}`, {
          toastId: updateErrorId,
          autoClose: 3000,
        });
      });
  };
}

// --- Acount specific admin actions
function fetchAccounts() {
  return (dispatch) => {
    dispatch(loading(true));
    return getAccounts()
      .then((accounts) => {
        dispatch(loading(false));
        dispatch(fetchAccountsSuccess(accounts));
      })
      .catch((err) => {
        toast.error(`Failed to load accounts. ${parseError(err)}`, {
          toastId: accountsErrorId,
        });
      });
  };
}

function fetchAccountsByList(accountslist) {
  return (dispatch) => {
    dispatch(loading(true));
    return queryAccounts(accountslist)
      .then((accounts) => {
        dispatch(loading(false));
        dispatch(fetchAccountsSuccess(accounts));
      })
      .catch((err) => {
        toast.error(`Failed to load accounts. ${parseError(err)}`, {
          toastId: accountsErrorId,
        });
      });
  };
}

function fetchAccountsSuccess(accounts) {
  return {
    type: GET_ACCOUNTS_SUCCESS,
    accounts,
  };
}

function addAccount(account) {
  return addEntity(account, account.account, createAccount);
}

function modifyAccount(account) {
  return updateEntity(account, account.account, updateAccount);
}

function copyElements(fromAccount, toAccount) {
  return (dispatch) => {
    dispatch(loading(true));
    return copyAccountElements(fromAccount, toAccount)
      .then(() => {
        dispatch(loading(false));
        toast.success(`Copied from ${fromAccount} to ${toAccount}`, {
          toastId: addSuccessId,
          autoClose: 5000,
        });
      })
      .catch((err) => {
        toast.error(`Failed to copy account elements: ${parseError(err)}`, {
          toastId: addErrorId,
          autoClose: 5000,
        });
      });
  };
}

function addImageToAccount(account, image) {
  return (dispatch) => {
    dispatch(loading(true));
    return uploadImageForAccount(account, image)
      .then((imagePath) => {
        dispatch(loading(false));
        dispatch(addImageToAccountSuccess(imagePath));
      })
      .catch((err) => {
        toast.error(`Failed to upload image: ${parseError(err)}`, {
          toastId: accountsErrorId,
          autoClose: 5000,
        });
      });
  };
}

function addImageToAccountSuccess(imagePath) {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    imagePath,
  };
}

// --- Events specific admin actions
function fetchEventsForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return getEventsForAccount(account)
      .then((events) => {
        dispatch(loading(false));
        dispatch(fetchEventsForAccountSuccess(events));
      })
      .catch((err) => {
        toast.error(`Failed to load events. ${parseError(err)}`, {
          toastId: eventsErrorId,
        });
      });
  };
}

function fetchEventsForAccountSuccess(events) {
  return {
    type: GET_EVENTS_SUCCESS,
    events,
  };
}

function addEvent(event) {
  return addEntity(event, event.id, createEvent);
}

function modifyEvent(event) {
  return updateEntity(event, event.id, updateEvent);
}

// deleteEvent
function removeEvent(account, id) {
  return removeEntity(account, id, deleteEvent);
}

function postEvent(eventId, body) {
  return (dispatch) => {
    dispatch(loading(true));
    return completeEvent(eventId, body)
      .then((eventData) => {
        dispatch(loading(false));
        dispatch(success(eventData));
      })
      .catch((err) => {
        //console.log(JSON.stringify(err));
        toast.error(
          `Failed to complete event ${eventId}: ${JSON.stringify(err)}`,
          {
            toastId: eventsErrorId,
          }
        );
      });
  };
  function success(eventData) {
    return {
      type: COMPLETE_EVENT_SUCCESS,
      eventData,
    };
  }
}

// --- Achievement specific admin actions
function fetchAchievementsForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return getAchievementsForAccount(account)
      .then((achievements) => {
        dispatch(loading(false));
        dispatch(fetchAchievementsForAccountSuccess(achievements));
      })
      .catch((err) => {
        toast.error(`Failed to load achievements. ${parseError(err)}`, {
          toastId: achievementsErrorId,
        });
      });
  };
}

function fetchAchievementsForAccountSuccess(achievements) {
  return {
    type: GET_ACHIEVEMENTS_SUCCESS,
    achievements,
  };
}

function addAchievement(achievement) {
  return addEntity(achievement, achievement.id, createAchievement);
}

function modifyAchievement(achievement) {
  return updateEntity(achievement, achievement.id, updateAchievement);
}

function removeAchievement(account, id) {
  return removeEntity(account, id, deleteAchievement);
}

// --- Missions specific admin actions

function fetchMissionsForAccount(query) {
  return (dispatch) => {
    dispatch(loading(true));
    return getMissionsForAccount(query)
      .then((missions) => {
        dispatch(loading(false));
        dispatch(success(missions));
      })
      .catch((err) => {
        toast.error(`Failed to load missions. ${parseError(err)}`, {
          toastId: missionsErrorId,
        });
      });
  };
  function success(missions) {
    return {
      type: GET_MISSIONS_SUCCESS,
      missions,
    };
  }
}

function addMission(mission) {
  return addEntity(mission, mission.id, createMission);
}

function modifyMission(mission) {
  return updateEntity(mission, mission.id, updateMission);
}

function removeMission(account, id) {
  return removeEntity(account, id, deleteMission);
}

// --- Teams specific admin actions

function fetchTeamsForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return getTeamsForAccount(account)
      .then((teams) => {
        dispatch(loading(false));
        dispatch(fetchTeamsForAccountSuccess(teams));
      })
      .catch((err) => {
        toast.error(`Failed to load teams. ${parseError(err)}`, {
          toastId: teamsErrorId,
        });
      });
  };
}

function fetchTeamsForAccountSuccess(teams) {
  return {
    type: GET_TEAMS_SUCCESS,
    teams,
  };
}

function addTeam(team) {
  return addEntity(team, team.id, createTeam);
}

function modifyTeam(team) {
  return updateEntity(team, team.id, updateTeam);
}

function removeTeam(account, id) {
  return removeEntity(account, id, deleteTeam);
}

// --- Mystery box specific admin actions

function fetchMysteryboxes(query) {
  return (dispatch) => {
    dispatch(loading(true));
    return queryMysteryboxes(query)
      .then((mysteryboxes) => {
        dispatch(loading(false));
        dispatch(success(mysteryboxes));
      })
      .catch((err) => {
        toast.error(`Failed to load mystery boxes. ${parseError(err)}`, {
          toastId: mysteryboxErrorId,
        });
      });
  };
  function success(mysteryboxes) {
    return {
      type: GET_MYSTERYBOXES_SUCCESS,
      mysteryboxes,
    };
  }
}

function addMysterybox(mysterybox) {
  return addEntity(mysterybox, mysterybox.id, createMysterybox);
}

function modifyMysterybox(mysterybox) {
  return updateEntity(mysterybox, mysterybox.id, updateMysterybox);
}

function removeMysterybox(account, id) {
  return removeEntity(account, id, deleteMysterybox);
}

// --- Quiz specific admin actions

function fetchQuizzesForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return queryQuizzes(account)
      .then((quizzes) => {
        dispatch(loading(false));
        dispatch(success(quizzes));
      })
      .catch((err) => {
        toast.error(`Failed to load quizzes. ${parseError(err)}`, {
          toastId: quizzesErrorId,
        });
      });
  };
  function success(quizzes) {
    return {
      type: GET_QUIZZES_SUCCESS,
      quizzes,
    };
  }
}

function addQuiz(quiz) {
  return addEntity(quiz, quiz.id, createQuiz);
}

function modifyQuiz(quiz) {
  return updateEntity(quiz, quiz.id, updateQuiz);
}

function removeQuiz(account, id) {
  return removeEntity(account, id, deleteQuiz);
}

// --- Raffles specific admin actions

function fetchRaffles(query) {
  return (dispatch) => {
    dispatch(loading(true));
    return queryRaffles(query)
      .then((raffles) => {
        dispatch(loading(false));
        dispatch(success(raffles));
      })
      .catch((err) => {
        toast.error(`Failed to load raffles. ${parseError(err)}`, {
          toastId: rafflesErrorId,
        });
      });
  };
  function success(raffles) {
    return {
      type: GET_RAFFLES_SUCCESS,
      raffles,
    };
  }
}

function addRaffle(raffle) {
  return addEntity(raffle, raffle.id, createRaffle);
}

function modifyRaffle(raffle) {
  return updateEntity(raffle, raffle.id, updateRaffle);
}

function removeRaffle(account, id) {
  return removeEntity(account, id, deleteRaffle);
}

// --- Player specific admin actions

function fetchPlayersForAccount(query) {
  return (dispatch) => {
    dispatch(loading(true));
    return getPlayersForAccount(query)
      .then((players) => {
        dispatch(loading(false));
        dispatch(fetchPlayersForAccountSuccess(players));
      })
      .catch((err) => {
        toast.error(`Failed to load players. ${parseError(err)}`, {
          toastId: playersErrorId,
        });
      });
  };
}

function fetchPlayersForAccountSuccess(players) {
  return {
    type: GET_PLAYERS_SUCCESS,
    players,
  };
}

function addPlayer(player) {
  return addEntity(player, player.player, createPlayer);
}

function modifyPlayer(player) {
  return updateEntity(player, player.player, updatePlayer);
}

function removePlayer(account, id) {
  return removeEntity(account, id, deletePlayer);
}

// --- Level specific admin actions

function fetchLevelsForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return getLevelsForAccount(account)
      .then((levels) => {
        dispatch(loading(false));
        dispatch(fetchLevelsForAccountSuccess(levels));
      })
      .catch((err) => {
        toast.error(`Failed to load levels. ${parseError(err)}`, {
          toastId: levelsErrorId,
        });
      });
  };
}

function fetchLevelsForAccountSuccess(levels) {
  return {
    type: GET_LEVELS_SUCCESS,
    levels,
  };
}

function addLevel(level) {
  return addEntity(level, level.id, createLevel);
}

function modifyLevel(level) {
  return updateEntity(level, level.id, updateLevel);
}

function removeLevel(account, id) {
  return removeEntity(account, id, deleteLevel);
}

// --- Prize specific admin actions

function fetchPrizesForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return getPrizesForAccount(account)
      .then((prizes) => {
        dispatch(loading(false));
        dispatch(success(prizes));
      })
      .catch((err) => {
        toast.error(`Failed to load prizes. ${parseError(err)}`, {
          toastId: prizesErrorId,
        });
      });
  };
  function success(prizes) {
    return {
      type: GET_PRIZES_SUCCESS,
      prizes,
    };
  }
}

function addPrize(prize) {
  return addEntity(prize, prize.id, createPrize);
}

function modifyPrize(prize) {
  return updateEntity(prize, prize.id, updatePrize);
}

function removePrize(account, id) {
  return removeEntity(account, id, deletePrize);
}

// --- Leaderboard specific admin actions

function fetchLeaderboardsForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return getLeaderboardsForAccount(account)
      .then((leaderboards) => {
        dispatch(loading(false));
        dispatch(success(leaderboards));
      })
      .catch((err) => {
        toast.error(`Failed to load leaderboards. ${parseError(err)}`, {
          toastId: leaderboardErrorId,
        });
      });
  };
  function success(leaderboards) {
    return {
      type: GET_LEADERBOARDS_SUCCESS,
      leaderboards,
    };
  }
}

function addLeaderboard(leaderboard) {
  return addEntity(leaderboard, leaderboard.id, createLeaderboard);
}

function modifyLeaderboard(leaderboard) {
  return updateEntity(leaderboard, leaderboard.id, updateLeaderboard);
}

function removeLeaderboard(account, id) {
  return removeEntity(account, id, deleteLeaderboard);
}

// --- Survey specific admin actions

function fetchSurveysForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return querySurveys(account)
      .then((surveys) => {
        dispatch(loading(false));
        dispatch(success(surveys));
      })
      .catch((err) => {
        toast.error(`Failed to load surveys. ${parseError(err)}`, {
          toastId: surveysErrorId,
        });
      });
  };
  function success(surveys) {
    return {
      type: GET_SURVEYS_SUCCESS,
      surveys,
    };
  }
}

function addSurvey(survey) {
  return addEntity(survey, survey.id, createSurvey);
}

function modifySurvey(survey) {
  return updateEntity(survey, survey.id, updateSurvey);
}

function removeSurvey(account, id) {
  return removeEntity(account, id, deleteSurvey);
}

// --- Rules specific admin actions

function fetchRulesForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return queryRules(account)
      .then((rules) => {
        dispatch(loading(false));
        dispatch(success(rules));
      })
      .catch((err) => {
        toast.error(`Failed to load rules. ${parseError(err)}`, {
          toastId: rulesErrorId,
        });
      });
  };
  function success(rules) {
    return {
      type: GET_RULES_SUCCESS,
      rules,
    };
  }
}

function addRule(rule) {
  return addEntity(rule, rule.id, createRule);
}

function modifyRule(rule) {
  return updateEntity(rule, rule.id, updateRule);
}

function removeRule(account, id) {
  return removeEntity(account, id, deleteRule);
}

function fetchRulesMetadataForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return getRulesMetadata(account)
      .then((rulesmetadata) => {
        dispatch(loading(false));
        dispatch(success(rulesmetadata));
      })
      .catch((err) => {
        toast.error(`Failed to load rules metadata. ${parseError(err)}`, {
          toastId: rulesErrorId,
        });
      });
  };
  function success(rulesmetadata) {
    return {
      type: GET_RULES_METADATA_SUCCESS,
      rulesmetadata,
    };
  }
}

// --- Analytics specific admin actions

function fetchAnalyticsForAccount(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return getAnalyticsForAccount(account)
      .then((analytics) => {
        dispatch(loading(false));
        dispatch(fetchAnalyticsForAccountSuccess(analytics));
      })
      .catch((err) => {
        toast.error(`Failed to load analytics. ${parseError(err)}`, {
          toastId: analyticsErrorId,
        });
      });
  };
}

function fetchAggregatedAnalytics(account) {
  return (dispatch) => {
    dispatch(loading(true));
    return getAggregatedAnalytics(account)
      .then((analytics) => {
        dispatch(loading(false));
        dispatch(fetchAnalyticsForAccountSuccess(analytics));
      })
      .catch((err) => {
        toast.error(`Failed to load analytics. ${parseError(err)}`, {
          toastId: analyticsErrorId,
        });
      });
  };
}

function fetchAnalyticsForAccountSuccess(analytics) {
  return {
    type: GET_ANALYTICS_SUCCESS,
    analytics,
  };
}

// --- Streaks specific admin actions

function fetchStreaksForAccount(query) {
  return (dispatch) => {
    dispatch(loading(true));
    return getStreaksForAccount(query)
      .then((streaks) => {
        dispatch(loading(false));
        dispatch(success(streaks));
      })
      .catch((err) => {
        toast.error(`Failed to load streaks. ${parseError(err)}`, {
          toastId: streaksErrorId,
        });
      });
  };
  function success(streaks) {
    return {
      type: GET_STREAKS_SUCCESS,
      streaks,
    };
  }
}

function addStreak(streak) {
  return addEntity(streak, streak.id, createStreak);
}

function modifyStreak(streak) {
  return updateEntity(streak, streak.id, updateStreak);
}

function removeStreak(account, id) {
  return removeEntity(account, id, deleteStreak);
}

// --- Selection specific actions for UI ---

function selectAccount(account) {
  return { type: SELECT_ACCOUNT, account };
}

export {
  fetchAccounts,
  fetchAccountsByList,
  addAccount,
  modifyAccount,
  copyElements,
  addImageToAccount,
  selectAccount,
  fetchEventsForAccount,
  addEvent,
  modifyEvent,
  removeEvent,
  postEvent,
  fetchAchievementsForAccount,
  addAchievement,
  modifyAchievement,
  removeAchievement,
  fetchMissionsForAccount,
  addMission,
  modifyMission,
  removeMission,
  fetchTeamsForAccount,
  addTeam,
  modifyTeam,
  removeTeam,
  fetchPlayersForAccount,
  addPlayer,
  modifyPlayer,
  removePlayer,
  fetchLevelsForAccount,
  addLevel,
  modifyLevel,
  removeLevel,
  fetchPrizesForAccount,
  addPrize,
  modifyPrize,
  removePrize,
  fetchLeaderboardsForAccount,
  addLeaderboard,
  modifyLeaderboard,
  removeLeaderboard,
  fetchAnalyticsForAccount,
  fetchAggregatedAnalytics,
  fetchMysteryboxes,
  addMysterybox,
  modifyMysterybox,
  removeMysterybox,
  fetchRaffles,
  addRaffle,
  modifyRaffle,
  removeRaffle,
  fetchQuizzesForAccount,
  addQuiz,
  modifyQuiz,
  removeQuiz,
  fetchSurveysForAccount,
  addSurvey,
  modifySurvey,
  removeSurvey,
  fetchRulesForAccount,
  addRule,
  modifyRule,
  removeRule,
  fetchRulesMetadataForAccount,
  fetchStreaksForAccount,
  addStreak,
  modifyStreak,
  removeStreak,
};
