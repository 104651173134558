import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

const ElementQuizTimer = ({ timing, onChange }) => {
  const [value, setValue] = useState(
    timing && timing.maxSecondsToComplete ? timing.maxSecondsToComplete : 120
  );

  useEffect(() => {
    onChange({
      maxSecondsToComplete: value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-timetocomplete"
        label="Time to Complete:"
        tooltip="Time to complete quiz in seconds."
        onChange={(value) => setValue(value)}
        type="number"
      />
    </React.Fragment>
  );
};

ElementQuizTimer.propTypes = {
  timing: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ElementQuizTimer;
