/* eslint-disable-next-line no-unused-vars */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";

// Development time
import PropTypes from "prop-types";

// Own components
import ElementButton from "../inputs/ElementButton";
import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItemExt } from "./PickerItem";
import ParamPicker from "./ParamPicker";

// Styling
import "../inputs/element-input.css";

const ActionPicker = (props) => {
  const [action, setAction] = useState(null);
  const actions = props.rulesmetadata ? props.rulesmetadata.actions : [];
  const [actionParams, setActionParams] = useState(
    props.rule.actionParams ? props.rule.actionParams : null
  );

  useEffect(() => {
    if (props.rule) setAction(populateAction(props.rule.actionId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (action && actionParams) {
      props.onChange(action.id, actionParams);
    }
  }, [action, actionParams]);

  const getActionById = (id) => {
    return actions ? actions.find((action) => action.id === id) : null;
  };

  const updateParamsToDefaults = (action) => {
    if (!action) return;
    const params = {};
    for (const param of action.params) {
      params[param.name] = param.type === "Number" ? 0 : null;
    }
    setActionParams(params);
  };

  // add item to selected list
  const onSelected = (id) => {
    let action = getActionById(id);
    if (action) {
      // if conditionId is updated, update conditionParams with default values
      if (action.id !== props.rule.actionId) {
        updateParamsToDefaults(action);
      }
      setAction(action);
    }
  };

  const onDelete = () => {
    props.onChange(null);
    setAction(null);
  };

  const populateAction = (id) => {
    const action = actions.find((act) => act.id === id);
    if (action) {
      return {
        id: action.id,
        name: action.name,
        description: action.name,
        //imgUrl: defaultImage(),
        imgUrl: process.env.PUBLIC_URL + "/assets/images/action_selected.png",
      };
    }
    return null;
  };

  const getMetaParams = (id) => {
    const metaAction = actions.find((item) => item.id === id);
    return metaAction ? metaAction.params : [];
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Action for Rule:"
        id="id-actionpicker"
        required={true}
        onSelected={(id) => onSelected(id)}
        items={actions}
        tooltip="You must select the action for the rule and parametrize the action"
        type="action"
      />
      {action && (
        <div className="rows">
          <PickerItemExt
            key={action.id}
            title={`Action ID: ${action.id}`}
            id={action.id}
            name={action.name}
            imgUrl={
              process.env.PUBLIC_URL + "/assets/images/action_selected.png"
            }
            onDelete={onDelete}
          />
          <div className="pickerspace" />
        </div>
      )}
      {action && (
        <div style={{ marginTop: "10px" }}>
          <ParamPicker
            params={actionParams}
            metaParams={getMetaParams(action.id)}
            onChange={(data) => setActionParams(data)}
          />
        </div>
      )}
    </div>
  );
};

ActionPicker.propTypes = {
  rule: PropTypes.object.isRequired,
  rulesmetadata: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const RuleActionPicker = (props) => {
  const [hidePicker, setHidePicker] = useState(false);
  const rule = props.rule;

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-rule-action-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Action" : "- Action"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <React.Fragment>
          <Grid item xs={12}>
            <ActionPicker
              rule={rule}
              rulesmetadata={props.rulesmetadata}
              onChange={(actionId, actionParams) =>
                props.onChange(actionId, actionParams)
              }
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

RuleActionPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  rule: PropTypes.object.isRequired,
  rulesmetadata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  rulesmetadata: state.input.rulesmetadata,
});

export default connect(mapStateToProps, null)(RuleActionPicker);
