import React, { useState } from "react";

import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

const ElementCredits = ({ credits, onChange, tooltip }) => {
  const [value, setValue] = useState(credits);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-credits"
        label="Credits:"
        tooltip={tooltip}
        onChange={(value) => {
          setValue(value);
          onChange(value);
        }}
        type="number"
      />
    </React.Fragment>
  );
};

ElementCredits.propTypes = {
  credits: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

export default ElementCredits;
