import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Material-UI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// Own components
import ElementButton from "../components/inputs/ElementButton";
import { ElementType } from "../definitions/definitions";
import {
  isAdmin,
  isAccountAdmin,
  getUserAccounts,
  hasAdminRights,
} from "../auth/roles";

// Styling
import "./containers.css";

import { fetchAccounts, fetchAccountsByList } from "../actions/adminActions";

import ElementsTable from "../components/ElementsTable";
import ElementMission from "./ElementMission";
import ElementEvent from "./ElementEvent";
import ElementTeam from "./ElementTeam";
import ElementAccount from "./ElementAccount";
import ElementAchievement from "./ElementAchievement";
import ElementLevel from "./ElementLevel";
import ElementLeaderboard from "./ElementLeaderboard";
import ElementPrize from "./ElementPrize";
import ElementRaffle from "./ElementRaffle";
import ElementMysterybox from "./ElementMysterybox";
import ElementPlayer from "./ElementPlayer";
import ElementQuiz from "./ElementQuiz";
import ElementSurvey from "./ElementSurvey";
import ElementRule from "./ElementRule";
import ElementStreak from "./ElementStreak";
import { buildQuery } from "../utils/utils";

import {
  defaultMission,
  defaultEvent,
  defaultAccount,
  defaultTeam,
  defaultAchievement,
  defaultLevel,
  defaultLeaderboard,
  defaultPrize,
  defaultRaffle,
  defaultMysterybox,
  defaultPlayer,
  defaultQuiz,
  defaultSurvey,
  defaultRule,
  defaultStreak,
} from "./constants";

const TABLE_CELL_SIZE = "12px";
const TABLE_CELL_FONT = "Montserrat";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: "white",
    paddingTop: 30,
    paddingBottom: 10,
    paddingLeft: 62,
    paddingRight: 0,
  },
}));

const defaultQuery = {
  limit: 500,
  skip: 0,
  availability: "all",
  timeframe: "all",
  player: "",
};

const defaultPlayersQuery = {
  limit: 10000,
  skip: 0,
  availability: "all",
  timeframe: "all",
  player: "",
};

const columns = [
  {
    title: "IMAGE",
    field: "imgUrl",
    render: (rowData) => (
      <div style={{ paddingLeft: "12px" }}>
        <img
          src={rowData.imgUrl}
          alt=""
          style={{ width: 32, borderRadius: "50%" }}
        />
      </div>
    ),
    width: "10%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "ID",
    field: "id",
    searchable: true,
    width: "20%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "NAME",
    field: "name",
    searchable: true,
    width: "20%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "DESCRIPTION",
    field: "description",
    searchable: true,
    width: "50%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      oveflow: "hidden",
      maxWidth: 100,
    },
  },
];

const accountColumns = [
  {
    title: "IMAGE",
    field: "imgUrl",
    render: (rowData) => (
      <div style={{ paddingLeft: "12px" }}>
        <img
          src={rowData.imgUrl}
          alt=""
          style={{ width: 32, borderRadius: "50%" }}
        />
      </div>
    ),
    width: "10%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "ID",
    field: "account",
    searchable: true,
    width: "20%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "NAME",
    field: "name",
    searchable: true,
    width: "20%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "DESCRIPTION",
    field: "description",
    searchable: true,
    width: "50%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      oveflow: "hidden",
      maxWidth: 100,
    },
  },
];

const playersColumns = [
  {
    title: "IMAGE",
    field: "imgUrl",
    render: (rowData) => (
      <div style={{ paddingLeft: "12px" }}>
        <img
          src={rowData.imgUrl}
          alt=""
          style={{ width: 32, borderRadius: "50%" }}
        />
      </div>
    ),
    width: "10%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "ID",
    field: "player",
    searchable: true,
    width: "20%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "NAME",
    field: "name",
    searchable: true,
    width: "20%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "TEAM",
    field: "team",
    searchable: true,
    width: "50%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      oveflow: "hidden",
      maxWidth: 100,
    },
  },
];

const ElementsPage = ({
  title,
  accounts,
  missions,
  events,
  teams,
  achievements,
  levels,
  leaderboards,
  prizes,
  raffles,
  mysteryboxes,
  players,
  quizzes,
  surveys,
  rules,
  streaks,
  account,
  getElements,
  elementType,
  getAccounts,
  getAccountsByList,
}) => {
  const classes = useStyles();

  const [createMode, setCreateMode] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItemsForElement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createMode,
    missions,
    events,
    accounts,
    teams,
    achievements,
    levels,
    leaderboards,
    prizes,
    raffles,
    mysteryboxes,
    quizzes,
    surveys,
    rules,
    streaks,
    players,
  ]);

  const createEvent = () => {
    setCreateMode(true);
  };

  const imageElement = () => {
    switch (elementType) {
      case ElementType.ACCOUNT:
        return process.env.PUBLIC_URL + "/assets/icons/accounts.png";
      case ElementType.MISSION:
        return process.env.PUBLIC_URL + "/assets/icons/missions.png";
      case ElementType.EVENT:
        return process.env.PUBLIC_URL + "/assets/icons/events.png";
      case ElementType.TEAM:
        return process.env.PUBLIC_URL + "/assets/icons/teams.png";
      case ElementType.ACHIEVEMENT:
        return process.env.PUBLIC_URL + "/assets/icons/achievements.png";
      case ElementType.LEVEL:
        return process.env.PUBLIC_URL + "/assets/icons/levels.png";
      case ElementType.LEADERBOARD:
        return process.env.PUBLIC_URL + "/assets/icons/leaderboards.png";
      case ElementType.PRIZE:
        return process.env.PUBLIC_URL + "/assets/icons/prizes.png";
      case ElementType.RAFFLE:
        return process.env.PUBLIC_URL + "/assets/icons/raffles.png";
      case ElementType.MYSTERYBOX:
        return process.env.PUBLIC_URL + "/assets/icons/mystery.png";
      case ElementType.QUIZ:
        return process.env.PUBLIC_URL + "/assets/icons/quiz.png";
      case ElementType.SURVEY:
        return process.env.PUBLIC_URL + "/assets/icons/survey.png";
      case ElementType.RULE:
        return process.env.PUBLIC_URL + "/assets/icons/rules.png";
      case ElementType.STREAK:
        return process.env.PUBLIC_URL + "/assets/icons/streaks.png";
      case ElementType.PLAYER:
        return process.env.PUBLIC_URL + "/assets/icons/players.png";
      default:
        return process.env.PUBLIC_URL + "/assets/icons/accounts.png";
    }
  };

  const elementSubtitle = () => {
    switch (elementType) {
      case ElementType.ACCOUNT:
        return "Where the magic starts";
      case ElementType.MISSION:
        return "The quest for glory";
      case ElementType.STREAK:
        return "Keep your players in the loop";
      case ElementType.EVENT:
        return "What your players are doing";
      case ElementType.TEAM:
        return "Together everyone achieves more";
      case ElementType.ACHIEVEMENT:
        return "Rewards for your players";
      case ElementType.LEVEL:
        return "Take your players on a journey";
      case ElementType.LEADERBOARD:
        return "Behold the leader of the pack";
      case ElementType.PRIZE:
        return "Pamper your players";
      case ElementType.RAFFLE:
        return "And we have a winner!";
      case ElementType.MYSTERYBOX:
        //return process.env.PUBLIC_URL + "/assets/icons/raffles.png";
        return "The element of surprise";
      case ElementType.PLAYER:
        return "It’s all about them";
      case ElementType.QUIZ:
        return "Test the knowledge of your players";
      case ElementType.SURVEY:
        return "Get timely and relevant feedback from your audience";
      case ElementType.RULE:
        return "Build rules to target specific player outcomes";
      default:
        return "The unexpected happened";
    }
  };

  const accountElements = () => {
    if (isAdmin()) return accounts;
    const userAccounts = getUserAccounts();
    const accs = accounts.filter((account) =>
      userAccounts.includes(account.account)
    );
    return accs;
  };

  const setItemsForElement = () => {
    switch (elementType) {
      case ElementType.ACCOUNT:
        if (createMode) {
          setItems(() => [...accountElements(), defaultAccount]);
        } else {
          setItems(accountElements());
        }
        break;
      case ElementType.MISSION:
        if (createMode) {
          setItems(() => [...missions, defaultMission]);
        } else {
          setItems(missions);
        }
        break;
      case ElementType.EVENT:
        if (createMode) {
          setItems(() => [...events, defaultEvent]);
        } else {
          setItems(events);
        }
        break;
      case ElementType.TEAM:
        if (createMode) {
          setItems(() => [...teams, defaultTeam]);
        } else {
          setItems(teams);
        }
        break;
      case ElementType.ACHIEVEMENT:
        if (createMode) {
          setItems(() => [...achievements, defaultAchievement]);
        } else {
          setItems(achievements);
        }
        break;
      case ElementType.LEVEL:
        if (createMode) {
          setItems(() => [...levels, defaultLevel]);
        } else {
          setItems(levels);
        }
        break;
      case ElementType.LEADERBOARD:
        if (createMode) {
          setItems(() => [...leaderboards, defaultLeaderboard]);
        } else {
          setItems(leaderboards);
        }
        break;
      case ElementType.PRIZE:
        if (createMode) {
          setItems(() => [...prizes, defaultPrize]);
        } else {
          setItems(prizes);
        }
        break;
      case ElementType.RAFFLE:
        if (createMode) {
          setItems(() => [...raffles, defaultRaffle]);
        } else {
          setItems(raffles);
        }
        break;
      case ElementType.QUIZ:
        if (createMode) {
          setItems(() => [...quizzes, defaultQuiz]);
        } else {
          setItems(quizzes);
        }
        break;
      case ElementType.SURVEY:
        if (createMode) {
          setItems(() => [...surveys, defaultSurvey]);
        } else {
          setItems(surveys);
        }
        break;
      case ElementType.MYSTERYBOX:
        if (createMode) {
          setItems(() => [...mysteryboxes, defaultMysterybox]);
        } else {
          setItems(mysteryboxes);
        }
        break;
      case ElementType.RULE:
        if (createMode) {
          setItems(() => [...rules, defaultRule]);
        } else {
          setItems(rules);
        }
        break;
      case ElementType.STREAK:
        if (createMode) {
          setItems(() => [...streaks, defaultStreak]);
        } else {
          setItems(streaks);
        }
        break;
      case ElementType.PLAYER:
        if (createMode) {
          setItems(() => [...players, defaultPlayer]);
        } else {
          setItems(players);
        }
        break;
      default:
        break;
    }
  };

  const DetailPanel = (rowData) => {
    switch (elementType) {
      case ElementType.ACCOUNT:
        return (
          <ElementAccount
            data={rowData}
            updateMode={rowData.account !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              if (isAdmin()) {
                getAccounts();
              } else if (isAccountAdmin()) {
                getAccountsByList(getUserAccounts());
              }
              setCreateMode(false);
            }}
          />
        );
      case ElementType.MISSION:
        return (
          <ElementMission
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.STREAK:
        return (
          <ElementStreak
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.EVENT:
        return (
          <ElementEvent
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.TEAM:
        return (
          <ElementTeam
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.ACHIEVEMENT:
        return (
          <ElementAchievement
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.LEVEL:
        return (
          <ElementLevel
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.LEADERBOARD:
        return (
          <ElementLeaderboard
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.PRIZE:
        return (
          <ElementPrize
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.RAFFLE:
        return (
          <ElementRaffle
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.QUIZ:
        return (
          <ElementQuiz
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.SURVEY:
        return (
          <ElementSurvey
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.MYSTERYBOX:
        return (
          <ElementMysterybox
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.RULE:
        return (
          <ElementRule
            data={rowData}
            updateMode={rowData.id !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      case ElementType.PLAYER:
        return (
          <ElementPlayer
            data={rowData}
            updateMode={rowData.player !== ""}
            onCancel={() => setCreateMode(false)}
            onUpdated={() => {
              const query = buildQuery(defaultPlayersQuery, account.account);
              getElements(query);
              setCreateMode(false);
            }}
          />
        );
      default:
        break;
    }
  };
  const tableCols = () => {
    switch (elementType) {
      case ElementType.ACCOUNT:
        return accountColumns;
      case ElementType.PLAYER:
        return playersColumns;
      default:
        return columns;
    }
  };
  const showCreate = () => {
    if (elementType !== ElementType.ACCOUNT) return hasAdminRights();
    return isAdmin();
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="center"
        style={{ width: "100%" }}
      >
        <Grid container xs={10} row alignItems="center">
          <img src={imageElement()} height={64} alt="" />
          <div>
            <div className="tabletitle">{title}</div>
            <div className="tablesubtitle">{elementSubtitle()}</div>
          </div>
        </Grid>
        <Grid
          container
          xs={2}
          row
          justify="flex-end"
          style={{ paddingRight: 10 }}
        >
          {showCreate() && (
            <ElementButton
              id="id-timer-btn"
              variant="contained"
              component="span"
              size="large"
              color="green"
              onClick={createEvent}
            >
              Create
            </ElementButton>
          )}
        </Grid>
        <Grid item xs={12}>
          <ElementsTable
            data={items}
            columns={tableCols()}
            detailPanel={DetailPanel}
          />
        </Grid>
      </Grid>
    </div>
  );
};

ElementsPage.propTypes = {
  title: PropTypes.string.isRequired,
  account: PropTypes.object,
  accounts: PropTypes.array,
  missions: PropTypes.array,
  events: PropTypes.array,
  achievements: PropTypes.array,
  levels: PropTypes.array,
  teams: PropTypes.array,
  leaderboards: PropTypes.array,
  prizes: PropTypes.array,
  mysteryboxes: PropTypes.array,
  raffles: PropTypes.array,
  quizzes: PropTypes.array,
  players: PropTypes.array,
  surveys: PropTypes.array,
  rules: PropTypes.array,
  streaks: PropTypes.array,
  getElements: PropTypes.func.isRequired,
  elementType: PropTypes.number.isRequired,
  getAccounts: PropTypes.func,
  getAccountsByList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  accounts: state.input.accounts,
  account: state.input.account,
  missions: state.input.missions,
  events: state.input.events,
  teams: state.input.teams,
  levels: state.input.levels,
  achievements: state.input.achievements,
  leaderboards: state.input.leaderboards,
  prizes: state.input.prizes,
  raffles: state.input.raffles,
  mysteryboxes: state.input.mysteryboxes,
  players: state.input.players,
  quizzes: state.input.quizzes,
  surveys: state.input.surveys,
  rules: state.input.rules,
  streaks: state.input.streaks,
});

const mapDispatchToProps = (dispatch) => ({
  getAccounts: () => dispatch(fetchAccounts()),
  getAccountsByList: (accountslist) =>
    dispatch(fetchAccountsByList(accountslist)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ElementsPage);
