import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";
import { fetchAchievementsForAccount } from "../actions/adminActions";

const Achievements = (props) => {
  return (
    <ElementsPage
      title="Achievements"
      getElements={props.getAchievementsForAccount}
      elementType={ElementType.ACHIEVEMENT}
    />
  );
};

Achievements.propTypes = {
  getAchievementsForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});

const mapDispatchToProps = (dispatch) => ({
  getAchievementsForAccount: (query) =>
    dispatch(fetchAchievementsForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Achievements);
