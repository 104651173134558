import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

// Development time
import PropTypes from "prop-types";

// Own components
import PrizesPickerNew from "./PrizesPickerNew";
import ElementButton from "../inputs/ElementButton";

// Styling
import "../inputs/element-input.css";

const StockPickerNew = (props) => {
  const [hidePicker, setHidePicker] = useState(false);
  const [prizes, setPrizes] = useState(props.prizes);

  useEffect(() => {
    props.onChange(prizes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prizes]);

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-stock-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Stock" : "- Stock"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <React.Fragment>
          <Grid item xs={12}>
            <PrizesPickerNew
              tooltip="You must select the prizes – and their quantities – to add to the element"
              onChange={(data) => setPrizes(data)}
              selectedPrizes={prizes}
              editMode={true}
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

StockPickerNew.propTypes = {
  onChange: PropTypes.func.isRequired,
  prizes: PropTypes.array.isRequired,
};

export default StockPickerNew;
