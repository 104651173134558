import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import ElementSwitch from "./ElementSwitch";
import ElementInput from "./ElementInput";

import "./element-input.css";

const ElementLimitCount = ({ limitCount, countLimit, onChange }) => {
  // Unlimited count is the negation of limit count
  const [_unlimitedCount, setUnlimitedCount] = useState(
    limitCount == null ? false : !limitCount
  );
  const [_countLimit, setCountLimit] = useState(
    countLimit == null ? 1 : countLimit
  );

  useEffect(() => {
    onChange(!_unlimitedCount, _countLimit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_unlimitedCount, _countLimit]);

  return (
    <div className="countlimit">
      <Grid item xs={4}>
        <ElementSwitch
          checked={_unlimitedCount}
          id="id-unlimitedcount"
          label="Unlimited"
          tooltip=""
          onChange={(value) => {
            setUnlimitedCount(value);
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <ElementInput
          value={_countLimit}
          id="id-countlimit"
          label="Limited:"
          tooltip="You can specify how many times the element can be completed by the player within the refresh period"
          disabled={_unlimitedCount}
          onChange={(value) => setCountLimit(value)}
          type="number"
        />
      </Grid>
    </div>
  );
};

ElementLimitCount.propTypes = {
  limitCount: PropTypes.bool,
  countLimit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default ElementLimitCount;
