import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

// Development time
import PropTypes from "prop-types";

// Own components
import MissionPickerSingle from "./MissionPickerSingle";
import ElementButton from "../inputs/ElementButton";

// Styling
import "../inputs/element-input.css";

const ObjectivesPickerStreak = (props) => {
  const [missionId, setMissionId] = useState(props.missionId);
  const [hidePicker, setHidePicker] = useState(false);

  useEffect(() => {
    props.onChange(missionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionId]);

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-objectives-streak-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Objectives" : "- Objectives"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <>
          <Grid item xs={12}>
            {
              <MissionPickerSingle
                title="Objectives:"
                tooltip="Specify the mission driving the streak"
                onChange={(id) => setMissionId(id)}
                missionId={missionId}
                editMode={false}
              />
            }
          </Grid>
        </>
      )}
    </Grid>
  );
};

ObjectivesPickerStreak.propTypes = {
  onChange: PropTypes.func,
  missionId: PropTypes.string,
};

export default ObjectivesPickerStreak;
