import React, { useState, useEffect, Suspense } from "react";
import { connect } from "react-redux";
import { styled } from "@material-ui/core/styles";

import { Spinner } from "../Spinner";

import PropTypes from "prop-types";
import ElementButton from "./ElementButton";

import { imageUrl, defaultImage } from "../../utils/imageutils";
import { isEmpty } from "../../utils/utils";

const Input = styled("input")({
  display: "none",
});

const smallImageSize = 72;
const normalImageSize = 122;

const ElementImage = (props) => {
  const [imageSource, setImageSource] = useState({});
  const [imgPath, setImgPath] = useState(defaultImage());

  const id = `contained-button-file-${props.id}`;
  const isSmallImage = props.size === "small";
  const imageSize = isSmallImage ? smallImageSize : normalImageSize;
  const imageClass = isSmallImage ? "elementimagesmall" : "elementimage";
  const imageBoxClass = isSmallImage ? "imageboxsmall" : "imagebox";

  useEffect(() => {
    if (isEmpty(imageSource)) return;
    props.onDataChanged({ file: imageSource });
    return () => {
      //cleanup;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgPath]);

  useEffect(() => {
    if (props.path != null && props.path !== "") {
      setImgPath(props.path);
      //console.log(`Id        : ${id}`);
      //console.log(`Image path: ${props.path}`);
    }
  }, [props.path]);

  const handleCapture = ({ target }) => {
    setImageSource(target.files[0]);
    setImgPath(imageUrl(target.files[0]));
  };

  //             <img src={imagePath(imageSource)} height={96} alt="" />

  return (
    <div className={imageClass}>
      <Suspense fallback={<Spinner />}>
        <div className={imageBoxClass}>
          <img src={imgPath} height={imageSize} width={imageSize} alt="" />
        </div>
      </Suspense>
      <div style={{ paddingLeft: "16px" }}>
        <label htmlFor={id}>
          <Input
            accept="image/*"
            id={id}
            multiple={false}
            type="file"
            onChange={handleCapture}
          />
          {isSmallImage ? (
            <ElementButton
              id="id-btn-small-image"
              width="100px"
              height="30px"
              variant="contained"
              component="span"
              color="blue"
            >
              + Image
            </ElementButton>
          ) : (
            <ElementButton
              id="id-btn-normal-image"
              variant="contained"
              component="span"
              color="blue"
            >
              + Image
            </ElementButton>
          )}
        </label>
      </div>
    </div>
  );
};

ElementImage.propTypes = {
  onDataChanged: PropTypes.func,
  account: PropTypes.object,
  path: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});

export default connect(mapStateToProps)(ElementImage);
