import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import MenuItemExt from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

import PropTypes from "prop-types";

import { useAuth0 } from "@auth0/auth0-react";
import {
  setUserAccess,
  //showNavbar,
  isAdmin,
  NO_ROLE,
  //SERVICE_ADMIN_ROLE,
} from "../auth/roles";

import AccountsPicker from "../components/pickers/AccountsPicker";

import "./MainPage.css";

import GLRoutes from "./GLRoutes";

const mainStyle = {
  margin: "0 3%",
  paddingTop: "2rem",
  paddingLeft: "0px", //windowWidth > breakWidth ? "180px" : "0",
  paddingRight: "0px",
};

const MainPage = ({ userrole }) => {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const [roleUpdated, setRoleUpdated] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const contentHeight = 1400;

  let history = useHistory();

  useEffect(() => {
    if (userrole === NO_ROLE) return;
    history.push("/analytics");
    setShowNew(isAdmin());
  }, [userrole, history]);

  useEffect(() => {
    console.log("showNew", showNew);
  }, [showNew]);

  useEffect(() => {
    const getUserToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user",
        });
        setUserAccess(accessToken);
        setRoleUpdated(true);
        //setShowNew(true);
        console.log("Role state: ", roleUpdated);
      } catch (e) {
        //console.log(e.toString());
      }
    };
    getUserToken();
  }, [getAccessTokenSilently]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
      <Sidebar style={{ backgroundColor: "#8AAAFA", height: contentHeight }}>
        <Menu
          menuItemStyles={{
            button: ({ active, disabled }) => {
              // only apply styles on first level elements of the tree
              return {
                font: "Montserrat",
                fontSize: "13px",
                color: disabled ? "#ffffff" : "black",
                backgroundColor: active ? "#ffffff" : undefined,
              };
            },
          }}
        >
          <MenuItem
            component={<Link to="/accounts" />}
            icon={<img src="assets/icons/accounts.png" className="menu-img" />}
          >
            Accounts
          </MenuItem>
          <MenuItem
            component={<Link to="/analytics" />}
            icon={<img src="assets/icons/analytics.png" className="menu-img" />}
          >
            Analytics
          </MenuItem>
          <hr />
          <MenuItem
            component={<Link to="/players" />}
            icon={<img src="assets/icons/players.png" className="menu-img" />}
          >
            Players
          </MenuItem>
          <MenuItem
            component={<Link to="/teams" />}
            icon={<img src="assets/icons/teams.png" className="menu-img" />}
          >
            Teams
          </MenuItem>
          <MenuItem
            component={<Link to="/events" />}
            icon={<img src="assets/icons/events.png" className="menu-img" />}
          >
            Events
          </MenuItem>
          <MenuItem
            component={<Link to="/missions" />}
            icon={<img src="assets/icons/missions.png" className="menu-img" />}
          >
            Missions
          </MenuItem>
          <MenuItem
            component={<Link to="/streaks" />}
            icon={<img src="assets/icons/streaks.png" className="menu-img" />}
          >
            Streaks
          </MenuItem>
          <MenuItem
            component={<Link to="/achievements" />}
            icon={
              <img src="assets/icons/achievements.png" className="menu-img" />
            }
          >
            Achievements
          </MenuItem>
          <MenuItem
            component={<Link to="/levels" />}
            icon={<img src="assets/icons/levels.png" className="menu-img" />}
          >
            Levels
          </MenuItem>
          <MenuItem
            component={<Link to="/leaderboards" />}
            icon={
              <img src="assets/icons/leaderboards.png" className="menu-img" />
            }
          >
            Leaderboards
          </MenuItem>
          <MenuItem
            component={<Link to="/prizes" />}
            icon={<img src="assets/icons/prizes.png" className="menu-img" />}
          >
            Prizes
          </MenuItem>
          <MenuItem
            component={<Link to="/mysteryboxes" />}
            icon={
              <img src="assets/icons/mysteryboxes.png" className="menu-img" />
            }
          >
            Mystery Boxes
          </MenuItem>
          <MenuItem
            component={<Link to="/raffles" />}
            icon={<img src="assets/icons/raffles.png" className="menu-img" />}
          >
            Raffles
          </MenuItem>
          <MenuItem
            component={<Link to="/quizzes" />}
            icon={<img src="assets/icons/quizzes.png" className="menu-img" />}
          >
            Quizzes
          </MenuItem>
          <MenuItem
            component={<Link to="/surveys" />}
            icon={<img src="assets/icons/surveys.png" className="menu-img" />}
          >
            Surveys
          </MenuItem>
          <hr />
          <MenuItem
            component={<Link to="/rules" />}
            icon={<img src="assets/icons/rules.png" className="menu-img" />}
          >
            Rules
          </MenuItem>
        </Menu>
      </Sidebar>
      <div className="app-container">
        <header className="header">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/gamelayer-logo.png"}
            alt="Logo"
            height="64px"
          />{" "}
          <div className="header-right">
            <AccountsPicker />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ paddingLeft: 20 }}>
                <IconButton onClick={handleClick}>
                  <Avatar
                    style={{
                      width: 36,
                      height: 36,
                      borderRadius: "50%",
                    }}
                    src={user && user.picture}
                    alt="Avatar"
                  />
                </IconButton>
              </div>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItemExt
                  style={{ font: "Montserrat", fontSize: 14 }}
                  onClick={handleLogout}
                >
                  Logout
                </MenuItemExt>
              </Popover>
            </div>
          </div>
        </header>
        <div style={mainStyle} id="app-content">
          <GLRoutes />
        </div>
      </div>
    </div>
  );
};

MainPage.propTypes = {
  userrole: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  userrole: state.root.userrole,
});

export default connect(mapStateToProps, null)(MainPage);
