import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItemExt } from "./PickerItem";
import {
  populateElementSelection,
  createSelectableElementList,
  addToSelection,
} from "./pickertools";

import { ElementType } from "../../definitions/definitions";

const AchievementsPickerNew = (props) => {
  // {currentCount: 0, id: "testing-event", count: 2}
  const editMode = false;
  const [selection, setSelection] = useState([]);
  const [selectableAchievements, setSelectableAchievements] = useState(
    props.achievements
  );

  const { achievements, selectedAchievements, achievementId, onChange } = props;

  useEffect(() => {
    // Create list of selected items based on achievements
    setSelectableAchievements(
      createSelectableElementList(
        achievements,
        selectedAchievements,
        achievementId
      )
    );
    setSelection(
      populateElementSelection(
        achievements,
        selectedAchievements,
        ElementType.ACHIEVEMENT,
        editMode
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Create list of selected items based on achievements
    const list = createSelectableElementList(
      achievements,
      selection,
      achievementId
    );
    setSelectableAchievements(list);
    let elementIds = selection.map(function (element) {
      return element.id;
    });
    onChange(elementIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  // add item to selected list
  const onSelected = (id) => {
    setSelection(addToSelection(selection, props.achievements, id));
  };

  const onDelete = (id) => {
    const list = selection.filter((item) => item.id !== id);
    setSelection(list);
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Achievement:"
        id="id-achievementpicker"
        required={false}
        onSelected={(id) => onSelected(id)}
        items={selectableAchievements}
        tooltip={props.tooltip}
        type="achievements"
      />
      {selection &&
        selection.map((item) => {
          return (
            <div className="rows" key={item.id}>
              <PickerItemExt
                title="Achievement Name:"
                id={item.id}
                name={item.name}
                imgUrl={item.imgUrl}
                onDelete={onDelete}
              />
              <div className="pickerspace"></div>
            </div>
          );
        })}
    </div>
  );
};

AchievementsPickerNew.propTypes = {
  achievements: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  selectedAchievements: PropTypes.array.isRequired,
  objectiveMode: PropTypes.bool,
  tooltip: PropTypes.string,
  achievementId: PropTypes.string,
  editMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  achievements: state.input.achievements ? state.input.achievements : [],
  account: state.input.account,
});

export default connect(mapStateToProps, null)(AchievementsPickerNew);
