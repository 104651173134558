import store from "../store";
import { setUserRole, setUserData } from "../actions/rootActions";

const ADMIN_READ = "admin:read";
const ADMIN_WRITE = "admin:write";
const ACCOUNT_READ = "account:read";
const ACCOUNT_WRITE = "account:write";

const SERVICE_ADMIN_ROLE = 1000;
const ACCOUNT_ADMIN_ROLE = 500;
const ACCOUNT_READ_ROLE = 100;
const NO_ROLE = 0;

/**
 * @description Parse JWT and returns a JSON object
 * @param {*} token
 * @returns JSON object or null if parsing failed
 */
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

/**
 * @description Set's user access rights
 * @param {*} token
 */
const setUserAccess = (token) => {
  const jsonObj = parseJwt(token);
  let role = NO_ROLE;

  // If parsing fails or if we have no permissions, user has no role
  if (!jsonObj || !jsonObj.permissions || jsonObj.permissions.length === 0) {
    store.dispatch(setUserRole(role));
    return;
  }
  let appMetadata = jsonObj["https://api.gamelayer.co/app_metadata"];
  const accounts =
    appMetadata && appMetadata.accounts ? appMetadata.accounts : [];
  if (
    jsonObj.permissions.includes(ADMIN_READ) &&
    jsonObj.permissions.includes(ADMIN_WRITE)
  ) {
    role = SERVICE_ADMIN_ROLE;
  } else if (jsonObj.permissions.includes(ACCOUNT_WRITE)) {
    role = ACCOUNT_ADMIN_ROLE;
  } else if (jsonObj.permissions.includes(ACCOUNT_READ)) {
    role = ACCOUNT_READ_ROLE;
  } else {
    role = NO_ROLE;
  }
  store.dispatch(setUserRole(role));
  store.dispatch(
    setUserData({
      role,
      accounts,
    })
  );
};
/*
permissions: Array(3)
0: "account:read"
1: "account:write"
2: "docs:read"
length: 3
*/

/**
 * @description Check if current user has access to docs only
 * @returns true access only to docs, false otherwise
 */

/**
 * @description Checks if current user is GameLayer Admin
 * @returns true if admin, false otherwise
 */
const isAdmin = () => {
  const state = store.getState();
  const role = state.root.userdata.role;
  return role === SERVICE_ADMIN_ROLE;
};

/**
 * @description Checks if current user is Account Admin.
 * @returns true if user is account admin, false otherwise
 */
const isAccountAdmin = () => {
  const state = store.getState();
  const role = state.root.userdata.role;
  return role === ACCOUNT_ADMIN_ROLE;
};

const hasAdminRights = () => {
  const state = store.getState();
  const role = state.root.userdata.role;
  return role === ACCOUNT_ADMIN_ROLE || role === SERVICE_ADMIN_ROLE;
};

/**
 * @description Checks if current user has read only access to account data
 * @returns true if user has read only access, otherwise false
 */
const hasAccountReadAccess = () => {
  const state = store.getState();
  const role = state.root.userdata.role;
  return role === ACCOUNT_READ_ROLE;
};

/**
 * @description Informs if navigation is to be shown for user
 * @returns true - navbar can be shown, false - navbar should not be shown
 */
const showNavbar = () => {
  const state = store.getState();
  const role = state.root.userdata.role;
  return (
    role === SERVICE_ADMIN_ROLE ||
    role === ACCOUNT_ADMIN_ROLE ||
    role === ACCOUNT_READ_ROLE
  );
};

const getUserAccounts = () => {
  const state = store.getState();
  return state.root.userdata ? state.root.userdata.accounts : [];
};

export {
  parseJwt,
  setUserAccess,
  isAdmin,
  isAccountAdmin,
  hasAccountReadAccess,
  hasAdminRights,
  showNavbar,
  getUserAccounts,
  NO_ROLE,
  SERVICE_ADMIN_ROLE,
};
