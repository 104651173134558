import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItem } from "./PickerItem";
import {
  populateElementSelection,
  createSelectableElementList,
  updateSelectionList,
  addToSelection,
} from "./pickertools";
import { ElementType } from "../../definitions/definitions";

const editMode = true;

const AchievementsPickerEdit = (props) => {
  // {currentCount: 0, id: "testing-event", count: 2}
  const [selection, setSelection] = useState([]);
  const [selectableAchievements, setSelectableAchievements] = useState(
    props.achievements
  );

  const { achievements, selectedAchievements, achievementId, onChange } = props;
  useEffect(() => {
    // Create list of selected items based on achievements
    setSelectableAchievements(
      createSelectableElementList(
        achievements,
        selectedAchievements,
        achievementId
      )
    );
    setSelection(
      populateElementSelection(
        achievements,
        selectedAchievements,
        ElementType.ACHIEVEMENT,
        editMode
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selection.length > 0) {
      // Create list of selected items based on achievements
      const list = createSelectableElementList(
        achievements,
        selection,
        achievementId
      );
      setSelectableAchievements(list);
    }
    let elementIds = selection.map(function (element) {
      return {
        id: element.id,
        steps: element.count,
      };
    });
    onChange(elementIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const updateSelection = (id, value) => {
    const newSelection = updateSelectionList(selection, id, value);
    setSelection(newSelection);
  };

  // add item to selected list
  const onSelected = (id) => {
    setSelection(addToSelection(selection, achievements, id));
  };

  const onDelete = (id) => {
    const list = selection.filter((item) => item.id !== id);
    setSelection(list);
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Achievement:"
        id="id-achievementpicker"
        required={false}
        onSelected={(id) => onSelected(id)}
        items={selectableAchievements}
        tooltip={props.tooltip}
        type="achievements"
      />
      {selection &&
        selection.map((item) => {
          return (
            <div className="rows" key={item.id}>
              <PickerItem
                title="Achievement Name:"
                label="Steps Granted:"
                id={item.id}
                name={item.name}
                imgUrl={item.imgUrl}
                count={item.count}
                onChange={(id, value) => updateSelection(id, value)}
                onDelete={onDelete}
              />
              <div className="pickerspace"></div>
            </div>
          );
        })}
    </div>
  );
};

AchievementsPickerEdit.propTypes = {
  achievements: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  selectedAchievements: PropTypes.array.isRequired,
  tooltip: PropTypes.string,
  achievementId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  achievements: state.input.achievements ? state.input.achievements : [],
  account: state.input.account,
});

export default connect(mapStateToProps, null)(AchievementsPickerEdit);
