import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import { fetchTeamsForAccount } from "../actions/adminActions";

import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";

const Teams = (props) => {
  return (
    <ElementsPage
      title="Teams"
      getElements={props.getTeamsForAccount}
      elementType={ElementType.TEAM}
    />
  );
};

Teams.propTypes = {
  teams: PropTypes.array.isRequired,
  getTeamsForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  teams: state.input.teams,
  account: state.input.account,
});
const mapDispatchToProps = (dispatch) => ({
  getTeamsForAccount: (query) => dispatch(fetchTeamsForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
