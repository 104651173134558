import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ElementTooltip from "../inputs/ElementTooltip";

import "../inputs/element-input.css";

const ListItem = (props) => {
  const even = props.index % 2 === 0;
  const pickerClass = `pickeritem ${even ? "even" : "odd"}`;
  return (
    <div className={pickerClass}>
      <label
        className="pickerlabel"
        onClick={() => {
          props.onSelected(props.item.id, props.item);
        }}
      >
        {props.item.name}{" "}
      </label>
    </div>
  );
};

ListItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  onSelected: PropTypes.func,
};

const ElementPickerNew = (props) => {
  const [properties, setProperties] = useState({
    active: false,
    title: props.title || "Title not set",
    error: props.error || "",
    id: props.id || "id-1",
    tooltip: props.tooltip || "",
    required: props.required || false,
    type: props.type || "event",
  });

  const [expanded, setExpanded] = useState(false);
  const [pickerImage, setPickerImage] = useState(
    process.env.PUBLIC_URL + "/assets/images/event_picker.png"
  );

  useEffect(() => {
    if (props.type === "achievements") {
      setPickerImage(
        process.env.PUBLIC_URL + "/assets/images/achievement_picker.png"
      );
    } else if (props.type === "missions") {
      setPickerImage(
        process.env.PUBLIC_URL + "/assets/images/mission_picker.png"
      );
    } else if (props.type === "levels") {
      setPickerImage(
        process.env.PUBLIC_URL + "/assets/images/level_picker.png"
      );
    } else if (props.type === "teams") {
      setPickerImage(process.env.PUBLIC_URL + "/assets/images/team_picker.png");
    } else if (props.type === "prizes") {
      setPickerImage(
        process.env.PUBLIC_URL + "/assets/images/prize_picker.png"
      );
    } else if (props.type === "languages") {
      setPickerImage(
        process.env.PUBLIC_URL + "/assets/images/language_picker.png"
      );
    } else if (props.type === "condition") {
      setPickerImage(process.env.PUBLIC_URL + "/assets/images/condition.png");
    } else if (props.type === "action") {
      setPickerImage(process.env.PUBLIC_URL + "/assets/images/action.png");
    }
  }, [props.type]);

  const onSelected = (index, item) => {
    setExpanded(false);
    props.onSelected(index, item);
  };

  const { id, active, title, error, tooltip, required } = properties;

  const picker = `picker ${active ? "active" : ""}`;

  const downImg = process.env.PUBLIC_URL + "/assets/images/down-chevron.png";
  const upImg = process.env.PUBLIC_URL + "/assets/images/up-chevron.png";

  return (
    <div tabIndex="1" onFocus={() => {}}>
      <div
        tabIndex="2"
        className="pickerlayout"
        onFocus={() => {
          setProperties({ ...properties, active: true });
        }}
        onBlur={() => {
          setProperties({ ...properties, active: false });
          setExpanded(false);
        }}
      >
        <div className="columns">
          <div id={id} className={picker}>
            <div className="pickerleft">
              <img src={pickerImage} alt="" className="pickerimage" />
              <label id={id + "2"} className="pickertitle">
                {error || `${title}`}
              </label>
            </div>
            <div className="pickerright">
              <img
                src={expanded ? upImg : downImg}
                alt=""
                className="pickerimage"
                onClick={() => {
                  setExpanded(!expanded);
                }}
              />
            </div>
          </div>
          {expanded &&
            props.items &&
            props.items.map(function (item, index) {
              return (
                <ListItem
                  item={item}
                  key={index}
                  index={index}
                  onSelected={onSelected}
                />
              );
            })}
        </div>
        <ElementTooltip tooltip={tooltip} required={required} />
      </div>
    </div>
  );
};

ElementPickerNew.propTypes = {
  onSelected: PropTypes.func.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  required: PropTypes.bool,
  items: PropTypes.array,
  error: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string,
};

export { ElementPickerNew };
