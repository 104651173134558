import React, { useState } from "react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// Own components

import "../components.css";

const useStyles = makeStyles({
  root: {
    width: "90px",
    height: "50px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  track: {
    width: "40px",
    height: "20px",
    borderRadius: "10px",
  },
  switchBase: {
    color: "#DCDBE1",
    "&$checked": {
      color: "#214fbb",
      transform: "translateX(40px)",
    },
    "& + $track": {
      backgroundColor: "#DCDBE1",
    },
    "&$checked + $track": {
      backgroundColor: "#e9f0fe",
    },
  },
  checked: {},
  thumb: {
    width: "32px",
    height: "32px",
    transform: "translateX(0px)",
  },
  label: {
    "& .MuiFormControlLabel-label": {
      fontSize: "13px",
      fontFamily: "Montserrat",
      fontWeight: 100,
    },
  },
});

const ElementSwitch = ({ id, label, checked, onChange, ...props }) => {
  const classes = useStyles();
  const [_checked, setChecked] = useState(checked);

  return (
    <FormControlLabel
      className={classes.label}
      control={
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            checked: classes.checked,
            track: classes.track,
            thumb: classes.thumb,
          }}
          {...props}
          checked={_checked}
          onChange={(event) => {
            setChecked(event.target.checked);
            onChange(event.target.checked);
          }}
          name={id}
        />
      }
      label={label}
    />
  );
};

ElementSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ElementSwitch;
