import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "../components.css";
import ElementSwitch from "./ElementSwitch";

const ElementShuffle = ({ isShuffleRequired, onChange }) => {
  const [value, setValue] = useState(isShuffleRequired);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <ElementSwitch
        checked={value}
        id="id-isShuffleRequired"
        label="Shuffle"
        tooltip="Shuffle quesions in the quiz"
        onChange={(value) => setValue(value)}
      />
    </React.Fragment>
  );
};

ElementShuffle.propTypes = {
  isShuffleRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ElementShuffle;
