import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { parseISO, formatISO } from "date-fns";

import ElementTooltip from "./ElementTooltip";
import "react-datepicker/dist/react-datepicker.css";
import "./element-input.css";

function ElementDateTime(props) {
  const [properties, setProperties] = useState({
    active: false,
    label: props.label || "Label",
    tooltip: props.tooltip || "",
    id: props.id || "id-1",
    required: props.required || false,
    disabled: props.disabled || false,
  });
  const [date, setDate] = useState(
    props.date ? parseISO(props.date) : new Date()
  );

  const { active, id, label, tooltip, required, disabled } = properties;
  const dateFormat = "yyyy-MM-dd HH:mm";

  const handleDateChange = (date) => {
    const isoDate = formatISO(date);
    setDate(date);
    //console.log("date ", isoDate);
    props.onChange(isoDate);
  };

  const inputBox = `inputbox ${active ? "active" : ""}`;
  return (
    <div className="input">
      <div id={1} className={inputBox}>
        <label htmlFor={1} id={2} className="label">
          {label}
        </label>
        <div>
          <DatePicker
            id={id}
            htmlFor={1}
            className="field"
            wrapperClassName="datePicker"
            selected={date}
            showTimeInput
            dateFormat={dateFormat}
            required
            disabled={disabled}
            onChange={handleDateChange}
            onFocus={() => setProperties({ ...properties, active: true })}
            onBlur={() => setProperties({ ...properties, active: false })}
          />
        </div>
      </div>
      <ElementTooltip tooltip={tooltip} required={required} />
    </div>
  );
}

ElementDateTime.propTypes = {
  label: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ElementDateTime;
