//Loading types
export const LOADING = "LOADING";

//User auth
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_DATA = "SET_USER_DATA";

function setUserAuth(user) {
  if (!user) {
    return {
      type: USER_LOGGED_OUT,
      user,
    };
  }
  return {
    type: USER_LOGGED_IN,
    user,
  };
}

function loading(on) {
  return {
    type: LOADING,
    on,
  };
}

function setUserRole(userrole) {
  return {
    type: SET_USER_ROLE,
    userrole,
  };
}

function setUserData(userdata) {
  return {
    type: SET_USER_DATA,
    userdata,
  };
}

export { setUserAuth, loading, setUserRole, setUserData };
