import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import { fetchPlayersForAccount } from "../actions/adminActions";

import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";

const Players = (props) => {
  return (
    <>
      <ElementsPage
        title="Players"
        getElements={props.getPlayersForAccount}
        elementType={ElementType.PLAYER}
      />
    </>
  );
};

Players.propTypes = {
  getPlayersForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});
const mapDispatchToProps = (dispatch) => ({
  getPlayersForAccount: (query) => dispatch(fetchPlayersForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Players);
