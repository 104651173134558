import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import { Provider } from "react-redux";
import store from "./store";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import LoginPage from "./containers/LoginPage";
import Loading from "./components/Loading";
import { sec } from "./auth/utils";
import MainPage from "./containers/MainPage";

const App = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, logout } =
    useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);

  if (isLoading) {
    return <Loading />;
  }
  if (!isAuthenticated) {
    logout({
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });

    return (
      <Provider store={store}>
        <LoginPage />
      </Provider>
    );
  }

  return (
    <div>
      <Provider store={store}>
        {" "}
        <Router>
          <MainPage />
        </Router>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
