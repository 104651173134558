/* eslint-disable  no-unused-vars */

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

// Development time
import PropTypes from "prop-types";

// Own components
import ElementInput from "../inputs/ElementInput";
import ElementDateTime from "../inputs/ElementDateTime";

// Styling
import "../inputs/element-input.css";

const ParamPicker = (props) => {
  const params = props.params ? props.params : [];
  const metaParams = props.metaParams ? props.metaParams : [];

  useEffect(() => {
    props.onChange(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const onUpdateParam = (paramName, newValue) => {
    // Use the spread operator to create a new params object with the updated value
    props.onChange({ ...params, [paramName]: { value: newValue } });
  };

  const getValue = (name) => {
    const property = params[name];
    if (property == null) return null;
    return property.value;
  };

  const elementStyle = {
    marginBottom: "10px",
  };

  return (
    <Grid container alignItems="center" justify="center">
      <React.Fragment>
        <Grid item xs={12}>
          {metaParams.map((item) => {
            const type = item.type;
            return (
              <div key={`meta-${item.name}`}>
                {type === "Number" && (
                  <div style={elementStyle}>
                    <ElementInput
                      value={getValue(item.name)}
                      id={`id-param`}
                      type="number"
                      label={`${item.description}:`}
                      required
                      tooltip="Numeric value for the parameter"
                      onChange={(value) => onUpdateParam(item.name, value)}
                    />
                  </div>
                )}
                {type === "Date" && (
                  <div style={elementStyle}>
                    <ElementDateTime
                      id="id-startd"
                      label={`${item.description}:`}
                      date={getValue(item.name)}
                      required
                      tooltip="Date in format YYYY-MM-DD"
                      onChange={(value) => onUpdateParam(item.name, value)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </Grid>
      </React.Fragment>
    </Grid>
  );
};

ParamPicker.propTypes = {
  params: PropTypes.object.isRequired,
  metaParams: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ParamPicker;

/* eslint-enable no-unused-vars */
