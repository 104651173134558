import React, { useState } from "react";
import PropTypes from "prop-types";

import ElementTooltip from "./ElementTooltip";

import "./element-input.css";

const MULTILINE_ROWS = 3;

const ElementInput = (props) => {
  const [properties, setProperties] = useState({
    active: false,
    value: props.value || "",
    error: props.error || "",
    label: props.label || "Label",
    tooltip: props.tooltip || "",
    multiline: props.multiline || false,
    type: props.type || "text",
    id: props.id || "id-1",
    required: props.required || false,
  });

  const [value, setValue] = useState(
    props.value || (props.type === "text" ? "" : 0)
  );

  const changeValue = (event) => {
    const value = event.target.value;
    setValue(value);
    props.onChange(value);
    //setProperties({ ...properties, value, error: "" });
  };
  const { id, active, error, label, tooltip, multiline, type, required } =
    properties;
  const { disabled } = props;

  const field = `field ${active ? "active" : ""} ${
    multiline ? "multilinefield" : ""
  } `;
  const multilineBox = `inputbox multilinebox ${active ? "active" : ""}`;
  const inputBox = `inputbox ${active ? "active" : ""}`;

  return (
    <div className="input">
      <div id={1} className={multiline ? multilineBox : inputBox}>
        <label htmlFor={1} id={2} className="label">
          {error || `${label}`}
        </label>
        <div>
          {!multiline && (
            <input
              htmlFor={1}
              className={field}
              id={id}
              type={type}
              value={value}
              disabled={disabled}
              onChange={(event) => changeValue(event)}
              onFocus={() => setProperties({ ...properties, active: true })}
              onBlur={() => setProperties({ ...properties, active: false })}
            />
          )}
          {multiline && (
            <textarea
              htmlFor={1}
              className={field}
              type="text"
              rows={MULTILINE_ROWS}
              value={value}
              disabled={disabled}
              onChange={(event) => changeValue(event)}
              onFocus={() => setProperties({ ...properties, active: true })}
              onBlur={() => setProperties({ ...properties, active: false })}
            ></textarea>
          )}
        </div>
      </div>
      {tooltip !== "" && (
        <ElementTooltip tooltip={tooltip} required={required} />
      )}
    </div>
  );
};

ElementInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
};

export default ElementInput;
