import { ElementType } from "../../definitions/definitions";
/**
 * Retrieved item by id
 * @param {*} list
 * @param {*} id - to be searched
 * @returns found item, undefined if none found
 */
const getItemById = (list, id) => {
  return list.find((element) => element.id === id);
};

/**
 * @description Checks if list contains ID
 * @param {*} list
 * @param {*} id
 * @returns true if included, false otherwise
 */
const includesId = (list, id) => {
  return list.find((element) => element.id === id);
};

/**
 *
 * @param {*} list
 * @param {*} ids
 * @returns
 */
const populateSelection = (list, ids) => {
  return ids.map((id) => {
    const element = getItemById(list, id);
    if (element) {
      return {
        id: id,
        name: element.name,
        imgUrl: element.imgUrl,
      };
    } else {
      return {
        id: id,
        name: "Element name is missing",
        imgUrl: undefined,
      };
    }
  });
};

const populateElementSelection = (list, ids, elementType, editMode) => {
  const _list = ids.map((elem) => {
    const id = editMode ? elem.id : elem;
    const element = getItemById(list, id);
    if (element) {
      return {
        id: id,
        name: element.name,
        imgUrl: element.imgUrl,
        count:
          elementType === ElementType.ACHIEVEMENT ? elem.steps : elem.count,
      };
    }
    return {
      id: id,
      name: "",
      imgUrl: undefined,
      count: elementType === ElementType.ACHIEVEMENT ? elem.steps : elem.count,
    };
  });
  return _list;
};

const createSelectableElementList = (elements, selection, elementId) => {
  const list = elements.filter(
    (element) =>
      !includesId(selection, element.id) &&
      element.id !== elementId &&
      !selection.includes(element.id)
  );
  return list;
};

const addToSelection = (selection, items, id) => {
  const item = getItemById(items, id);
  if (item) {
    const newArr = [
      ...selection,
      {
        id: item.id,
        name: item.name,
        imgUrl: item.imgUrl,
        currentCount: 0,
        count: 1,
      },
    ];
    return newArr;
  }
  return selection;
};

const updateSelectionList = (selection, id, value) => {
  const newSelection = selection.map((elem) => {
    var temp = Object.assign({}, elem);
    if (temp.id === id) {
      temp.count = value;
    }
    return temp;
  });
  return newSelection;
};

export {
  getItemById,
  includesId,
  populateSelection,
  createSelectableElementList,
  populateElementSelection,
  addToSelection,
  updateSelectionList,
};
