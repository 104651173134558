import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

const ElementRequiredPassrate = ({ requiredPassRate, onChange }) => {
  const [value, setValue] = useState(requiredPassRate);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-passrate"
        label="Quiz Pass Rate:"
        tooltip="Required passrate (0-100) to pass the quiz"
        onChange={(value) => setValue(value)}
        type="number"
      />
    </React.Fragment>
  );
};

ElementRequiredPassrate.propTypes = {
  requiredPassRate: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ElementRequiredPassrate;
