import React from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
const cloneDeep = require("lodash.clonedeep");

// Development time
import PropTypes from "prop-types";

const ElementsTable = (props) => {
  const data = cloneDeep(props.data);
  //const data = props.data.map((o) => ({ ...o }));
  return (
    <MaterialTable
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      title={props.title}
      columns={props.columns}
      data={data}
      detailPanel={props.detailPanel}
      dense
      options={{
        pageSizeOptions: [5, 10, 20],
        pageSize: 10,
        padding: "dense",
        detailPanelType: "single",
        tableLayout: "auto",
        headerStyle: { style: "white-space: nowrap", padding: "dense" },
        showTitle: props.showTitle != null ? props.showTitle : false,
        emptyRowsWhenPaging:
          props.emptyRowsWhenPaging != null ? props.emptyRowsWhenPaging : true,
        cellStyle: {
          padding: "5px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 100,
          fontSize: "12px",
          font: "Montserrat",
        },
        rowStyle: (x) => {
          if (x.tableData.id % 2) {
            return {
              backgroundColor: "#f2f2f2",
            };
          } else {
            return {};
          }
        },
      }}
    />
  );
};

ElementsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  detailPanel: PropTypes.func,
  emptyRowsWhenPaging: PropTypes.bool,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
};

export default ElementsTable;
