import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// Material-UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";

// Own components
import {
  addAccount,
  modifyAccount,
  copyElements,
} from "../actions/adminActions";

// New UI components
import ElementId from "../components/inputs/ElementId";
import ElementName from "../components/inputs/ElementName";
import ElementDescription from "../components/inputs/ElementDescription";
import ElementImage from "../components/inputs/ElementImage";
import ElementIsAvailable from "../components/inputs/ElementIsAvailable";
import ElementButton from "../components/inputs/ElementButton";
import ElementInput from "../components/inputs/ElementInput";
import ElementRadio from "../components/inputs/ElementRadio";
import ElementTooltip from "../components/inputs/ElementTooltip";

import { uploadImageForAccount } from "../api/backendAPI";
import { isEmpty } from "../utils/utils";
import { ErrorDialog } from "../components/Dialogs";
import { isAdmin, hasAdminRights } from "../auth/roles";

import "../components/inputs/element-input.css";

const cloneDeep = require("lodash.clonedeep");

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    //   background: "#f6f7fa",
    background: "white",
    paddingTop: 15,
    paddingBottom: 10,
    paddingLeft: 62,
  },
  label: {
    fontSize: 13,
    fontFamily: "Montserrat",
    fontWeight: 100,
  },
});

const ProdAccount = "PROD";
const DevAccount = "DEV";
const FreeAccount = "FREE";

const AccountTypeSelection = ({
  isDevAccount,
  isFreeAccount,
  onDataChanged,
}) => {
  const classes = useStyles();
  const [radioActive, setRadioActive] = useState(false);
  const [accountType, setAccountType] = useState(
    isDevAccount ? DevAccount : isFreeAccount ? FreeAccount : ProdAccount
  );
  useEffect(() => {
    if (accountType === FreeAccount) {
      onDataChanged({
        isDevAccount: false,
        isFreeAccount: true,
      });
    } else if (accountType === DevAccount) {
      onDataChanged({
        isDevAccount: true,
        isFreeAccount: false,
      });
    } else {
      onDataChanged({
        isDevAccount: false,
        isFreeAccount: false,
      });
    }
  }, [accountType]);

  const radioBox = `radiobox ${radioActive ? "active" : ""}`;
  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <label className="textlabel">Account Type:</label>
        </Grid>
        <Grid item xs={8} className="radiorow">
          <Box
            className={radioBox}
            onFocus={() => setRadioActive(true)}
            onBlur={() => setRadioActive(false)}
          >
            <FormControl component="fieldset">
              <RadioGroup
                className="radiocontainer"
                row
                defaultValue={DevAccount}
                value={accountType}
                name="account-type"
                onChange={(event) => {
                  setAccountType(event.target.value);
                }}
              >
                <FormControlLabel
                  value={ProdAccount}
                  control={<ElementRadio id="id-prod" />}
                  label={
                    <Typography className={classes.label}>Paid</Typography>
                  }
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={FreeAccount}
                  control={<ElementRadio id="id-free" />}
                  label={
                    <Typography className={classes.label}>Free</Typography>
                  }
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={DevAccount}
                  control={<ElementRadio id="id-dev" />}
                  label={
                    <Typography className={classes.label}>Test</Typography>
                  }
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <ElementTooltip tooltip="Type of account"></ElementTooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

AccountTypeSelection.propTypes = {
  isDevAccount: PropTypes.bool.isRequired,
  isFreeAccount: PropTypes.bool.isRequired,
  onDataChanged: PropTypes.func.isRequired,
};

const ElementAccount = (props) => {
  const classes = useStyles();

  const [formData, setFormData] = useState(props.data);
  const [imageSource, setImageSource] = useState({});
  const [copyTo, setCopyTo] = useState("");

  // Form validity
  const [validForm, setValidForm] = useState({
    id: props.data.id !== "",
    name: props.data.name !== "",
  });

  const [validId, setValidId] = useState(true);
  const isFormValid = validForm.name && validForm.id;
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [isDevAccount, setIsDevAccount] = useState(props.data.isDevAccount);
  const [isFreeAccount, setIsFreeAccount] = useState(props.data.isFreeAccount);

  const saveData = (path) => {
    const accountId = formData.account.trim();
    let data = cloneDeep(formData);
    data.account = accountId;
    data.imgUrl = path;
    if (props.updateMode) {
      props.updateEntity(data).then(() => {
        props.onUpdated();
      });
    } else {
      props.createEntity(data).then(() => {
        props.onUpdated();
      });
    }
  };

  const saveEntity = () => {
    // We create account always with empty image
    if (!props.updateMode) {
      saveData(process.env.REACT_APP_DEFAULT_IMAGE);
    } else {
      if (!isEmpty(imageSource)) {
        uploadImageForAccount(formData, imageSource)
          .then((res) => {
            const { path } = res;
            saveData(path);
          })
          .catch((err) => {
            toast.error(
              `Failed to upload image. ${err.error ? err.error : ""}`
            );
          });
      } else {
        saveData(formData.imgUrl);
      }
    }
  };

  const onImageChanged = (data) => {
    setImageSource(data.file);
  };

  const isValidId = (id) => {
    if (!props.updateMode) {
      // ID must be unique
      const obj = props.entities.find((item) => item.id === id);
      setValidForm({
        ...validForm,
        id: obj === undefined,
      });
      setValidId(obj === undefined);
    }
  };

  const isValidName = (name) => {
    setValidForm({
      ...validForm,
      name: name !== "",
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (isFormValid) {
      saveEntity();
    } else {
      setShowError(!validId);
      if (!validId) {
        setErrorText(
          `Account with ${formData.id} exists already. Please use different ID.`
        );
      }
    }
  };

  const submitCopy = () => {
    const copyFrom = formData.account;
    props.copyAccountElement(copyFrom, copyTo);
  };

  return (
    <div className={classes.root}>
      <form className="needs-validation" noValidate onSubmit={submitHandler}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid
            container
            xs={8}
            spacing={2}
            alignItems="center"
            justify="center"
            //style={{ width: "70%" }}
          >
            <Grid item xs={12}>
              <ElementIsAvailable
                isAvailable={formData.isAvailable}
                onChange={(isAvailable) =>
                  setFormData({ ...formData, isAvailable })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <ElementId
                disabled={props.updateMode}
                id={formData.account}
                onChange={(account) => {
                  setFormData({ ...formData, account });
                  isValidId(account);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementName
                name={formData.name}
                onChange={(name) => {
                  setFormData({ ...formData, name });
                  isValidName(name);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementDescription
                description={formData.description}
                required={true}
                onChange={(description) => {
                  setFormData({ ...formData, description });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementImage
                path={formData.imgUrl}
                onDataChanged={(data) => {
                  onImageChanged(data);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ElementInput
                value={formData.imageFolder}
                id="id-image-folder"
                label="Image Folder:"
                required
                tooltip="[Required] This is the folder name for account specific images, for example, GameLayerImages"
                onChange={(imageFolder) => {
                  setFormData({ ...formData, imageFolder });
                }}
                type="text"
              />
            </Grid>
            {hasAdminRights() && (
              <Grid item xs={12}>
                <ElementInput
                  value={formData.apikey}
                  id="id-api-key"
                  label="API Key:"
                  tooltip="[Required] API key needed when calling GameLayer APIs"
                  disabled
                  required
                  onChange={() => {}}
                  type="text"
                />
              </Grid>
            )}
            {isAdmin() && (
              <Grid item xs={12}>
                <AccountTypeSelection
                  isDevAccount={isDevAccount}
                  isFreeAccount={isFreeAccount}
                  updateMode={props.updateMode}
                  onDataChanged={(data) => {
                    const { isDevAccount, isFreeAccount } = data;
                    if (!props.updateMode) {
                      setIsDevAccount(isDevAccount);
                      setIsFreeAccount(isFreeAccount);
                      setFormData({ ...formData, isDevAccount, isFreeAccount });
                    }
                  }}
                />
              </Grid>
            )}
            {isAdmin() && (
              <Grid item xs={12}>
                <ElementInput
                  value={copyTo}
                  id="id-copy-to"
                  label="Copy Account Elements to:"
                  tooltip="Copy GameLayer element from active account to this account."
                  onChange={(value) => {
                    setCopyTo(value);
                  }}
                  type="text"
                />
                <div style={{ paddingTop: 10, paddingBottom: 20 }}>
                  <ElementButton
                    id="id-copy-btn"
                    variant="contained"
                    size="small"
                    color="blue"
                    onClick={() => {
                      submitCopy();
                    }}
                  >
                    Copy Elements
                  </ElementButton>
                </div>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4}>
            <div className="buttonrow">
              <div style={{ paddingRight: 20 }}>
                {hasAdminRights() && (
                  <ElementButton
                    id="id-save-btn"
                    variant="contained"
                    size="small"
                    color="amber"
                    type="submit"
                  >
                    Save
                  </ElementButton>
                )}
              </div>
              {!props.updateMode && (
                <ElementButton
                  id="id-cancel-btn"
                  variant="contained"
                  size="small"
                  color="blue"
                  onClick={() => {
                    if (props.onCancel) props.onCancel();
                  }}
                >
                  Cancel
                </ElementButton>
              )}
            </div>
          </Grid>
          {showError && (
            <ErrorDialog title={errorText} onDone={() => setShowError(false)} />
          )}
        </Grid>
      </form>
    </div>
  );
};

ElementAccount.propTypes = {
  data: PropTypes.object.isRequired,
  updateMode: PropTypes.bool.isRequired,
  account: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onUpdated: PropTypes.func,
  createEntity: PropTypes.func.isRequired,
  updateEntity: PropTypes.func.isRequired,
  entities: PropTypes.array.isRequired,
  copyAccountElement: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
  entities: state.input.accounts,
});

const mapDispatchToProps = (dispatch) => ({
  createEntity: (data) => dispatch(addAccount(data)),
  updateEntity: (data) => dispatch(modifyAccount(data)),
  copyAccountElement: (copyFrom, copyTo) =>
    dispatch(copyElements(copyFrom, copyTo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ElementAccount);
