import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const BLUE_NORMAL = "#214FBB";
const BLUE_HOVER = "#5E86FE";
const BLUE_DISABLED = "#EAF1FE";
const GREEN_NORMAL = "#00AD32";
const GREEN_HOVER = "#52CE5B";
const GREEN_DISABLED = "#E7F1DB";
const RED_NORMAL = "#E73A2A";
const RED_HOVER = "#F96355";
const RED_DISABLED = "#EA8B80";
const AMBER_NORMAL = "#FFB623";
const AMBER_HOVER = "#FDC75B";
const AMBER_DISABLED = "#E8BB84";

const getBackground = (color) => {
  switch (color) {
    case "blue":
      return BLUE_NORMAL;
    case "green":
      return GREEN_NORMAL;
    case "red":
      return RED_NORMAL;
    case "amber":
      return AMBER_NORMAL;
    default:
      return BLUE_NORMAL;
  }
};

const getDisabled = (color) => {
  switch (color) {
    case "blue":
      return BLUE_DISABLED;
    case "green":
      return GREEN_DISABLED;
    case "red":
      return RED_DISABLED;
    case "amber":
      return AMBER_DISABLED;
    default:
      return BLUE_DISABLED;
  }
};

const getHover = (color) => {
  switch (color) {
    case "blue":
      return BLUE_HOVER;
    case "green":
      return GREEN_HOVER;
    case "red":
      return RED_HOVER;
    case "amber":
      return AMBER_HOVER;
    default:
      return BLUE_HOVER;
  }
};

const getWidth = (width) => {
  if (width != null) return width;
  return "165px";
};

const getHeight = (height) => {
  if (height != null) return height;
  return "42px";
};

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 100,
    width: (props) => getWidth(props.width),
    height: (props) => getHeight(props.height),
    //border: "3px solid",
    lineHeight: 1,
    backgroundColor: (props) => getBackground(props.color),
    //borderColor: (props) => getBackground(props.color),
    borderRadius: "21px",
    color: "white",
    fontFamily: ["Montserrat", "Roboto", "Arial"].join(","),
    "&:hover": {
      //border: "3px solid",
      //borderColor: (props) => getBackground(props.color),
      boxShadow: "none",
      backgroundColor: (props) => getHover(props.color),
    },
    "&:active": {
      //border: "3px solid",
      //borderColor: (props) => getBackground(props.color),
      boxShadow: "none",
      backgroundColor: (props) => getBackground(props.color),
    },
    "&:disabled": {
      backgroundColor: (props) => getDisabled(props.color),
    },
  },
});

function ElementButton(props) {
  const { ...other } = props;
  const classes = useStyles(props);
  return <Button className={classes.root} {...other} />;
}

export default ElementButton;
