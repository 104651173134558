/* eslint-disable-next-line no-unused-vars */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";

// Development time
import PropTypes from "prop-types";

// Own components
import ElementButton from "../inputs/ElementButton";
import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItemExt } from "./PickerItem";
import ParamPicker from "./ParamPicker";

// Styling
import "../inputs/element-input.css";

const ConditionPicker = (props) => {
  const [condition, setCondition] = useState(null);
  const conditions = props.rulesmetadata ? props.rulesmetadata.conditions : [];
  const [conditionParams, setConditionParams] = useState(
    props.rule.conditionParams ? props.rule.conditionParams : null
  );

  useEffect(() => {
    if (props.rule) setCondition(populateCondition(props.rule.conditionId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (condition && conditionParams) {
      props.onChange(condition.id, conditionParams);
    }
  }, [condition, conditionParams]);

  const getConditionById = (id) => {
    return conditions
      ? conditions.find((condition) => condition.id === id)
      : null;
  };

  // todo: ensure
  const updateParamsToDefaults = (condition) => {
    if (!condition) return;
    const params = {};
    for (const param of condition.params) {
      params[param.name] = param.type === "Number" ? 0 : null;
    }
    setConditionParams(params);
  };

  // add item to selected list
  const onSelected = (id) => {
    let condition = getConditionById(id);
    if (condition) {
      // if conditionId is updated, update conditionParams with default values
      if (condition.id !== props.rule.conditionId) {
        updateParamsToDefaults(condition);
      }
      setCondition(condition);
    }
  };

  const onDelete = () => {
    props.onChange(null);
    setCondition(null);
  };

  const populateCondition = (id) => {
    const condition = conditions.find((cond) => cond.id === id);
    if (condition) {
      return {
        id: condition.id,
        name: condition.name,
        description: condition.description,
        imgUrl:
          process.env.PUBLIC_URL + "/assets/images/condition_selected.png",
      };
    }
    return null;
  };

  const getMetaParams = (id) => {
    const metaCondition = conditions.find((item) => item.id === id);
    return metaCondition ? metaCondition.params : [];
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Condition for Rule:"
        id="id-conditionpicker"
        required={true}
        onSelected={(id) => onSelected(id)}
        items={conditions}
        tooltip="You must select the condition for the rule and parametrize the condition"
        type="condition"
      />
      {condition && (
        <div className="rows">
          <PickerItemExt
            key={condition.id}
            title={`Condition ID: ${condition.id}`}
            id={condition.id}
            name={condition.name}
            imgUrl={
              process.env.PUBLIC_URL + "/assets/images/condition_selected.png"
            }
            onDelete={onDelete}
          />
          <div className="pickerspace" />
        </div>
      )}
      {condition && (
        <div style={{ marginTop: "10px" }}>
          <ParamPicker
            params={conditionParams}
            metaParams={getMetaParams(condition.id)}
            onChange={(data) => setConditionParams(data)}
          />
        </div>
      )}
    </div>
  );
};

ConditionPicker.propTypes = {
  rule: PropTypes.object.isRequired,
  rulesmetadata: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const RuleConditionPicker = (props) => {
  const [hidePicker, setHidePicker] = useState(false);
  const rule = props.rule;

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-rule-condition-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Condition" : "- Condition"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <React.Fragment>
          <Grid item xs={12}>
            <ConditionPicker
              rule={rule}
              rulesmetadata={props.rulesmetadata}
              onChange={(conditionId, conditionParams) =>
                props.onChange(conditionId, conditionParams)
              }
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

RuleConditionPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  rule: PropTypes.object.isRequired,
  rulesmetadata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  rulesmetadata: state.input.rulesmetadata,
});

export default connect(mapStateToProps, null)(RuleConditionPicker);
