import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import { fetchSurveysForAccount } from "../actions/adminActions";

import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";

const Surveys = (props) => {
  return (
    <ElementsPage
      title="Surveys"
      getElements={props.getSurveysForAccount}
      elementType={ElementType.SURVEY}
    />
  );
};

Surveys.propTypes = {
  getSurveysForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});
const mapDispatchToProps = (dispatch) => ({
  getSurveysForAccount: (query) => dispatch(fetchSurveysForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
