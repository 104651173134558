import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Development time
import PropTypes from "prop-types";

// Own components
import ElementDateTime from "./ElementDateTime";
import ElementInput from "./ElementInput";
import ElementSwitch from "./ElementSwitch";
import ElementButton from "./ElementButton";
import ElementTooltip from "./ElementTooltip";
import ElementRadio from "./ElementRadio";

// Styling
import "./element-input.css";

const useStyles = makeStyles({
  label: {
    fontSize: 13,
    fontFamily: "Montserrat",
    fontWeight: 100,
  },
  root: {
    display: "flex",
    justifyContent: "stretch",
  },
});

const REFRESH_NONE = "all-time";
const REFRESH_DAY = "daily";
const REFRESH_WEEK = "weekly";
const REFRESH_MONTH = "monthly";

const ElementTimers = (props) => {
  const classes = useStyles();

  const [timeToComplete, setTimeToComplete] = useState(props.timeToComplete);
  const [ttCompleteEnabled, setTtCompleteEnabled] = useState(
    props.timeToComplete > 0
  );
  const [timeToRestart, setTimeToRestart] = useState(props.timeToRestart);
  const [ttRestartEnabled, setTtRestartEnabled] = useState(
    props.timeToRestart > 0
  );
  const [activeFrom, setActiveFrom] = useState(props.activeFrom);
  const [activeTo, setActiveTo] = useState(props.activeTo);
  const [refreshPeriod, setRefreshPeriod] = useState(props.refreshPeriod);
  const [drawTime, setDrawTime] = useState(props.drawTime);
  const [hidePicker, setHidePicker] = useState(false);
  const [radioActive, setRadioActive] = useState(false);

  const isStreakTimer = props.isStreakTimer;

  useEffect(() => {
    const data = {
      timeToComplete: ttCompleteEnabled ? timeToComplete : 0,
      timeToRestart: ttRestartEnabled ? timeToRestart : 0,
      activeFrom,
      activeTo,
      refreshPeriod,
      drawTime,
    };
    props.onDataChanged(data);
    //console.log("Timer: ", data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timeToComplete,
    timeToRestart,
    activeFrom,
    activeTo,
    refreshPeriod,
    drawTime,
    ttRestartEnabled,
    ttCompleteEnabled,
  ]);

  useEffect(() => {}, [refreshPeriod]);

  const radioBox = `radiobox ${radioActive ? "active" : ""}`;
  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-timer-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Timers" : "- Timers"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <>
          {!props.hideActiveRange && (
            <React.Fragment>
              <Grid item xs={12}>
                <ElementDateTime
                  id="id-startd"
                  label="Start Date:"
                  tooltip="[Required] This is the date and time from which the element is active"
                  date={activeFrom}
                  required
                  onChange={(date) => setActiveFrom(date)}
                />
              </Grid>
              <Grid item xs={12}>
                <ElementDateTime
                  id="id-endd"
                  label="End Date:"
                  tooltip="[Required] This is the date and time from which the element is no longer active"
                  date={activeTo}
                  required
                  onChange={(date) => setActiveTo(date)}
                />
              </Grid>
            </React.Fragment>
          )}
          {drawTime && (
            <React.Fragment>
              <Grid item xs={12}>
                <ElementDateTime
                  id="id-drawd"
                  label="Draw Processed:"
                  tooltip="Tooltip text comes here"
                  date={drawTime}
                  onChange={(date) => setDrawTime(date)}
                  disabled={props.updateMode}
                />
              </Grid>
            </React.Fragment>
          )}
          {!props.hideActiveRange && <br />}
          {false /*!props.hideMissionTimers*/ && (
            <React.Fragment>
              <Grid item xs={4}>
                <ElementSwitch
                  checked={ttCompleteEnabled}
                  label="Time to Complete"
                  id="id-sw-timetocomplete"
                  onChange={(value) => setTtCompleteEnabled(value)}
                />
              </Grid>
              <Grid item xs={8}>
                <ElementInput
                  label="Limit:"
                  id="id-timetocomplete"
                  type="number"
                  tooltip="You can specify - in seconds - how long the player has to complete the element"
                  value={timeToComplete}
                  onChange={(value) => setTimeToComplete(value)}
                  disabled={!ttCompleteEnabled}
                />
              </Grid>
              <Grid item xs={4}>
                <ElementSwitch
                  checked={ttRestartEnabled}
                  label="Time to Restart"
                  id="id-sw-timetorestart"
                  onChange={(value) => setTtRestartEnabled(value)}
                />
              </Grid>
              <Grid item xs={8}>
                <ElementInput
                  label="Limit:"
                  id="id-timetorestart"
                  type="number"
                  tooltip="You can specify - in seconds - how long the player must wait before they can restart the element"
                  value={timeToRestart}
                  onChange={(value) => setTimeToRestart(value)}
                  disabled={!ttRestartEnabled}
                />
              </Grid>
            </React.Fragment>
          )}
          {!props.hideRefreshPeriod && (
            <React.Fragment>
              <Grid item xs={4}>
                <label className="textlabel">Refresh Period:</label>
              </Grid>
              <Grid item xs={8} className="radiorow">
                <Box
                  className={radioBox}
                  onFocus={() => setRadioActive(true)}
                  onBlur={() => setRadioActive(false)}
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      className="radiocontainer"
                      row
                      defaultValue={
                        isStreakTimer ? REFRESH_MONTH : REFRESH_NONE
                      }
                      value={refreshPeriod}
                      name="refresh-radios"
                      onChange={(event) => setRefreshPeriod(event.target.value)}
                    >
                      {!isStreakTimer && (
                        <FormControlLabel
                          value={REFRESH_NONE}
                          control={<ElementRadio id="id-none" />}
                          label={
                            <Typography className={classes.label}>
                              None
                            </Typography>
                          }
                          labelPlacement="start"
                        />
                      )}
                      <FormControlLabel
                        value={REFRESH_DAY}
                        control={<ElementRadio id="id-day" />}
                        label={
                          <Typography className={classes.label}>Day</Typography>
                        }
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value={REFRESH_WEEK}
                        control={<ElementRadio id="id-week" />}
                        label={
                          <Typography className={classes.label}>
                            Week
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value={REFRESH_MONTH}
                        control={<ElementRadio id="id-month" />}
                        label={
                          <Typography className={classes.label}>
                            Month
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <ElementTooltip tooltip="You can specify how frequently the element will refresh"></ElementTooltip>
              </Grid>
            </React.Fragment>
          )}
        </>
      )}
    </Grid>
  );
};

ElementTimers.propTypes = {
  onDataChanged: PropTypes.func.isRequired,
  timeToComplete: PropTypes.number,
  timeToRestart: PropTypes.number,
  activeFrom: PropTypes.string.isRequired,
  activeTo: PropTypes.string.isRequired,
  refreshPeriod: PropTypes.string,
  onlyActiveRange: PropTypes.bool,
  hideActiveRange: PropTypes.bool,
  hideRefreshPeriod: PropTypes.bool,
  hideMissionTimers: PropTypes.bool,
  drawTime: PropTypes.string,
  updateMode: PropTypes.bool,
  isStreakTimer: PropTypes.bool,
};

export default ElementTimers;
