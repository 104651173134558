import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";
import { fetchRulesForAccount } from "../actions/adminActions";

const Rules = (props) => {
  return (
    <ElementsPage
      title="Rules"
      getElements={props.getRulesForAccount}
      elementType={ElementType.RULE}
    />
  );
};

Rules.propTypes = {
  getRulesForAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.input.account,
});

const mapDispatchToProps = (dispatch) => ({
  getRulesForAccount: (query) => dispatch(fetchRulesForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
