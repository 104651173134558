import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// Own components
import { fetchAccounts } from "../actions/adminActions";

import ElementsPage from "./ElementsPage";
import { ElementType } from "../definitions/definitions";

const Accounts = (props) => {
  return (
    <ElementsPage
      title="Accounts"
      getElements={props.getAccounts}
      elementType={ElementType.ACCOUNT}
    />
  );
};

Accounts.propTypes = {
  accounts: PropTypes.array.isRequired,
  getAccounts: PropTypes.func.isRequired,
  userdata: PropTypes.object,
};

const mapStateToProps = (state) => ({
  accounts: state.input.accounts,
  userdata: state.root.userdata,
});
const mapDispatchToProps = (dispatch) => ({
  getAccounts: () => dispatch(fetchAccounts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
