import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItemExt } from "./PickerItem";

const SurveyPicker = (props) => {
  const items = props.surveys;
  const [surveyId, setSurveyId] = useState(props.surveyId);
  const [survey, setSurvey] = useState(
    items.find((survey) => survey.id === surveyId)
  );

  useEffect(() => {
    if (props.survey) setSurvey(populateSurvey(props.survey.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // add item to selected list
  const onSelected = (id, item) => {
    console.log("item ", item);
    props.onChange(item.id);
    setSurveyId(item.id);
    setSurvey(item);
  };

  const onDelete = () => {
    props.onChange(null);
    setSurvey(null);
    setSurveyId("");
  };

  const populateSurvey = (id) => {
    const _survey = props.surveys.find((survey) => survey.id === id);
    if (_survey) {
      return {
        id: _survey.id,
        name: _survey.name,
        imgUrl: _survey.imgUrl,
      };
    }
    return null;
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Survey:"
        id="id-surveypicker"
        required={false}
        onSelected={onSelected}
        items={items}
        tooltip={props.tooltip}
        type="surveys"
      />
      {survey && (
        <div className="rows">
          <PickerItemExt
            key={survey.id}
            title="Survey:"
            id={survey.id}
            name={survey.name}
            imgUrl={survey.imgUrl}
            onDelete={onDelete}
          />
          <div className="pickerspace" />
        </div>
      )}
    </div>
  );
};

SurveyPicker.propTypes = {
  account: PropTypes.object.isRequired,
  surveys: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  survey: PropTypes.object,
  tooltip: PropTypes.string,
  surveyId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  surveys: state.input.surveys ? state.input.surveys : [],
  account: state.input.account,
});

export default connect(mapStateToProps, null)(SurveyPicker);
