/**
 * Checks if a string contains only alphanumeric values
 * @param {*} str
 */
function isAlphaNumeric(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

/**
 * Checks if given object is empty
 * @param {*} object
 * @returns true if empty, false if not empty
 */
function isEmpty(object) {
  for (var i in object) {
    return false;
  }
  return true;
}

/**
 * Extracts a string from two substrings
 * @param {*} str - original string
 * @param {*} prefix - first match
 * @param {*} suffix - second match
 * @returns - substring, "" if none found
 */
const extract = (str, prefix, suffix) => {
  let s = str;
  var i = s.indexOf(prefix);
  if (i >= 0) {
    s = s.substring(i + prefix.length);
  } else {
    return "";
  }
  if (suffix) {
    i = s.indexOf(suffix);
    if (i >= 0) {
      s = s.substring(0, i);
    } else {
      return "";
    }
  }
  return s;
};

/**
 * Stringifies an object to a query string
 * @param {*} queryObject
 * @returns a REST API query string
 */
const stringifyQuery = (queryObject) => {
  let query = "?";
  for (let [key, value] of Object.entries(queryObject)) {
    query = query.concat(`&${key}=${value}`);
  }
  return query;
};

/**
 * Builds a query
 * @param {*} queryObject - query parameters in object
 * @param {*} account - account for the query
 * @param {*} id - id - if not set, will be exluded
 */
const buildQuery = (queryObject, account, id) => {
  const query = stringifyQuery(Object.assign({ account }, queryObject));
  return id ? `${id}${query}` : query;
};

/**
 * Parse more readable error description from API calls
 * @param {*} err - error object
 * @returns - string with more reasonable error description
 */
const parseError = (err) => {
  return err.error ? err.error : extract(JSON.stringify(err), "<pre>", "<br>");
};

/**
 * @description Removes all white spaces from a string
 * @param {*} str
 * @return string where all white spaces are removed
 */
const removeAllWhitespaces = (str) => {
  // If null or object is not a string
  if (!str || typeof str !== "string") return str;
  return str.replace(/\s+/g, "");
};

const uniqueId = () => {
  // desired length of Id
  var idStrLen = 20;
  // always start with a letter -- base 36 makes for a nice shortcut
  var idStr = (Math.floor(Math.random() * 25) + 10).toString(36) + "_";
  // add a timestamp in milliseconds (base 36 again) as the base
  idStr += new Date().getTime().toString(36) + "_";
  // similar to above, complete the Id using random, alphanumeric characters
  do {
    idStr += Math.floor(Math.random() * 35).toString(36);
  } while (idStr.length < idStrLen);

  return idStr;
};

/**
 * Checks if string is null, undefined, empty, contains whitespaces or tabs
 * @param {*} str
 * @returns
 */
function isEmptyOrSpaces(str) {
  return str == null || str.match(/^\s*$/) !== null;
}

export {
  isAlphaNumeric,
  isEmpty,
  extract,
  stringifyQuery,
  parseError,
  buildQuery,
  removeAllWhitespaces,
  uniqueId,
  isEmptyOrSpaces,
};
