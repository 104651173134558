import React from "react";
import PropTypes from "prop-types"; // Import PropTypes

import "./CostPane.css"; // Create a CSS file for styling

const CostPane = ({ players, totalCost, pricePerPlayer, comparator }) => {
  return (
    <div className={`container-pane ${totalCost ? "active" : "non-active"}`}>
      <div className="top-labels">
        <span className="label-pane">{`${comparator} ${players}`}</span>
        <span className="label-pane">Active Players</span>
      </div>
      <div className="middle-label">
        <span className="bold-label">{totalCost ? totalCost : "-"}</span>
      </div>
      <div className="bottom-label">
        <span className="label-pane">{pricePerPlayer + " €/player"}</span>
      </div>
    </div>
  );
};

CostPane.propTypes = {
  players: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
  pricePerPlayer: PropTypes.number.isRequired,
  comparator: PropTypes.string.isRequired,
};

export default CostPane;
