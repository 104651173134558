import React, { useState } from "react";

import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

const ElementDescription = ({ description, onChange, required }) => {
  const tooltipText =
    "You can specify a description for the element that, depending on needs, will be displayed in the front-end";
  const tooltipTextReq = "[Required] " + tooltipText;
  const [value, setValue] = useState(description);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-description"
        label="Description:"
        tooltip={required ? tooltipTextReq : tooltipText}
        onChange={(value) => {
          setValue(value);
          onChange(value);
        }}
        multiline
        rows={4}
        required={required}
        type="text"
      />
    </React.Fragment>
  );
};

ElementDescription.propTypes = {
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default ElementDescription;
