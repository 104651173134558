import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

const ElementFailMessage = ({ failMessage, onChange }) => {
  const [value, setValue] = useState(failMessage);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-passmessage"
        label="Quiz Fail Message:"
        tooltip="Fail message if quiz is not passed"
        onChange={(value) => setValue(value)}
        type="text"
      />
    </React.Fragment>
  );
};

ElementFailMessage.propTypes = {
  failMessage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ElementFailMessage;
