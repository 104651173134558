import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItem, PickerItemExt } from "./PickerItem";
import {
  populateElementSelection,
  createSelectableElementList,
  addToSelection,
  updateSelectionList,
} from "./pickertools";

import { ElementType } from "../../definitions/definitions";

const MissionsPickerNew = (props) => {
  // {currentCount: 0, id: "testing-event", count: 2}
  const [selection, setSelection] = useState(
    populateElementSelection(
      props.missions,
      props.selectedMissions,
      ElementType.MISSION
    )
  );
  const [selectableMissions, setSelectableMissions] = useState(props.missions);
  const editMode = props.editMode == undefined ? true : props.editMode;
  const title = props.title == null ? "Select Mission:" : props.title;

  const { missions, selectedMissions, missionId, onChange, isStreakMission } =
    props;
  useEffect(() => {
    // Create list of selected items based on missions
    const list = createSelectableElementList(
      missions,
      selectedMissions,
      missionId
    );
    setSelectableMissions(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Create list of selected items based on missions
    const list = createSelectableElementList(missions, selection, missionId);
    setSelectableMissions(list);
    if (selection) {
      let elementIds = selection.map(function (element) {
        return element.id;
      });
      onChange(elementIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const updateSelection = (id, value) => {
    const newSelection = updateSelectionList(selection, id, value);
    setSelection(newSelection);
  };

  // add item to selected list
  const onSelected = (id) => {
    if (!isStreakMission) {
      setSelection(addToSelection(selection, props.missions, id));
    } else if (selection.length < 1) {
      setSelection(addToSelection(selection, props.missions, id));
    }
  };

  const onDelete = (id) => {
    const list = selection.filter((item) => item.id !== id);
    setSelection(list);
  };

  return (
    <div>
      <ElementPickerNew
        title={title}
        id="id-missionpicker"
        required={false}
        onSelected={(id) => onSelected(id)}
        items={selectableMissions}
        tooltip={props.tooltip}
        type="missions"
      />
      {selection &&
        selection.map((item) => {
          return (
            <div className="rows" key={item.id}>
              {editMode &&
                !isStreakMission(
                  <PickerItem
                    title="Mission Name:"
                    label="Steps Required:"
                    id={item.id}
                    name={item.name}
                    imgUrl={item.imgUrl}
                    count={item.count}
                    onChange={(id, value) => updateSelection(id, value)}
                    onDelete={onDelete}
                  />
                )}
              {!editMode && (
                <PickerItemExt
                  title="Mission Name:"
                  id={item.id}
                  name={item.name}
                  imgUrl={item.imgUrl}
                  onDelete={onDelete}
                />
              )}
              <div className="pickerspace"></div>
            </div>
          );
        })}
    </div>
  );
};

MissionsPickerNew.propTypes = {
  missions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  selectedMissions: PropTypes.array.isRequired,
  tooltip: PropTypes.string,
  editMode: PropTypes.bool,
  missionId: PropTypes.string,
  title: PropTypes.string,
  isStreakMission: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  missions: state.input.missions ? state.input.missions : [],
  account: state.input.account,
});

export default connect(mapStateToProps, null)(MissionsPickerNew);
