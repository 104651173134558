import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const stylesReq = {
  tooltip: {
    width: "auto",
    maxWidth: "200px",
    height: "auto",
    borderRadius: "0px",
    backgroundColor: "#ECBE87",
    color: "black",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 100,
    marginLeft: "5px",
  },
};

const styles = {
  tooltip: {
    width: "auto",
    maxWidth: "200px",
    height: "auto",
    borderRadius: "0px",
    backgroundColor: "#E4F2D9",
    color: "black",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 100,
    marginLeft: "5px",
  },
};

const useStylesReq = makeStyles(stylesReq);
const useStyles = makeStyles(styles);

const ElementTooltip = ({ tooltip, required }) => {
  const classesReq = useStylesReq();
  const classes = useStyles();
  const src = required
    ? process.env.PUBLIC_URL + "/assets/icons/tooltip-req.png"
    : process.env.PUBLIC_URL + "/assets/icons/tooltip.png";
  return (
    <Tooltip
      classes={required ? classesReq : classes}
      title={tooltip}
      placement="right-start"
    >
      <img className="image" alt="" src={src} />
    </Tooltip>
  );
};

ElementTooltip.propTypes = {
  tooltip: PropTypes.string,
  required: PropTypes.bool,
};

export default ElementTooltip;
