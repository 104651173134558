import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import ElementButton from "../inputs/ElementButton";

import "./element-input.css";

const imgEdit = process.env.PUBLIC_URL + "/assets/images/edit.png";
const imgAdd = process.env.PUBLIC_URL + "/assets/images/language_add.png";
const imgDelete = process.env.PUBLIC_URL + "/assets/images/trash-bin.png";
const imgSave = process.env.PUBLIC_URL + "/assets/images/checked.png";
const imgCancel = process.env.PUBLIC_URL + "/assets/images/cross.png";

// Development time
import PropTypes from "prop-types";

const langToFlag = {};
langToFlag["en"] = "gb";
langToFlag["ar"] = "sa";
langToFlag["zh"] = "cn";
langToFlag["cs"] = "cz";
langToFlag["fi"] = "fi";
langToFlag["fr"] = "fr";
langToFlag["de"] = "de";
langToFlag["el"] = "gr";
langToFlag["hi"] = "in";
langToFlag["it"] = "it";
langToFlag["ja"] = "jp";
langToFlag["kk"] = "kz";
langToFlag["pt"] = "pt";
langToFlag["ru"] = "ru";
langToFlag["es"] = "es";
langToFlag["sv"] = "se";
langToFlag["tr"] = "tr";

const TABLE_CELL_SIZE = "12px";
const TABLE_CELL_FONT = "Montserrat";
const columns = [
  {
    title: "",
    field: "flag",
    render: (rowData) => (
      <div style={{ paddingLeft: "32px" }}>
        <span className={`fi fi-${langToFlag[rowData.code]}`}></span>{" "}
      </div>
    ),
    width: "5%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "ISO 639-1",
    field: "code",
    lookup: {
      en: "English",
      ar: "Arabic",
      zh: "Chinese",
      cs: "Czech",
      fi: "Finnish",
      fr: "French",
      de: "German",
      el: "Greek",
      hi: "Hindi",
      it: "Italian",
      ja: "Japanese",
      kk: "Kazakh",
      pt: "Portuguese",
      ru: "Russian",
      es: "Spanish",
      sv: "Swedish",
      tr: "Turkish",
    },
    width: "20%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "NAME",
    field: "name",
    width: "20%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
  },
  {
    title: "DESCRIPTION",
    field: "description",
    width: "60%",
    headerStyle: {
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
    },
    cellStyle: {
      textAlign: "left",
      fontSize: TABLE_CELL_SIZE,
      font: TABLE_CELL_FONT,
      //textOverflow: "ellipsis",
      whiteSpace: "normal",
      overflow: "hidden",
      wordWrap: "break-word",
    },
  },
];

const LanguageTable = ({ languages = [], onChange }) => {
  const [hidePicker, setHidePicker] = useState(true);
  const [data, setData] = useState(Array.isArray(languages) ? languages : []);

  useEffect(() => {
    console.log(data);
    onChange(data);
  }, [data]);

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-language-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Languages" : "- Languages"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <Grid item xs={12}>
          <MaterialTable
            localization={{
              header: {
                actions: "ACTIONS",
              },
            }}
            icons={{
              Edit: () => <img className="languagepicker" src={imgEdit} />,
              Delete: () => <img className="languagepicker" src={imgDelete} />,
              Add: () => <img className="languagepicker" src={imgAdd} />,
              Check: () => <img className="languagepicker" src={imgSave} />,
              Clear: () => <img className="languagepicker" src={imgCancel} />,
            }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            columns={columns}
            data={languages}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    setData([...data, newData]);

                    resolve();
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);

                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);

                    resolve();
                  }, 1000);
                }),
            }}
            dense
            options={{
              pageSizeOptions: [5, 10, 15],
              pageSize: 5,
              padding: "dense",
              detailPanelType: "single",
              tableLayout: "auto",
              headerStyle: {
                style: "white-space: nowrap",
                padding: "dense",
                fontSize: TABLE_CELL_SIZE,
                font: TABLE_CELL_FONT,
              },
              showTitle: false,
              search: false,
              emptyRowsWhenPaging: true,
              cellStyle: {
                padding: "2px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: 100,
                fontSize: "12px",
                font: "Montserrat",
              },
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return {
                    backgroundColor: "#f2f2f2",
                  };
                } else {
                  return {};
                }
              },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

LanguageTable.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

export default LanguageTable;
