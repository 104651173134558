import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

// Development time
import PropTypes from "prop-types";

// Own components
import AchievementsPickerEdit from "./AchievementsPickerEdit";
import ElementButton from "../inputs/ElementButton";
import ElementInput from "../inputs/ElementInput";

// Styling
import "../inputs/element-input.css";

const RewardsPickerNew = (props) => {
  const [hidePicker, setHidePicker] = useState(false);
  const [points, setPoints] = useState(props.reward.points);
  const [credits, setCredits] = useState(props.reward.credits);
  const [achievements, setAchievements] = useState(props.reward.achievements);

  useEffect(() => {
    props.onChange({ points, credits, achievements });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, credits, achievements]);

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <ElementButton
          id="id-rewards-btn"
          variant="contained"
          component="span"
          size="large"
          color="blue"
          onClick={() => setHidePicker(!hidePicker)}
        >
          {hidePicker ? "+ Rewards" : "- Rewards"}
        </ElementButton>
      </Grid>
      {!hidePicker && (
        <React.Fragment>
          <Grid item xs={12}>
            <ElementInput
              label="Points:"
              id="id-points"
              type="number"
              tooltip="You can specify the points, if any, that will be awarded to the player upon successful completion of the element"
              value={points}
              onChange={(value) => setPoints(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ElementInput
              label="Credits:"
              id="id-credits"
              type="number"
              tooltip="You can specify the credits, if any, that will be awarded to the player upon successful completion of the element"
              value={credits}
              onChange={(value) => setCredits(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <AchievementsPickerEdit
              tooltip="You can specify the achievements, if any, and the steps that will be granted towards completing the achievement for the player upon successful completion of the element"
              onChange={(data) => setAchievements(data)}
              selectedAchievements={achievements}
              achievementId={props.achievementId}
              editMode={true}
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

RewardsPickerNew.propTypes = {
  onChange: PropTypes.func,
  reward: PropTypes.object,
  achievementId: PropTypes.string,
};

export default RewardsPickerNew;
