import React, { useState } from "react";
import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

import { removeAllWhitespaces } from "../../utils/utils";

const ElementId = ({ id, onChange, disabled }) => {
  const [value, setValue] = useState(id);

  /*
  useEffect(() => {
    onChange(removeAllWhitespaces(value));
  }, [value]);
  */

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-id"
        label="ID:"
        tooltip="[Required] This is the unique identifier of the element that will be used throughout the system"
        onChange={(value) => {
          setValue(value);
          onChange(removeAllWhitespaces(value));
        }}
        disabled={disabled}
        required
        type="text"
      />
    </React.Fragment>
  );
};

ElementId.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ElementId;
