import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchAccounts,
  fetchAccountsByList,
  selectAccount,
  fetchEventsForAccount,
  fetchMissionsForAccount,
  fetchAchievementsForAccount,
  fetchTeamsForAccount,
  fetchLevelsForAccount,
  fetchLeaderboardsForAccount,
  fetchPrizesForAccount,
  fetchMysteryboxes,
  fetchRaffles,
  fetchPlayersForAccount,
  fetchQuizzesForAccount,
  fetchSurveysForAccount,
  fetchRulesMetadataForAccount,
  fetchRulesForAccount,
  fetchStreaksForAccount,
} from "../../actions/adminActions";
import PropTypes from "prop-types";

import "../inputs/element-input.css";

import { buildQuery } from "../../utils/utils";
import {
  isAdmin,
  getUserAccounts,
  isAccountAdmin,
  hasAccountReadAccess,
} from "../../auth/roles";

const defaultQuery = {
  limit: 500,
  skip: 0,
  availability: "all",
  timeframe: "all",
};

const defaultPlayersQuery = {
  limit: 1000,
  skip: 0,
  availability: "all",
  timeframe: "all",
  player: "",
};

const AccountsPicker = (props) => {
  const [availableAccounts, setAvailableAccounts] = useState([]);
  useEffect(() => {
    //console.log("retrieving makes");
    const gAccounts = async () => {
      if (isAdmin()) {
        props.getAccounts();
      } else if (isAccountAdmin() || hasAccountReadAccess()) {
        props.getAccountsByList(getUserAccounts());
      }
    };
    gAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userdata]);

  useEffect(() => {
    if (isAdmin()) {
      setAvailableAccounts(props.accounts);
      return;
    }
    // Filter user accounts
    const userAccounts = getUserAccounts();
    const accounts = props.accounts.filter((account) =>
      userAccounts.includes(account.account)
    );
    //console.log("Available accounts: ", accounts);
    setAvailableAccounts(accounts);
  }, [props.accounts]);

  const onSelectionChanged = (e) => {
    let value = e.target.value;
    //console.log(`value: ${value}`);
    const account = JSON.parse(value);
    props.selectedAccount(account);
    const accountId = account.account;
    const query = buildQuery(defaultQuery, accountId);
    props.getEventsForAccount(query);
    props.getMissionsForAccount(query);
    props.getStreaksForAccount(query);
    props.getAchievementsForAccount(query);
    props.getLevelsForAccount(query);
    props.getTeamsForAccount(query);
    props.getLeaderboardsForAccount(query);
    props.getMysteryBoxesForAccount(query);
    props.getPrizesForAccount(query);
    props.getRafflesForAccount(query);
    props.getQuizzesForAccount(query);
    props.getSurveysForAccount(query);
    props.getRulesForAccount(query);
    props.getRulesMetadataForAccount(query);
    props.getPlayersForAccount(buildQuery(defaultPlayersQuery, accountId));
    //console.log(`value: ${JSON.stringify(value)}`);
    //console.log(`here we are ${JSON.stringify(value)}`);
    //debugger;
  };

  const accountsList = () => {
    let list = availableAccounts.map((item) => {
      return (
        <option key={item.account} value={JSON.stringify(item)}>
          {item.name}
        </option>
      );
    }, []);
    if (list)
      list.unshift(
        <option key={0} selected disabled hidden>
          Select Account
        </option>
      );
    return list;
  };

  //       className="browser-default btn-info custom-select sm"

  return (
    <select
      className="accountpicker"
      name="account"
      id="account"
      onInput={onSelectionChanged}
    >
      {accountsList()}
    </select>
  );
};

AccountsPicker.propTypes = {
  accounts: PropTypes.array,
  userdata: PropTypes.object,
  selectedAccount: PropTypes.func,
  getAccounts: PropTypes.func,
  getAccountsByList: PropTypes.func,
  getEventsForAccount: PropTypes.func,
  getMissionsForAccount: PropTypes.func,
  getStreaksForAccount: PropTypes.func,
  getAchievementsForAccount: PropTypes.func,
  getLevelsForAccount: PropTypes.func,
  getTeamsForAccount: PropTypes.func,
  getLeaderboardsForAccount: PropTypes.func,
  getMysteryBoxesForAccount: PropTypes.func,
  getPrizesForAccount: PropTypes.func,
  getRafflesForAccount: PropTypes.func,
  getPlayersForAccount: PropTypes.func,
  getQuizzesForAccount: PropTypes.func,
  getSurveysForAccount: PropTypes.func,
  getRulesForAccount: PropTypes.func,
  getRulesMetadataForAccount: PropTypes.func,
};

const mapStateToProps = (state) => ({
  accounts: state.input.accounts,
  userdata: state.root.userdata,
});
const mapDispatchToProps = (dispatch) => ({
  getAccounts: () => dispatch(fetchAccounts()),
  getAccountsByList: (accountslist) =>
    dispatch(fetchAccountsByList(accountslist)),
  selectedAccount: (account) => dispatch(selectAccount(account)),
  getEventsForAccount: (query) => dispatch(fetchEventsForAccount(query)),
  getMissionsForAccount: (query) => dispatch(fetchMissionsForAccount(query)),
  getStreaksForAccount: (query) => dispatch(fetchStreaksForAccount(query)),
  getAchievementsForAccount: (account) =>
    dispatch(fetchAchievementsForAccount(account)),
  getLevelsForAccount: (account) => dispatch(fetchLevelsForAccount(account)),
  getTeamsForAccount: (account) => dispatch(fetchTeamsForAccount(account)),
  getLeaderboardsForAccount: (account) =>
    dispatch(fetchLeaderboardsForAccount(account)),
  getMysteryBoxesForAccount: (query) => dispatch(fetchMysteryboxes(query)),
  getPrizesForAccount: (query) => dispatch(fetchPrizesForAccount(query)),
  getRafflesForAccount: (query) => dispatch(fetchRaffles(query)),
  getQuizzesForAccount: (query) => dispatch(fetchQuizzesForAccount(query)),
  getSurveysForAccount: (query) => dispatch(fetchSurveysForAccount(query)),
  getRulesForAccount: (query) => dispatch(fetchRulesForAccount(query)),
  getRulesMetadataForAccount: (query) =>
    dispatch(fetchRulesMetadataForAccount(query)),
  getPlayersForAccount: (query) => dispatch(fetchPlayersForAccount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPicker);
