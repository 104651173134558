import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
// Own components

import "../components.css";

// checked color: "#214fbb",
// track      backgroundColor: "#DCDBE1",
//    "&$checked + $track":  backgroundColor: "#e9f0fe",
/*
  thumb: {
    width: "32px",
    height: "32px",
    transform: "translateX(0px)",
  },
*/

const RADIO_SIZE = 32;

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: RADIO_SIZE,
    height: RADIO_SIZE,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#DCDBE1",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(220,219,225,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#214fbb",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: RADIO_SIZE,
      height: RADIO_SIZE,
      /*backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",*/
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#5E86FE",
    },
  },
  label: {
    fontSize: 13,
    fontFamily: "Montserrat",
    fontWeight: 100,
  },
});

const ElementRadio = ({ id, ...props }) => {
  const classes = useStyles();
  return (
    <Radio
      disableRipple
      className={classes.root}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
      name={id}
    />
  );
};

ElementRadio.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ElementRadio;
