import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItemExt } from "./PickerItem";

const TeamPickerNew = (props) => {
  const items = props.teams;
  const [team, setTeam] = useState(null);

  useEffect(() => {
    console.log(props.team);
    if (props.team) setTeam(populateTeam(props.team));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // add item to selected list
  const onSelected = (id, item) => {
    //console.log("item ", item);
    props.onChange(item.id); // item.id
    setTeam(item);
  };

  const onDelete = () => {
    props.onChange({ id: "", ordinal: 0 });
    setTeam(null);
  };

  const populateTeam = (id) => {
    console.log("Teams: ", props.team);
    const _team = props.teams.find((team) => team.id === id);
    if (_team) {
      return {
        id: _team.id,
        name: _team.name,
        imgUrl: _team.imgUrl,
      };
    }
    return null;
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Team:"
        id="id-teampicker"
        required={false}
        onSelected={onSelected}
        items={items}
        tooltip={props.tooltip}
        type="teams"
      />
      {team && (
        <div className="rows">
          <PickerItemExt
            key={team.id}
            title="Team:"
            id={team.id}
            name={team.name}
            imgUrl={team.imgUrl}
            onDelete={onDelete}
          />
          <div className="pickerspace" />
        </div>
      )}
    </div>
  );
};

TeamPickerNew.propTypes = {
  onChange: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired,
  team: PropTypes.string,
  tooltip: PropTypes.string,
};

const mapStateToProps = (state) => ({
  teams: state.input.teams ? state.input.teams : [],
});

export default connect(mapStateToProps, null)(TeamPickerNew);
