import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItem } from "./PickerItem";
import {
  populateElementSelection,
  createSelectableElementList,
  updateSelectionList,
  addToSelection,
} from "./pickertools";
import { ElementType } from "../../definitions/definitions";

const editMode = true;

const PrizesPickerNew = (props) => {
  // {currentCount: 0, id: "testing-event", count: 2}
  const [selection, setSelection] = useState([]);
  const [selectablePrizes, setSelectablePrizes] = useState(props.prizes);

  const { prizes, selectedPrizes, onChange } = props;
  useEffect(() => {
    // Create list of selected items based on achievements
    setSelectablePrizes(createSelectableElementList(prizes, selectedPrizes));
    setSelection(
      populateElementSelection(
        prizes,
        selectedPrizes,
        ElementType.PRIZE,
        editMode
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selection.length > 0) {
      // Create list of selected items based on achievements
      const list = createSelectableElementList(prizes, selection);
      setSelectablePrizes(list);
    }
    let elementIds = selection.map(function (element) {
      return {
        id: element.id,
        count: element.count,
      };
    });
    onChange(elementIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const updateSelection = (id, value) => {
    const newSelection = updateSelectionList(selection, id, value);
    setSelection(newSelection);
  };

  // add item to selected list
  const onSelected = (id) => {
    setSelection(addToSelection(selection, props.prizes, id));
  };

  const onDelete = (id) => {
    const list = selection.filter((item) => item.id !== id);
    setSelection(list);
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Prize:"
        id="id-prizepicker"
        required={false}
        onSelected={(id) => onSelected(id)}
        items={selectablePrizes}
        tooltip={
          props.tooltip ||
          "You must select the prizes – and their quantities – to add to the element"
        }
        type="prizes"
      />
      {selection &&
        selection.map((item) => {
          return (
            <div className="rows" key={item.id}>
              <PickerItem
                title="Prize Name:"
                label="Units:"
                id={item.id}
                name={item.name}
                imgUrl={item.imgUrl}
                count={item.count}
                onChange={(id, value) => updateSelection(id, value)}
                onDelete={onDelete}
              />
              <div className="pickerspace"></div>
            </div>
          );
        })}
    </div>
  );
};

PrizesPickerNew.propTypes = {
  prizes: PropTypes.array.isRequired,
  selectedPrizes: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
};

const mapStateToProps = (state) => ({
  prizes: state.input.prizes ? state.input.prizes : [],
  account: state.input.account,
});

export default connect(mapStateToProps, null)(PrizesPickerNew);
