import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginPage from "./LoginPage";
import LoadingPage from "./LoadingPage";

// New UI elements
import Missions from "./Missions";
import Events from "./Events";
import Accounts from "./Accounts";
import Teams from "./Teams";
import Achievements from "./Achievements";
import Analytics from "./Analytics";
import Levels from "./Levels";
import Leaderboards from "./Leaderboards";
import Prizes from "./Prizes";
import Raffles from "./Raffles";
import MysteryBoxes from "./MysteryBoxes";
import Players from "./Players";
import Quizzes from "./Quizzes";
import Surveys from "./Surveys";
import Rules from "./Rules";
import Streaks from "./Streaks";

class GLRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/accounts" component={Accounts} />
        <Route path="/events" component={Events} />
        <Route path="/achievements" component={Achievements} />
        <Route path="/missions" component={Missions} />
        <Route path="/streaks" component={Streaks} />
        <Route path="/teams" component={Teams} />
        <Route path="/players" component={Players} />
        <Route path="/levels" component={Levels} />
        <Route path="/prizes" component={Prizes} />
        <Route path="/leaderboards" component={Leaderboards} />
        <Route path="/analytics" component={Analytics} />
        <Route path="/mysteryboxes" component={MysteryBoxes} />
        <Route path="/raffles" component={Raffles} />
        <Route path="/quizzes" component={Quizzes} />
        <Route path="/surveys" component={Surveys} />
        <Route path="/rules" component={Rules} />
        <Route path="/analytics/:error" component={LoginPage} />
        <Route path="/" component={LoadingPage} />
      </Switch>
    );
  }
}

export default GLRoutes;
