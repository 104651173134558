import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ElementPickerNew } from "./ElementPickerNew";
import { PickerItemExt } from "./PickerItem";

const QuizzPicker = (props) => {
  const items = props.quizzes;
  const [quizzId, setQuizzId] = useState(props.quizzId);
  const [quizz, setQuizz] = useState(
    items.find((quizz) => quizz.id === quizzId)
  );

  useEffect(() => {
    if (props.quizz) setQuizz(populateQuiz(props.quizz.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // add item to selected list
  const onSelected = (id, item) => {
    console.log("item ", item);
    props.onChange(item.id);
    setQuizzId(item.id);
    setQuizz(item);
  };

  const onDelete = () => {
    props.onChange(null);
    setQuizz(null);
    setQuizzId("");
  };

  const populateQuiz = (id) => {
    const _quizz = props.quizzes.find((quizz) => quizz.id === id);
    if (_quizz) {
      return {
        id: _quizz.id,
        name: _quizz.name,
        imgUrl: _quizz.imgUrl,
      };
    }
    return null;
  };

  return (
    <div>
      <ElementPickerNew
        title="Select Quizz:"
        id="id-quizzpicker"
        required={false}
        onSelected={onSelected}
        items={items}
        tooltip={props.tooltip}
        type="quizzes"
      />
      {quizz && (
        <div className="rows">
          <PickerItemExt
            key={quizz.id}
            title="Quizz:"
            id={quizz.id}
            name={quizz.name}
            imgUrl={quizz.imgUrl}
            onDelete={onDelete}
          />
          <div className="pickerspace" />
        </div>
      )}
    </div>
  );
};

QuizzPicker.propTypes = {
  account: PropTypes.object.isRequired,
  quizzes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  quizz: PropTypes.object,
  tooltip: PropTypes.string,
  quizzId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  quizzes: state.input.quizzes ? state.input.quizzes : [],
  account: state.input.account,
});

export default connect(mapStateToProps, null)(QuizzPicker);
