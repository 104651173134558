import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import ElementInput from "./ElementInput";

const ElementPoints = ({ points, onChange, tooltip }) => {
  const [value, setValue] = useState(points);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <ElementInput
        value={value}
        id="id-points"
        label="Points:"
        tooltip={tooltip}
        onChange={(value) => setValue(value)}
        type="number"
      />
    </React.Fragment>
  );
};

ElementPoints.propTypes = {
  points: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

export default ElementPoints;
